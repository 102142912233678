import React from "react";

export default ({ text, title, className, paddingTop }) => {
  return (
    <div className={`info-box ${className}`}>
      <div className="icon">
        <img
          src={`${process.env.PUBLIC_URL}/images/assets/info.svg`}
          alt="Info"
          className="icon"
        />
      </div>
      <div className={`text ${paddingTop || ""} blue-Regular-14px`}>
        {title && <div className="blue-Bold-14px text-left mb-1">{title}</div>}
        {text}
      </div>
    </div>
  );
};
