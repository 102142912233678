import React, { Component } from "react";
import ConnectedIntlProvider from "../../../providers/ConnectedIntlProvider";

import { Route } from "react-router-dom";
import routes from "../../../constants/routes";

import ShopDetailsSummary from "./ShopDetailsSummary";
import ChoosePlanSummary from "./ChoosePlanSummary";
import ChooseDesignsSummary from "./ChooseDesignsSummary";
import ConfirmationSummary from "./ConfirmationSummary";

import "../../../stylesheets/register/summary.scss";

class Summary extends Component {
  render = () => {
    return (
      <ConnectedIntlProvider>
        <Route path={routes.register.details} component={ShopDetailsSummary} />
        <Route path={routes.register.plan} component={ChoosePlanSummary} />
        <Route
          path={routes.register.designs}
          component={ChooseDesignsSummary}
        />
        <Route
          path={routes.register.confirmation}
          component={ConfirmationSummary}
        />
      </ConnectedIntlProvider>
    );
  };
}

export default Summary;
