import {
  EU_ZONE_1,
  EU_ZONE_2,
  EU_ZONE_3,
  INTERNATIONAL_ZONE_1,
  INTERNATIONAL_ZONE_2,
} from "./internationals";

export const retail_price = {
  goodbag: 14.9,
  goodcup: 29.9,
}

export const bag_prices = {
  custom_medium: {
    251: 6.27,
    501: 5.58,
    1001: 5.07,
    3001: 4.78,
    5001: 4.31,
    10001: 4.15,
    20001: 3.62,
    40001: 3.41,
    50001: 3.19,
    100001: 2.98,
    250001: 2.88,
    9999999991: 2.77,
  },
  custom_large: {
    251: 6.58,
    501: 5.89,
    1001: 5.38,
    3001: 5.09,
    5001: 4.62,
    10001: 4.47,
    20001: 4.19,
    40001: 3.94,
    50001: 3.70,
    100001: 3.57,
    250001: 3.45,
    9999999991: 3.20,
  },
};

export const shipping_prices = {
  // [quantity upper threshold]: {
  //   [cost]: [countries]
  // },
  // ...
  20: {
    10: ["AT"],
    12: ["DE"],
    15: EU_ZONE_1,
    20: EU_ZONE_2,
    40: EU_ZONE_3,
    30: ["CH"],
    80: INTERNATIONAL_ZONE_1,
    100: INTERNATIONAL_ZONE_2,
  },
  50: {
    10: ["AT"],
    15: ["DE"],
    18: EU_ZONE_1,
    25: EU_ZONE_2,
    45: EU_ZONE_3,
    50: ["CH"],
    135: INTERNATIONAL_ZONE_1,
    200: INTERNATIONAL_ZONE_2,
  },
  80: {
    10: ["AT"],
    18: ["DE"],
    20: EU_ZONE_1,
    30: EU_ZONE_2,
    50: EU_ZONE_3,
    80: ["CH"],
    200: INTERNATIONAL_ZONE_1,
    300: INTERNATIONAL_ZONE_2,
  },
  100: {
    10: ["AT"],
    20: ["DE"],
    25: EU_ZONE_1,
    35: EU_ZONE_2,
    55: EU_ZONE_3,
    110: ["CH"],
    280: INTERNATIONAL_ZONE_1,
    305: INTERNATIONAL_ZONE_2,
  },
  eligibleCountries: [
    "AT",
    "DE",
    "CH",
    ...EU_ZONE_1,
    ...EU_ZONE_2,
    ...EU_ZONE_3,
    ...INTERNATIONAL_ZONE_1,
    ...INTERNATIONAL_ZONE_2,
  ],
};
