/**
 * This service is responsible for getting and setting
 * the cookie consent within the localStorage.
 */
export default class CookiesService {

    static activateStatisticModules = () => {
        // this.activateGoogleAnalytics();
    }
    
    static activateMarketingModules = () => {
        // this.activateFacebookPixel();
        this.activateHubspot();
    }

    // static activateGoogleAnalytics = () => {
    //     import("react-ga").then(({ default: GoogleAnalytics }) =>
    //         GoogleAnalytics.initialize("UA-84534351-1")
    //     );
    // };

    // static activateFacebookPixel = () => {
    //     import("../business/FBPixelService").then(
    //         ({ default: FBPixelService }) => FBPixelService.init()
    //     );
    // };
    
    static activateHubspot = () => {
        const script = document.createElement("script");
        script.type = 'text/javascript';
        script.id = 'hs-script-loader';
        script.defer = true;
        script.src = '//js.hs-scripts.com/7609819.js';
        script.async = true;
        document.body.appendChild(script);
    };

  static setCookieConsent = () =>
    localStorage.setItem("cookie_consent", "true");
}
