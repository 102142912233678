import React, { Component } from "react";
import routes from "../../../constants/routes";
import OrderService from "../../../services/business/OrderService";

import { connect } from "react-redux";
import { navigate } from "../../shared/routes/CustomBrowserRouter";
import registerActions from "../../../actions/register";
import reorderActions from "../../../actions/reorder";

import { ScaleIn } from "../../hocs/AnimationHOC";
import { withTranslation } from "react-i18next";

import "../../../stylesheets/register/completed.scss";

class OrderPlacedScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      success: props.history.location.state
        ? props.history.location.state.success
        : false,
      tryAgainClicked: false,
      process: props.history.location.state
        ? props.history.location.state.process
        : undefined,
    };
  }

  componentDidMount() {
    // If the user hasn't been redirected to this page on purpose by the website
    // itself, then sent him to the landing page.
    if (!["reorder", "register"].includes(this.state.process))
      return navigate(routes.index);

    if (this.state.success) {
      return this.props[`reset${this.state.process.capitalize()}Process`]();
    }
  }

  retrySubmit = async () => {
    // Prevent double submission
    if (this.state.tryAgainClicked) return;

    this.setState({ tryAgainClicked: true }, async () => {
      // Try submitting a request again.
      let { success } = await OrderService[
        `submit${this.state.process.capitalize()}`
      ]();
      // TODO: check when the retry functionality should be suspended and
      // the user be prevented from retrying(sending requests forever) because
      // of a very simple reason.

      this.setState(
        { success: success ? null : false }, // Re-trigger scale-in animation if success is true
        // Finally update the state with the new request's result
        () =>
          this.setState({
            success,
            tryAgainClicked: false,
          })
      );
    });
  };

  render = () => {
    let { success, tryAgainClicked } = this.state;
    if (success === null) return null;

    let { t } = this.props;

    return (
      <div className={`order-placed success-${success ? "true" : "false"}`}>
        <ScaleIn>
          <div className="text-center">
            <div
              className="heading blue-ExtraBold-40px-left"
              dangerouslySetInnerHTML={{
                __html: success
                  ? t("confirmation:order_completed_heading")
                  : t("confirmation:order_completed_error_heading"),
              }}
            />
            <div className="image">
              <img
                src={`${process.env.PUBLIC_URL}/images/register/completed/${
                  success ? "order-placed.png" : "order-error.svg"
                }`}
                alt=""
              />
            </div>
            <div
              className="text blue-Regular-18px"
              dangerouslySetInnerHTML={{
                __html: success
                  ? t("confirmation:order_completed_text")
                  : t("confirmation:order_completed_error_text"),
              }}
            />
            {!success && (
              <div
                className={`btn btn-primary bulky retry-order-submit ${
                  tryAgainClicked ? "disabled" : ""
                }`}
                onClick={this.retrySubmit}
              >
                {t("confirmation:try_again")}
              </div>
            )}
          </div>
        </ScaleIn>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  processStarted: state.register.entry_type,
});

const mapDispatchToProps = (dispatch) => ({
  resetRegisterProcess: () => dispatch(registerActions.resetRegister()),
  resetReorderProcess: () => dispatch(reorderActions.resetReorder()),
});

let Reduxified = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderPlacedScreen);

export default withTranslation()(Reduxified);
