import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import CONFIG from "../../config";

import { connect } from "react-redux";
import settingsActions from "../../actions/settings";
import registerActions from "../../actions/register";
import layoutActions from "../../actions/layout";

import LocationService from "../../services/business/LocationService";

import FinderContent from "./FinderContent";
import "../../stylesheets/landing_page.scss";
import CompanyStores from "../register/plan/CompanyStores";
import FindShopModal from "./FindShopModal";
import MediaQuery from "react-responsive/src/Component";

class LandingPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalToggled: false,
    };

    this.whyBecomePartnerLayer = new React.createRef();
  }

  componentDidMount = async () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    // Get the country code of the client user.
    // Used for currency adjustments later in the process.
    let countryCode = await LocationService.getUserCountry();
    this.props.setCountryCode(countryCode);
    // Reset all layout panels' and checkboxes' toggle status.
    this.props.resetLayout();
    // Reset all progress on the application process
    this.props.resetProcess();
  };

  scrollDown = () =>
    window.scrollTo({
      top: this.whyBecomePartnerLayer.offsetTop,
      behavior: "smooth",
    });

  toggleModal = (modalToggled) => this.setState({ modalToggled });

  render = () => {
    let { t, i18n } = this.props;
    let language = i18n.language.split("-")[0];

    return (
      <Fragment>
        <div className="landing-page">
          <div className="find-shop-layer">
            <div className="shop-finder-container">
              <FinderContent />
              <img
                src={`${process.env.PUBLIC_URL}/images/landing/find-out-more-${language}.png`}
                onError={(ev) => {
                  ev.target.src = `/images/landing/find-out-more-${CONFIG.DEFAULT_LANGUAGE}.png`;
                }}
                alt=""
                className={`find-out-more ${i18n.language}`}
                onClick={this.scrollDown}
              />
            </div>
            <div className="page-background">
              <div className="wave">
                <img
                  src={`${process.env.PUBLIC_URL}/images/landing/wave.png`}
                  alt=""
                />
              </div>
            </div>
          </div>
          <div
            className="why-become-partner"
            ref={(node) => (this.whyBecomePartnerLayer = node)}
          >
            <h2
              className="heading blue-ExtraBold-40px-center"
              dangerouslySetInnerHTML={{ __html: t("landing:partner_heading") }}
            />
            <div className="timeline">
              <div className="story environment">
                <div className="thumbnail">
                  <MediaQuery maxWidth={991}>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/landing/environment-mobile.jpg`}
                      alt=""
                    />
                  </MediaQuery>
                  <MediaQuery minWidth={992}>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/landing/environment.jpg`}
                      alt=""
                    />
                  </MediaQuery>
                </div>
                <div className="label blue-Bold-30px-center">
                  {t("landing:timeline_1_label")}
                </div>
                <div className="text blue-Regular-18px">
                  {t("landing:timeline_1_text")}
                </div>
              </div>
              <div className="story customers">
                <div className="thumbnail">
                  <MediaQuery maxWidth={991}>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/landing/customers-mobile.jpg`}
                      alt=""
                    />
                  </MediaQuery>
                  <MediaQuery minWidth={992}>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/landing/customers.jpg`}
                      alt=""
                    />
                  </MediaQuery>
                </div>
                <div className="label blue-Bold-30px-center">
                  {t("landing:timeline_2_label")}
                </div>
                <div className="text blue-Regular-18px">
                  {t("landing:timeline_2_text")}
                </div>
              </div>
              <div className="story profit">
                <div className="thumbnail">
                  <MediaQuery maxWidth={991}>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/landing/profit-mobile.jpg`}
                      alt=""
                    />
                  </MediaQuery>
                  <MediaQuery minWidth={992}>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/landing/profit.jpg`}
                      alt=""
                    />
                  </MediaQuery>
                </div>
                <div className="label blue-Bold-30px-center">
                  {t("landing:timeline_3_label")}
                </div>
                <div className="text blue-Regular-18px">
                  {t("landing:timeline_3_text")}
                </div>
              </div>
            </div>
            <div
              className="btn btn-primary bulky become-partner-btn"
              onClick={() => this.toggleModal(true)}
            >
              {t("landing:become_partner")}
            </div>
          </div>
          <CompanyStores headingStyle={"blue-Bold-30px-center"}>
            <div className="text-center">
              <div
                className="btn btn-primary bulky become-partner-btn"
                onClick={() => this.toggleModal(true)}
              >
                {t("landing:become_partner")}
              </div>
            </div>
          </CompanyStores>
        </div>
        {this.state.modalToggled && (
          <FindShopModal close={() => this.toggleModal(false)} />
        )}
      </Fragment>
    );
  };
}

const mapDispatchToProps = (dispatch) => ({
  setCountryCode: (country_code) =>
    dispatch(settingsActions.setCountryCode(country_code)),
  resetLayout: () => dispatch(layoutActions.resetLayout()),
  resetProcess: () => dispatch(registerActions.resetRegister()),
});

let WithTranslation = withTranslation()(LandingPage);

export default connect(null, mapDispatchToProps)(WithTranslation);
