import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import Input from "../../shared/forms/Input";

import "../../../stylesheets/shared/custom_dropdown.scss";

class DropdownSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      typing: false,
      toggled: false,
      focusedOption: 0,
      filteredCollection: this.filterCollection(props.search),
    };

    this.input = new React.createRef();
    this.dropdown = new React.createRef();
    this.optionsContainer = new React.createRef();
  }

  /**
   * The ev parameter can be null in some occasions.
   * For example when the only purpose of calling this method is to
   * simply close the dropdown.
   */
  toggle = (ev, isToggled) => {
    if (ev && ev.stopPropagation) ev.stopPropagation();
    if (!(ev instanceof Event) && ev && ev.persist) ev.persist(); // Persist event in synthetic or nullified event

    let { search, selectedValue, name } = this.props;
    let focusedOption = this.props.collection.find(
      (c) => c[name === "category" ? "id" : "code"] === selectedValue
    );
    // Depending on whether the user started typing, search for the selected option in the
    // respective collection.
    focusedOption = this.state.typing
      ? this.state.filteredCollection.indexOf(focusedOption)
      : this.props.collection.indexOf(focusedOption);

    this.setState((prevState) => ({
      toggled: typeof isToggled === "boolean" ? isToggled : !prevState.toggled,
      typing: false,
      // Reset if dropdown is closed.
      focusedOption: !isToggled ? 0 : focusedOption < 0 ? 0 : focusedOption,
      // Always filter options by the value of the input
      filteredCollection: this.filterCollection(
        ev && ev.target && ev.target.value ? ev.target.value : search
      ),
    }));

    if (!!isToggled) {
      this.optionsContainer.scrollTop = 0;
    }
  };

  componentDidMount = () =>
    document.addEventListener("mousedown", this.handleClickOutside);

  componentWillUnmount = () =>
    document.removeEventListener("mousedown", this.handleClickOutside);

  handleClickOutside = (ev) => {
    // Toggle the dropdown list only on click outside the input or an option.
    if (
      this.dropdown &&
      !this.dropdown.contains(ev.target) &&
      !ev.target.classList.contains("option")
    ) {
      this.setState({ typing: false });
      this.toggle(null, false);
      this.props.onBlur({ target: { value: 1 } }); // The props.onBlur event handler needs truthy value in order to not reset the select value to ""
    }
  };

  setFocusedOption = (index) => this.setState({ focusedOption: index });

  filterCollection = (search) =>
    this.props.collection.filter((c) => {
      let name =
        this.props.name === "country"
          ? c.name.toLowerCase()
          : this.props.t(`general:category_${c.name}`).toLowerCase();

      return name.indexOf(search.toLowerCase()) > -1;
    });

  handleOptionSelect = (ev, selectName, option) => {
    ev.stopPropagation();

    this.toggle(ev, false);
    this.props.handleOptionSelect(
      selectName,
      option.id || option.code,
      selectName === "category"
        ? this.props.t(`general:category_${option.name}`)
        : option.name
    );
    this.props.onBlur({ target: { value: 1 } }); // The props.onBlur event handler needs truthy value in order to not reset the select value to ""
  };

  handleInputChange = (ev) => {
    this.setState({ typing: true });

    let { value } = ev.target;

    // Reset selection whenever the input is completely empty.
    if (value.length === 0)
      this.props.handleOptionSelect(ev.target.name, "", "");

    let filteredCollection = this.filterCollection(value);
    // Filter out the dropdown options collection.
    this.setState((prevState) => ({
      filteredCollection,
      focusedOption:
        filteredCollection.length > 0 &&
        filteredCollection.length >= prevState.focusedOption + 1
          ? prevState.focusedOption
          : 0,
    }));

    this.props.handleInputChange(ev);
  };

  handleKeyDown = (ev) => {
    let { name, search, collection } = this.props;
    let { filteredCollection, typing } = this.state;
    // If the user is typing in the input use the proper collection.
    let collectionInUse = typing ? filteredCollection : collection;

    const onFocus = this.state.focusedOption;
    let nextFocused, focusedOptionElement;

    switch (ev.keyCode) {
      // Enter key
      case 13:
        ev.preventDefault();
        // If the dropdown is opened, pick the option at the current index selected.
        if (this.state.toggled) {
          // Prevent option select when there aren't any options at all.
          if (collectionInUse.length === 0) return;

          let selectedOption = collectionInUse[onFocus];
          this.props.onBlur({ target: { value: selectedOption.name } });
          this.props.handleOptionSelect(
            name,
            selectedOption.id || selectedOption.code,
            selectedOption.name
          );
          return this.toggle(null, false);
        }
        // Expand dropdown list if it is closed.
        this.props.onFocus();
        return this.toggle({ target: { value: ev.target.value } }, true);

      // Escape (27) and Tab(9) key
      case 27:
      case 9:
        this.toggle(null, false);
        return this.props.onBlur({ target: { value: search } });

      // Arrow down key
      case 40:
        // Expand dropdown list if it is closed.
        if (!this.state.toggled) {
          this.props.onFocus();
          return this.toggle({ target: { value: ev.target.value } }, true);
        }

        if (this.state.typing && collectionInUse.length < 2) return;

        nextFocused = onFocus === collectionInUse.length - 1 ? 0 : onFocus + 1;
        this.setFocusedOption(nextFocused);

        focusedOptionElement = document.getElementById(
          `dropdown-option-${name}-${nextFocused}`
        );
        return this.scrollOptionIntoView(focusedOptionElement);

      // Arrow up key
      case 38:
        // Prevent cursor jumping to the beginning
        ev.preventDefault();

        if (
          !this.state.toggled ||
          (this.state.typing && collectionInUse.length < 2)
        )
          return;

        nextFocused = onFocus === 0 ? collectionInUse.length - 1 : onFocus - 1;
        this.setFocusedOption(nextFocused);

        focusedOptionElement = document.getElementById(
          `dropdown-option-${name}-${nextFocused}`
        );
        return this.scrollOptionIntoView(focusedOptionElement);

      default:
        break;
    }
  };

  /**
   * Scroll the dropdown list so that the current option focused is visible.
   * @param {HTMLElement} option
   */
  scrollOptionIntoView(option) {
    let parentRect = this.optionsContainer.getBoundingClientRect();
    let childRect = option.getBoundingClientRect();
    // Measures whether the child's top and bottom borders are within the top and bottom borders of the suggestions container element.
    let optionViewable =
      childRect.top >= parentRect.top &&
      childRect.top + childRect.height <= parentRect.top + parentRect.height;

    // If the suggestion is not fully viewable, scroll the suggestions container.
    if (!optionViewable)
      this.optionsContainer.scrollTop =
        childRect.top + this.optionsContainer.scrollTop - parentRect.top;
  }

  render = () => {
    let isToggled = this.state.toggled ? "opened" : "";
    let {
      t,
      style,
      name,
      search,
      className,
      errorMessage,
      onFocus,
      withLabel = false,
      required,
      placeholder,
    } = this.props;

    return (
      <div
        className={`dropdown-container ${withLabel ? "with-label" : ""} ${
          className || ""
        }`}
      >
        {withLabel && (
          <label className="blue-Regular-14px" htmlFor={name || ""}>
            {placeholder}
          </label>
        )}
        <div
          className={`custom-dropdown ${isToggled}`}
          ref={(node) => (this.dropdown = node)}
          style={style}
        >
          <div
            className="placeholder d-flex justify-content-between align-items-center"
            onClick={() => this.toggle(null, true)}
          >
            <Input
              setRef={(node) => (this.input = node)}
              required={typeof required === "undefined" ? true : required}
              errorMessage={errorMessage}
              value={search}
              onChange={this.handleInputChange}
              onFocus={onFocus}
              onKeyDown={this.handleKeyDown}
              placeholder={withLabel ? "" : placeholder}
              autocomplete="nope"
            />
            <img
              src={`${process.env.PUBLIC_URL}/images/assets/open.svg`}
              alt="Open"
              onClick={(ev) => this.toggle(ev)}
            />
          </div>
          <div
            className="dropdown-list"
            ref={(node) => (this.optionsContainer = node)}
          >
            {(this.state.typing
              ? this.state.filteredCollection
              : this.props.collection
            ).map((option, index) => {
              let isFocused =
                this.state.focusedOption === index ? "focused" : "";

              return (
                <div
                  className={`option ${isFocused}`}
                  key={option.name}
                  onClick={(ev) => this.handleOptionSelect(ev, name, option)}
                  onMouseOver={() => this.setFocusedOption(index)}
                  id={`dropdown-option-${name}-${index}`}
                >
                  {name === "category" && (
                    <img
                      src={`/images/categories/${option.name}@2x.png`}
                      className="category-icon"
                      alt={option.name}
                    />
                  )}
                  {name === "country"
                    ? option.name
                    : t(`general:category_${option.name}`)}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };
}

export default withTranslation()(DropdownSearch);
