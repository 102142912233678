import React from "react";
import { ScaleIn } from "../hocs/AnimationHOC";

const InfoMessage = ({ title, text, children }) => {
  return (
    <div className="info-message">
      <ScaleIn className="info-message-container">
        <div className="blue-Bold-30px-center message-title">{title}</div>
        <div className="blue-Regular-18px text-center message-text">{text}</div>
        <div className="message-actions text-center">{children}</div>
      </ScaleIn>
    </div>
  );
};

export default InfoMessage;
