import React from "react";
import { withTranslation } from "react-i18next";

import { connect } from "react-redux";
import registerActions from "../../../actions/register";

import InfoBox from "../../shared/InfoBox";
import Checkbox from "../../shared/forms/Checkbox";

import DiscountTextSelect from "./DiscountTextSelect";

const CouponsOffered = ({
  t,
  details,
  toggleCouponOption,
  selectDiscountText,
  saveCustomDiscountText,
}) => {
  return (
    <div className="coupon-options-container">
      <h4 className="blue-Bold-20px-left">{t("details:coupons_heading")}</h4>
      <InfoBox text={t("details:coupons_info_box")} />
      <div className="options-container">
        <Checkbox
          initialValue={details.offers_project_tree}
          onClick={(toggled) =>
            toggleCouponOption("offers_project_tree", toggled)
          }
        >
          {t("details:coupons_project_tree")}
        </Checkbox>
        <Checkbox
          initialValue={details.offers_project_ocean}
          onClick={(toggled) =>
            toggleCouponOption("offers_project_ocean", toggled)
          }
        >
          {t("details:coupons_project_ocean")}
        </Checkbox>
        <Checkbox
          initialValue={details.offers_discount}
          onClick={(toggled) => toggleCouponOption("offers_discount", toggled)}
        >
          <span
            dangerouslySetInnerHTML={{ __html: t("details:coupons_discount") }}
          />
        </Checkbox>
        {details.offers_discount && (
          <div className="select-discount-text">
            <DiscountTextSelect
              placeholder={details.custom_text || details.discount_text}
              custom_text={details.custom_text}
              selectDiscountText={selectDiscountText}
              saveCustomDiscountText={saveCustomDiscountText}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  details: state.register.coupon_options,
});

const mapDispatchToProps = (dispatch) => ({
  toggleCouponOption: (option, toggled) =>
    dispatch(registerActions.toggleCouponOption(option, toggled)),
  selectDiscountText: (discount_text) =>
    dispatch(registerActions.selectDiscountText(discount_text)),
  saveCustomDiscountText: (custom_text) =>
    dispatch(registerActions.saveCustomDiscountText(custom_text)),
});

let reduxWrapped = connect(mapStateToProps, mapDispatchToProps)(CouponsOffered);
export default withTranslation()(reduxWrapped);
