import React from "react";
import { withTranslation } from "react-i18next";

const NeedHelp = ({ t }) => (
  <div className="need-help">
    <h6 className="blue-ExtraBold-14px">{t("need_help_title")}</h6>
    <p
      className="blue-Regular-14px"
      dangerouslySetInnerHTML={{ __html: t("need_help_text") }}
    />
  </div>
);

export default withTranslation("reorder")(NeedHelp);
