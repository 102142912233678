import interact from "interactjs";

export const unset = (selector) => interact(selector).unset();

export const draggable = (selector, getPosition) =>
  interact(selector).draggable({
    listeners: {
      move: (event) => getPosition({ x: event.dx, y: event.dy }),
    },
    modifiers: [interact.modifiers.restrictRect({ restriction: "parent" })],
  });

export const resizable = (selector, cacheSizes, min, ratio) => {
  interact(selector)
    .resizable({
      edges: { left: true, right: true, bottom: true, top: true },
      preserveAspectRatio: true,
      modifiers: [
        interact.modifiers.aspectRatio({ ratio }),
        interact.modifiers.restrictEdges({
          outer: "parent",
          endOnly: false,
        }),
        interact.modifiers.restrictSize({
          min: { width: min, height: min },
        }),
      ],
      inertia: false,
    })
    .on("resizemove", (event) => {
      let { target } = event;
      let x = parseFloat(target.getAttribute("data-x")) || 0;
      let y = parseFloat(target.getAttribute("data-y")) || 0;

      // translate when resizing from top or left edges
      x += event.deltaRect.left;
      y += event.deltaRect.top;

      cacheSizes({
        size: { width: event.rect.width, height: event.rect.height },
        position: { x, y },
      });
    });
};
