import React, { useCallback, useEffect, useRef } from "react";

import { withTranslation } from "react-i18next";
import { injectIntl } from "react-intl";

import { config } from "../../../constants/designs";
import { currency_rates } from "../../../constants/internationals";
import { getBagPrices } from "../../../helpers/ProcessHelpers";
import { useDesignsQuery } from "../../../services/queries/BgoodProductQueries";

import DesignOption from "./DesignOption";

const DesignOptionsList = ({t,
  intl,
  i18n,
  country,
  quantities,
  step,
  plan,
  setQuantity,
  custom_logo,
  saveLogo,
  showBagDetails,
  updateDesignsHeight}) => {
  
  const containerRef = useRef();

  /**
   * Whenever the bag design options container's dimensions change,
   * it would update the redux state and the designs summary height
   * on desktop would update because of that.
   */
  const handleListLoad = useCallback(() => {
    let { height } = containerRef.current.getBoundingClientRect();
    updateDesignsHeight(height);
  }, [updateDesignsHeight]);

  useEffect(() => {
    window.addEventListener("resize", handleListLoad);
  
    // returned function will be called on component unmount 
    return () => {
      window.removeEventListener("resize", handleListLoad);
    }
  }, [handleListLoad])

    let language = i18n.language;
    let currency = currency_rates[country] || currency_rates.default;
    let bags_prices = getBagPrices(quantities, plan);

    let {data, isLoading, isError} = useDesignsQuery();

    let designs = data;

    let getBagPriceStr = (bag_price) =>
      intl.formatNumber(bag_price, {
        style: "currency",
        currency: currency.code,
        currencyDisplay: "symbol",
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });

    if(isLoading || isError) {
      return <div></div>;
    }

    return (
      <div
        className="designs-categories"
        ref={containerRef}
        onLoad={handleListLoad}
      >
        <div className="category model-cups-large">
          <h2 className="blue-Bold-20px-left">
            {designs.cups_large.title[language] || designs.cups_large.title.en}:
          </h2>
          <div
            className="design-options-list"
          >
            {Object.keys(designs.cups_large.bags)
              .filter((name) => !designs.cups_large.bags[name].is_custom && designs.cups_large.bags[name].display_for_partners)
              .map((name) => (
                <DesignOption
                  image={designs.cups_large.bags[name].images[0]}
                  key={name}
                  item={designs.cups_large.bags[name]}
                  name={designs.cups_large.bags[name].name}
                  color={designs.cups_large.bags[name].i18n_variant_color}
                  showDetails={() => showBagDetails({ size: "cups_large", name, key: name })}
                  quantity={quantities.cups_large[name]}
                  min={step === 'register' ? config.MIN_QUANTITY_REGULAR : config.MIN_QUANTITY_REGULAR_REORDER}
                  placeholder={t("bag_option_min", {
                    quantity: step === 'register' ? config.MIN_QUANTITY_REGULAR : config.MIN_QUANTITY_REGULAR_REORDER,
                  })}
                  price={getBagPriceStr(bags_prices.standard_cups * currency.rate)}
                  setQuantity={(quantity) => setQuantity("cups_large", name, quantity)}
                />
            ))}
          </div>
        </div>


        <div className="category model-large">
          <h2 className="blue-Bold-20px-left">
            {designs.large.title[language] || designs.large.title.en} (
            {designs.large.details.size}):
          </h2>
          <div
            className="design-options-list"
          >
            {Object.keys(designs.large.bags)
              .filter((name) => !designs.large.bags[name].is_custom && designs.large.bags[name].display_for_partners)
              .map((name) => (
                <DesignOption
                  image={designs.large.bags[name].images[0]}
                  key={name}
                  item={designs.large.bags[name]}
                  name={designs.large.bags[name].name}
                  color={designs.large.bags[name].i18n_variant_color}
                  showDetails={() => showBagDetails({ size: "large", name, key: name })}
                  quantity={quantities.large[name]}
                  min={step === 'register' ? config.MIN_QUANTITY_REGULAR : config.MIN_QUANTITY_REGULAR_REORDER}
                  placeholder={t("bag_option_min", {
                    quantity: step === 'register' ? config.MIN_QUANTITY_REGULAR : config.MIN_QUANTITY_REGULAR_REORDER,
                  })}
                  price={getBagPriceStr(bags_prices.standard * currency.rate)}
                  setQuantity={(quantity) => setQuantity("large", name, quantity)}
                />
            ))}
            <DesignOption
              image={`${process.env.PUBLIC_URL}/images/register/designs/bag/large/empty.png`}
              custom_logo={custom_logo}
              saveLogo={saveLogo}
              name={t("your_logo")}
              showDetails={() => showBagDetails({ size: "large", name: 'custom', color: 'light', key: 'custom_large_light' })}
              quantity={quantities.large.custom}
              price={getBagPriceStr(bags_prices.custom_large * currency.rate)}
              min={config.MIN_QUANTITY_CUSTOM}
              placeholder={t("bag_option_min", {
                quantity: config.MIN_QUANTITY_CUSTOM,
              })}
              setQuantity={(quantity) =>
                setQuantity("large", "custom", quantity)
              }
            />
          </div>
        </div>
        <div className="category model-medium">
          <h2 className="blue-Bold-20px-left">
            {designs.medium.title[language] || designs.medium.title.en} (
            {designs.medium.details.size}):
          </h2>
          <div
            className="design-options-list"
          >
            {Object.keys(designs.medium.bags)
            .filter((name) => !designs.medium.bags[name].is_custom && designs.medium.bags[name].display_for_partners)
            .map((name) => (
              <DesignOption
                image={designs.medium.bags[name].images[0]}
                key={name}
                item={designs.medium.bags[name]}
                name={designs.medium.bags[name].name}
                color={designs.medium.bags[name].i18n_variant_color}
                showDetails={() => showBagDetails({ size: "medium", name, key: name })}
                quantity={quantities.medium[name]}
                min={step === 'register' ? config.MIN_QUANTITY_REGULAR : config.MIN_QUANTITY_REGULAR_REORDER}
                placeholder={t("bag_option_min", {
                  quantity: step === 'register' ? config.MIN_QUANTITY_REGULAR : config.MIN_QUANTITY_REGULAR_REORDER,
                })}
                price={getBagPriceStr(bags_prices.standard * currency.rate)}
                setQuantity={(quantity) =>
                  setQuantity("medium", name, quantity)
                }
              />
            ))}
            <DesignOption
              image={`${process.env.PUBLIC_URL}/images/register/designs/bag/medium/medium_empty.png`}
              custom_logo={custom_logo}
              saveLogo={saveLogo}
              name={t("your_logo")}
              showDetails={() => showBagDetails({ size: "medium", name: 'custom', key: 'custom_medium_light' })}
              quantity={quantities.medium.custom}
              price={getBagPriceStr(bags_prices.custom_medium * currency.rate)}
              min={config.MIN_QUANTITY_CUSTOM}
              placeholder={t("bag_option_min", {
                quantity: config.MIN_QUANTITY_CUSTOM,
              })}
              setQuantity={(quantity) =>
                setQuantity("medium", "custom", quantity)
              }
            />
          </div>

          <div className='blue-Regular-14px print-info'>
            *{t('print_info')}
          </div>
        </div>
      </div>
    );
  };

const WithIntl = injectIntl(DesignOptionsList);
export default withTranslation('reorder')(WithIntl);
