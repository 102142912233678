import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import Input from "../../shared/forms/Input";

class DiscountTextSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      toggled: false,
      inputText: this.props.custom_text,
      focusedOption: 0,
    };

    this.dropdown = new React.createRef();

    this.defaultDiscountTexts = [
      props.t("details:coupons_default_discount_text_3_percent"),
      props.t("details:coupons_default_discount_text_5_percent"),
      props.t("details:coupons_default_discount_text_10_percent"),
    ];
  }

  toggle = (ev, toggled) => {
    if (ev) ev.stopPropagation();
    this.setState({ toggled, focusedOption: 0 });
  };

  componentDidMount = () =>
    document.addEventListener("mousedown", this.handleClickOutside);

  componentWillUnmount = () =>
    document.removeEventListener("mousedown", this.handleClickOutside);

  handleClickOutside = (event) => {
    if (this.dropdown && !this.dropdown.contains(event.target)) {
      this.toggle(null, false);
      this.setState({ inputText: this.props.custom_text });
    }
  };

  handleOptionSelect = (ev) => {
    ev.stopPropagation();
    this.toggle(null, false);
  };

  handleInputChange = (ev) => {
    let { value } = ev.target;
    this.setState({ inputText: value });
  };

  handleKeyDown = (ev) => {
    const onFocus = this.state.focusedOption;
    let nextFocused;

    switch (ev.keyCode) {
      // "Enter" key
      case 13:
        ev.preventDefault();
        // Select the focused option from the list.
        if (!this.state.inputText) {
          let discountText = this.defaultDiscountTexts[onFocus];
          this.props.selectDiscountText(discountText);
          return this.toggle(null, false);
        }

        return this.saveCustomText();

      // "Escape" key
      case 27:
        return this.toggle(null, false);

      case 40:
        if (!this.state.toggled) return this.toggleResults(true);

        nextFocused = onFocus === 2 ? 0 : onFocus + 1;
        return this.setFocusedOption(nextFocused);

      // Arrow up key
      case 38:
        ev.preventDefault();
        if (!this.state.toggled) return;

        nextFocused = onFocus === 0 ? 2 : onFocus - 1;
        return this.setFocusedOption(nextFocused);

      default:
        break;
    }
  };

  setFocusedOption = (index) => this.setState({ focusedOption: index });

  saveCustomText = () => {
    const { inputText } = this.state;
    const { length } = inputText.trim();

    if (length > 3) {
      this.toggle(null, false);
      this.props.saveCustomDiscountText(inputText);
    } else if (!length) {
      this.setState({ inputText: "" }, () => {
        this.toggle(null, false);
        this.props.saveCustomDiscountText("");
      });
    }
  };

  render = () => {
    let isToggled = this.state.toggled ? "opened" : "";
    let { selectDiscountText, t } = this.props;

    return (
      <div className="dropdown-container">
        <div
          className={`custom-dropdown ${isToggled}`}
          ref={(node) => (this.dropdown = node)}
          style={this.props.style}
        >
          <div
            className="placeholder d-flex justify-content-between align-items-center"
            onClick={() => this.toggle(null, true)}
          >
            {this.state.toggled ? (
              <Input
                name="discount-text"
                maxLength={254}
                value={this.state.inputText}
                onChange={this.handleInputChange}
                onKeyDown={this.handleKeyDown}
                placeholder={t("details:coupons_custom_text")}
              />
            ) : (
              <span>{this.props.placeholder}</span>
            )}
            <i
              className="fas fa-chevron-down"
              onClick={(ev) => this.toggle(ev, !this.state.toggled)}
            />
          </div>
          <div className="dropdown-list" onClick={this.handleOptionSelect}>
            {this.state.inputText ? (
              <div className="save-custom-text">
                <div>{this.state.inputText}</div>
                {this.state.inputText !== this.props.custom_text && (
                  <div className="text-right">
                    <div
                      className="btn btn-primary save-btn"
                      onClick={this.saveCustomText}
                    >
                      {t("details:coupons_save_custom")}
                    </div>
                  </div>
                )}
              </div>
            ) : (
              this.defaultDiscountTexts.map((text, index) => {
                let isFocused =
                  this.state.focusedOption === index ? "focused" : "";
                return (
                  <div
                    className={`option ${isFocused}`}
                    key={index}
                    onClick={() => selectDiscountText(text)}
                    onMouseOver={() => this.setFocusedOption(index)}
                  >
                    <div className="content">{text}</div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
    );
  };
}

export default withTranslation()(DiscountTextSelect);
