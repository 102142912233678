import React from "react";
import { navigate } from "../../shared/routes/CustomBrowserRouter";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { countries } from "../../../constants/internationals";
import routes from "../../../constants/routes";
import reorderActions from "../../../actions/reorder";

import PreviewSection from "../../register/confirmation/PreviewSection";
import ContactInfoPreviewSection from "../../register/confirmation/ContactInfoPreviewSection";

export default () => {
  const { t } = useTranslation("reorder");
  const { registered } = useSelector((s) => s.reorder);
  const dispatch = useDispatch();

  const urlArea = window.location.pathname.match(/\/new.*/)
    ? "new_business"
    : "reorder";

  const redirectToStep = () => {
    dispatch(reorderActions.setEditingStep("details"));
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate(routes[urlArea].details);
  };

  let { contact_info, shipping_address, billing_address } = useSelector(
    (s) => s.reorder
  );

  billing_address = billing_address.same_as_shipping
    ? shipping_address
    : billing_address;

  return (
    <div className="shop-details-column column">
      <ContactInfoPreviewSection
        editEnabled={!registered}
        contact_info={contact_info}
        redirectToStep={redirectToStep}
      />
      <PreviewSection
        heading={t("shipping_address_heading")}
        link={redirectToStep}
        className="shipping-address-section"
        name="shipping_address"
      >
        <div className="info-row">
          <div className="blue-Bold-14px text-left">{t("recipient_title")}</div>
          <div className="blue-Regular-14px">{shipping_address.contact_name}</div>
        </div>
        <div className="info-row">
          <div className="blue-Bold-14px text-left">
            {t("address")}
          </div>
          <div className="blue-Regular-14px">
            {shipping_address.address}, {shipping_address.postcode}{" "}
            {shipping_address.city},{" "}
            {
              countries.find((c) => c.code === shipping_address.country_code)
                .name
            }
          </div>
        </div>
      </PreviewSection>
      <PreviewSection
        heading={t("billing_address_heading")}
        link={redirectToStep}
        className="billing-address-section"
        name="billing_address"
      >
        <div className="info-row">
          <div className="blue-Bold-14px text-left">{t("recipient_title")}</div>
          <div className="blue-Regular-14px">{billing_address.contact_name}</div>
        </div>
        <div className="info-row">
          <div className="blue-Bold-14px text-left">
            {t("address")}
          </div>
          <div className="blue-Regular-14px">
            {billing_address.address}, {billing_address.postcode}{" "}
            {billing_address.city},{" "}
            {
              countries.find((c) => c.code === billing_address.country_code)
                .name
            }
          </div>
        </div>
      </PreviewSection>
    </div>
  );
};
