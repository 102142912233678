import React from "react";
import { useTranslation } from "react-i18next";
import CookiesService from "../../services/storage/CookieService";
import { CookieBanner } from "@palmabit/react-cookie-law";

import {useSelector} from "react-redux";

export default () => {
  const { openFromApp } = useSelector((s) => s.settings);
  const { t } = useTranslation("general");

  if (openFromApp) return null;

  /**
   * This function will call the corresponding functions after the user clicked on "Activate all" or "Save preferences".
   * As we'll only load the scripts opt-in, we don't have to handle the negative cases
   * (If enabled==false, it won't include the scripts anyways)
   * @param {string} feature 
   * @param {boolean} enabled 
   */
  const onSelect = (feature, enabled) => {
    if(typeof window !== "undefined") {
        if(enabled) {
            if(feature === 'statistics') {
                CookiesService.activateStatisticModules();
            }
            if(feature === 'marketing') {
                CookiesService.activateMarketingModules();
            }
        }
    }
  }

  return (
      <CookieBanner
        message={t("cookie_consent_text_start")}
        privacyPolicyLinkText={t("cookie_consent_privacy_link_text")}
        acceptButtonText={t("cookie_consent_accept_all")}
        managePreferencesButtonText={t("cookie_consent_privacy_manage")}
        savePreferencesButtonText={t("cookie_consent_accept_save")}
        necessaryOptionText={t("cookie_consent_necessary")}
        statisticsOptionText={t("cookie_consent_statistics")}
        marketingOptionText={t("cookie_consent_marketing")}
        wholeDomain={true}
        onAccept={() => onSelect('accept', true)}
        onAcceptStatistics={() => onSelect('statistics', true)}
        onAcceptMarketing={() => onSelect('marketing', true)}
        onDeclineStatistics={() => onSelect('statistics', false)}
        onDeclineMarketing={() => onSelect('marketing', false)}
        showPreferencesOption={false}
        policyLink={'/privacy'}
        styles={{ // need to reset the style properties here, else the scss styles won't work
            dialog: {
            },
            container: {
            },
            message: {
            },
            policy: {
            },
            selectPane: {
            },
            optionWrapper: {
            },
            optionLabel: {
            },
            checkbox: {
            },
            buttonWrapper: {
            },
            button: {
            }
        }}
        />
  );

};
