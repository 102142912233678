import { config as bags_config } from "../constants/designs";
import { bag_prices, shipping_prices } from "../constants/prices";
import plans from "../constants/plans";
import designs from "../constants/designs";
import routes from "../constants/routes";
import REGISTER_STEPS, { REORDER_STEPS } from "../constants/steps";

import reduxStore from "../config/store";

import { validateEmail } from "./FormHelpers";

import { history, navigate } from "../components/shared/routes/CustomBrowserRouter";
import { __DEVELOP_CONFIRMATION_SET_TO_FALSE_IN_PRODUCTION } from "../components/reorder/ReorderPage";

export const validateShopDetails = (details) => {
  let isValid = true;
  let { contact_info, shop_details, coupon_options } = details;

  if (
    !contact_info.company ||
    !contact_info.contact ||
    !validateEmail(contact_info.email)
  )
    isValid = false;

  if (
    !shop_details.name ||
    !shop_details.address ||
    !shop_details.city ||
    !shop_details.postcode ||
    !shop_details.country ||
    !shop_details.category
  )
    isValid = false;

  if (!Object.values(coupon_options).find((v) => v === true)) isValid = false;

  return isValid;
};

/**
 * Validate all three contact info, shipping and billing details
 * for the reorder process.
 * If "same_as_shipping" is selected for the billing address,
 * only the contact info and the shipping address are validated.
 * @return {boolean}
 */
export const validateReorderDetails = ({
  contact_info,
  shipping_address: { email, phone, ..._shippingAddress}, // ignore email and phone number
  billing_address,
}) => {
  const { registered } = reduxStore.getState().reorder;

  if (!contact_info || !_shippingAddress || !billing_address) return false;

  // Contact info is always valid if the user is registered,
  // even if a field is missing in the db
  let contactInfoValid = registered
    ? true
    : typeof Object.values(contact_info).find((v) => !v) === "undefined";
  let shippingAddressValid =
    typeof Object.values(_shippingAddress).find((v) => !v) === "undefined";
  let billingAddressValid = billing_address.same_as_shipping
    ? shippingAddressValid
    : typeof Object.entries(billing_address).find(
        ([key, val]) => key !== "same_as_shipping" && !val
      ) === "undefined";

  return contactInfoValid && shippingAddressValid && billingAddressValid;
};

/**
 * Adjust the horizontal process steps scrollbar accordingly to the
 * current step. Used by both register and reorder processes.
 * @param {('details'|'plan'|'designs'|'confirmation')} step
 */
export const adjustProcessNavigationScroll = (step) => {
  let container = document.querySelector(".steps-navigation > .my-container");
  let current = document.querySelector(`.navigation-step.${step}`);

  let index = document.querySelectorAll(".navigation-step");
  for (let i = 0; i < index.length; i++) {
    if (index[i].classList.contains(step)) {
      index = i;
      break;
    }
  }

  if (current)
    container.scrollTo({ left: current.offsetLeft, behavior: "smooth" });
};

/**
 * Get the price of a bag depending on its type and selected quantities.
 * @param {('standard'|'custom_large'|'custom_medium')} type
 * @param {number} quantity
 * @returns {number}
 */
export const getBagPrice = (type, quantity) => {
  let price_list = bag_prices[type];
  let price_range = "100";

  Object.keys(price_list)
    .reverse()
    .forEach(
      (boundary) => parseInt(boundary) > quantity && (price_range = boundary)
    );

  return price_list[price_range];
};

/**
 * Each plan has a fixed quantity of free (standard) bags.
 * Depending on the quantities selected by the user, get the corresponding
 * price for both - the sum quantity of the standard bags selected and the
 * quantity of custom bags.
 * @param {object} quantities
 * @param {('ondemand'|'standard'|'premium')} plan
 * @returns {{standard: number, standard_cups: number, custom_large: number, custom_medium: number}}
 */
export const getBagPrices = (quantities, plan) => {
  return {
    standard: plans[plan].bag_price,
    standard_cups: plans[plan].cup_price,
    custom_large: getBagPrice("custom_large", parseInt(quantities.large.custom, 10) || 0),
    custom_medium: getBagPrice("custom_medium", parseInt(quantities.medium.custom, 10) || 0),
  };
};

/**
 * Returns the prices for the current order
 * @param {object} quantities
 * @param {object} plan
 * @param {('ondemand'|'standard'|'premium')} plan
 * @returns {{totalBagsCost: number, totalCupsCost: number, productsTotalCost: number}}
 */
export const getTotalPrice = (quantities, plan, currency) => {

  const { charge_bags, charge_cups, custom_large_bags, custom_medium_bags } = getBagsQuantities(
    plan,
    quantities,
    designs,
  );

  const totalBagsCost =
    (plans[plan].bag_price * charge_bags +
      getBagPrice("custom_large", custom_large_bags) * custom_large_bags + 
      getBagPrice("custom_medium", custom_medium_bags) * custom_medium_bags) *
    currency.rate;
  const totalCupsCost =
      plans[plan].cup_price * charge_cups *
    currency.rate;

  const productsTotalCost = totalBagsCost + totalCupsCost;

  return {totalBagsCost, totalCupsCost, productsTotalCost};
};

/**
 * Returns an object with the quantities of the free bags, the bags that need to be paid for and the custom bags.
 * @param {string} plan
 * @param {object} quantities
 * @returns {{charge_bags_free: number, charge_bags: number, custom_bags: number, custom_medium_bags: number}}
 */
export const getBagsQuantities = (plan, quantities, designs) => {
  let free_bags = plans[plan].free_bags;
  let charge_bags = 0;
  let charge_cups = 0;
  let custom_large_bags = parseInt(quantities.large.custom, 10) || 0;
  let custom_medium_bags = parseInt(quantities.medium.custom, 10) || 0;

  Object.keys(quantities).forEach((size) =>
    Object.keys(quantities[size]).forEach(
      (design) => {
        if(!designs[size].bags) {
          return;
        }
        if(design !== "custom" &&
          designs[size].bags[design].product_type === 'goodbag') {
          charge_bags += parseInt(quantities[size][design], 10) || 0;
        }
        if(design !== "custom" &&
          designs[size].bags[design].product_type === 'goodcup') {
          charge_cups += parseInt(quantities[size][design], 10) || 0;
        }
      }
    )
  );

  free_bags =
    charge_bags > 0 || custom_large_bags > 0 || custom_medium_bags > 0
      ? free_bags
      : 0;
  let charge_bags_free = 0;
  if (charge_bags > 0) {
    charge_bags_free = charge_bags > free_bags ? free_bags : charge_bags;
  }
  charge_bags =
    charge_bags - charge_bags_free > 0 ? charge_bags - charge_bags_free : 0;

  return { charge_bags, charge_cups, custom_large_bags, custom_medium_bags, charge_bags_free };
};

/**
 * Get the next step for the registration process (details, plan, designs or confirmation)
 * depending on which is the first one to be done yet in the collection.
 * @returns {string} - The name of the next step that follows.
 */
export const getNextRegistrationStep = () => {
  let { register } = reduxStore.getState();

  if (!validateShopDetails(register)) return "details";

  if (!register.plan) return "plan";

  let anyDesignSelected = Object.keys(register.designs).find((size) =>
    Object.values(register.designs[size]).find(
      (design) => parseInt(design) >= bags_config.MIN_QUANTITY_REGULAR
    )
  );

  if (!anyDesignSelected) return "designs";

  return "confirmation";
};

/**
 * Get the next step for the reorder process (designs or confirmation)
 * depending on which is the first one to be done yet in the collection.
 * @returns {string} - The name of the next step that follows.
 */
export const getNextReorderStep = () => {
  const {
    reorder: { designs, contact_info, shipping_address, billing_address },
  } = reduxStore.getState();

  const anyDesignSelected = Object.keys(designs).find((size) =>
    Object.values(designs[size]).find(
      (design) => parseInt(design) >= bags_config.MIN_QUANTITY_REGULAR_REORDER
    )
  );

  if (!anyDesignSelected) return "designs";

  if (
    !validateReorderDetails({
      contact_info,
      shipping_address,
      billing_address,
    })
  )
    return "details";

  return "confirmation";
};

/**
 * Prevent user going to next steps without completing the current one.
 * This handles the case when the user completed one step (step X), went to the next one(step Y),
 * then returned to step X using the browser back button or the process navigation bar
 * edited the data so that the step is no more "completed" and then tries to go to step Y.
 * @returns {boolean} - Whether the user at a step that he should NOT be at currently.
 */
export const goToFirstIncompleteStep = (procedure) => {
  if(!__DEVELOP_CONFIRMATION_SET_TO_FALSE_IN_PRODUCTION) {
    const steps = procedure === "register" ? REGISTER_STEPS : REORDER_STEPS;
    const currentPath = history.location.pathname;
    // Get the next step that should be completed
    const nextStep =
      procedure === "register" ? getNextRegistrationStep() : getNextReorderStep();
    const currentStep = currentPath.split("/")[3];
    // Check if the user is at a process step but it happens that he hasn't completed a prior step
    if (
      steps.indexOf(currentStep) > steps.indexOf(nextStep) &&
      currentStep !== nextStep
    ) {
      const nextPath = `${routes[procedure].start}/${nextStep}`;
      // redirect the user to the step he should really be at
      navigate(nextPath, { skipRedirect: true });
      return true;
    }
  }
  return false;
};

/**
 * Takes the opening hours object and converts it to a string.
 * @param opening_hours {{
 *   Monday: { open: boolean, from: string, to: string },
 *   ...rest of the week, constructed in the same fashion,
 *   Sunday: { open: boolean, from: string, to: string}
 * }}
 * @return {'Mo (off|opening time-closing time(e.g. 12:00-17:00)); [...rest of week in the same way]; PH off'}
 */
export const formatOpeningHours = (opening_hours) => {
  let opening_hours_os = [];
  Object.keys(opening_hours).forEach((day) => {
    let shortName = day.substr(0, 2);
    day = opening_hours[day];

    if (day.open && day.from && day.to)
      return opening_hours_os.push(`${shortName} ${day.from}-${day.to}`);

    opening_hours_os.push(`${shortName} off`);
  });
  return `${opening_hours_os.join("; ")}; PH off`;
};

/**
 * * Calculates the shipping price.
 * If the shipping address' country is not within the list of eligible
 * for shipping countries, return null. If yes - calculate the shipping price.
 * @param {Number} quantity
 * @param {string|null} country_code
 * @return {{boxes: number, cost: number}|null|number}
 */
export const calculateShippingPrice = (quantity, country_code) => {
  if (!quantity || !country_code) return { cost: 0, boxes: 0 };

  if (!shipping_prices.eligibleCountries.includes(country_code)) return null;

  let totalQuantity = quantity;

  let shippingCost = 0;
  const priceRanges = Object.keys(shipping_prices);
  const lastPricingRange = parseInt(priceRanges[3]);
  const iterations = Math.ceil(totalQuantity / lastPricingRange);

  for (let i = 0; i < iterations; i++) {
    // If the total quantity of bags is less or equal to the first quantity price range
    if (totalQuantity <= parseInt(priceRanges[0])) {
      shippingCost += parseInt(
        Object.entries(
          shipping_prices[priceRanges[0]]
        ).find(([price, countries]) => countries.includes(country_code))[0]
      );
      totalQuantity = 0;
      break;
    }
    // If we have a total quantity number bigger than the upper boundary of the
    // last quantity price range ( e.g. last range is 80-100 but all bags are 200)
    if (totalQuantity > lastPricingRange) {
      shippingCost += parseInt(
        Object.entries(
          shipping_prices[lastPricingRange]
        ).find(([price, countries]) => countries.includes(country_code))[0]
      );

      totalQuantity -= lastPricingRange;
    } else {
      // Find the range that the current amount of bags belong to
      // eslint-disable-next-line no-loop-func
      const pricingRange = Object.keys(shipping_prices).find((range, index) => {
        if (isNaN(parseInt(range)) || index === 0) return false;
        return (
          parseInt(range) >= totalQuantity &&
          parseInt(priceRanges[index - 1]) < totalQuantity
        );
      });

      const shippingAmount = parseInt(
        Object.entries(
          shipping_prices[pricingRange]
        ).find(([price, countries]) => countries.includes(country_code))[0]
      );
      shippingCost += shippingAmount;
      totalQuantity = 0;
    }
  }
  return { cost: shippingCost, boxes: iterations };
};
