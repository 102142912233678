import React, { Component, Fragment } from "react";

import "../../../polyfills/globalThis";
import { IMaskInput } from "react-imask";
import IMask from "imask";
import { withTranslation } from "react-i18next";

import { connect } from "react-redux";
import registerActions from "../../../actions/register";

import { ScaleIn, FadeIn } from "../../hocs/AnimationHOC";

import Toggle from "../../shared/Toggle";

class OpeningHoursModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      saveDisabled: false,
      openingHours: this.props.opening_hours,
    };

    this.modal = new React.createRef();
    this.saveButton = new React.createRef();
  }

  componentDidMount = () => {
    document.addEventListener("keydown", this.handleKeyDown);
  };

  componentWillUnmount = () => {
    document.removeEventListener("keydown", this.handleKeyDown);
  };

  checkTableValid = () => {
    // Get all opening hours rows that are enabled (the shop is opened on that day)
    const enabledOpeningHours = Object.values(this.state.openingHours).filter(
      (d) => d.open
    );
    // Get all rows where opening time is earlier than closing time
    let invalidTimeRanges = enabledOpeningHours.filter(
      (d) =>
        parseInt(d.from.replace(":", ""), 10) >=
        parseInt(d.to.replace(":", ""), 10)
    );
    // Get all rows that miss either opening or closing time
    let missingFromOrToField = enabledOpeningHours.filter(
      (d) => (d.from && !d.to) || (!d.from && d.to)
    );

    return invalidTimeRanges.length === 0 && missingFromOrToField.length === 0;
  };

  toggleOhDay = (id) => {
    this.setState(
      (state) => ({
        openingHours: {
          ...state.openingHours,
          [id]: {
            ...state.openingHours[id],
            open: !state.openingHours[id].open,
          },
        },
      }),
      () => this.setState({ saveDisabled: !this.checkTableValid() })
    );
  };

  handleOhInputChange = (field, day) => {
    let { name, value } = field;
    let { openingHours } = this.state;

    openingHours[day][name] = value;

    this.setState({ openingHours }, () =>
      this.setState({ saveDisabled: !this.checkTableValid() })
    );
  };

  onSave = (ev) => {
    if (ev) ev.preventDefault();

    // Check if there are any day entries where close time is earlier than open time
    let tableValid = this.checkTableValid();
    if (!tableValid) return this.setState({ saveDisabled: true });

    this.props.save(this.state.openingHours);
    this.props.close();
  };

  render = () => {
    let { openingHours } = this.state;
    let { t } = this.props;
    this.checkTableValid();
    let isSaveDisabled = this.state.saveDisabled ? "disabled" : "";

    return (
      <div className="opening-hours-modal">
        <ScaleIn className="opening-hours-modal-content">
          <div ref={(node) => (this.modal = node)}>
            <form id="opening-hours-form" onSubmit={this.onSave}>
              <div className="d-flex justify-content-between align-items-center modal-heading">
                <h4 className="blue-Bold-20px-left">
                  {t("details:opening_hours_modal_heading")}
                </h4>
                <img
                  src={`${process.env.PUBLIC_URL}/images/assets/close.svg`}
                  alt="x"
                  onClick={this.props.close}
                  className="close-oh-modal"
                />
              </div>
              <div className="opening-hours-table">
                <div className="table-row blue-Regular-14px">
                  <div className="table-cell">
                    {t("details:opening_hours_open")}
                  </div>
                  <div className="table-cell">
                    {t("details:opening_hours_day")}
                  </div>
                  <div className="table-cell">
                    {t("details:opening_hours_from")}
                  </div>
                  <div className="table-cell">
                    {t("details:opening_hours_to")}
                  </div>
                </div>
                {Object.keys(openingHours).map((day) => (
                  <div className="table-row" key={day}>
                    <div className="table-cell">
                      <Toggle
                        toggled={openingHours[day].open}
                        onClick={() => this.toggleOhDay(day)}
                      />
                    </div>
                    <div className="blue-SemiBold-15px table-cell">
                      {t(`general:opening_hours_${day.toLowerCase()}`)}
                    </div>
                    {openingHours[day].open && (
                      <Fragment>
                        <div className="table-cell">
                          <FadeIn>
                            <div className="input">
                              <IMaskInput
                                mask={"HH:mm"}
                                blocks={{
                                  HH: {
                                    mask: IMask.MaskedRange,
                                    from: 0,
                                    to: 23,
                                  },
                                  mm: {
                                    mask: IMask.MaskedRange,
                                    from: 0,
                                    to: 59,
                                  },
                                }}
                                name="from"
                                value={openingHours[day].from}
                                unmask={false}
                                onAccept={(value) =>
                                  this.handleOhInputChange(
                                    { value, name: "from" },
                                    day
                                  )
                                }
                                placeholder="07:00"
                              />
                            </div>
                          </FadeIn>
                        </div>
                        <div className="table-cell">
                          <FadeIn>
                            <div className="input">
                              <IMaskInput
                                mask={"HH:mm"}
                                blocks={{
                                  HH: {
                                    mask: IMask.MaskedRange,
                                    from: 0,
                                    to: 23,
                                  },
                                  mm: {
                                    mask: IMask.MaskedRange,
                                    from: 0,
                                    to: 59,
                                  },
                                }}
                                name="to"
                                value={openingHours[day].to}
                                unmask={false}
                                onAccept={(value) =>
                                  this.handleOhInputChange(
                                    { value, name: "to" },
                                    day
                                  )
                                }
                                placeholder="18:00"
                              />
                            </div>
                          </FadeIn>
                        </div>
                      </Fragment>
                    )}
                  </div>
                ))}
              </div>
              <div className="text-right actions-container">
                <button
                  type="submit"
                  form="opening-hours-form"
                  className={`btn btn-primary save-opening-hours ${isSaveDisabled}`}
                  ref={(node) => (this.saveButton = node)}
                >
                  {t("details:opening_hours_save")}
                </button>
              </div>
            </form>
          </div>
        </ScaleIn>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  opening_hours: state.register.opening_hours,
});

const mapDispatchToProps = (dispatch) => ({
  save: (opening_hours) =>
    dispatch(registerActions.saveOpeningHours(opening_hours)),
});

let reduxWrapped = connect(
  mapStateToProps,
  mapDispatchToProps
)(OpeningHoursModal);

export default withTranslation()(reduxWrapped);
