import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import { connect } from "react-redux";
import registerActions from "../../../actions/register";

import Input from "../../shared/forms/Input";
import TextArea from "../../shared/forms/TextArea";
import DropdownSearch from "./DropdownSearch";

import OpeningHoursModal from "./OpeningHoursModal";

import { countries } from "../../../constants/internationals";
import categories from "../../../constants/categories";

class ShopDetails extends Component {
  state = {
    countrySearch: "",
    categorySearch: "",
    focusedInput: "",
    oh_toggled: false,
  };

  onChange = ({ target }) => {
    let { name, value } = target;
    this.props.handleInputChange(name, value);
  };

  handleDropdownInputChange = (ev, input) =>
    this.setState({ [input]: ev.target.value });

  onBlur = (ev) => {
    // Clears current selection
    if (!ev.target.value)
      this.props.handleDropdownSelect(this.state.focusedInput, "");

    this.setState({ focusedInput: "" });
  };

  /**
   * On category/country input focus set value dynamically to the category/country name that was selected the last time
   * @param focusedInput
   * @returns {void}
   */
  focusInput = (focusedInput) => {
    let searchValue =
      focusedInput === "country"
        ? this.props.details.country
          ? countries.find((c) => c.code === this.props.details.country).name
          : ""
        : this.props.details.category
        ? this.props.t(
            `general:category_${
              categories.find((c) => c.id === this.props.details.category).name
            }`
          )
        : "";

    this.setState({ focusedInput, [`${focusedInput}Search`]: searchValue });
  };

  handleOptionSelect = (select, value, text) => {
    // The next line covers the case that the name of the selected option will
    // always be assigned as a value to the search text of the input.
    // Prevents the bug explained in the next line:
    // e.g: Seach "United Kingdom" > Press "enter" > UK is selected >
    // 			> Expand dropdown > Delete "Kingdom" > Press "enter" >
    // 			> UK is selected but the search value stays "United".
    this.setState({ [`${select}Search`]: text });
    this.props.handleDropdownSelect(select, value);
  };

  toggleOh = (oh_toggled) => this.setState({ oh_toggled });

  render = () => {
    let { details, t } = this.props;
    let countryValue =
      this.state.focusedInput === "country"
        ? this.state.countrySearch
        : details.country
        ? countries.find((c) => c.code === details.country).name
        : "";
    let categoryValue =
      this.state.focusedInput === "category"
        ? this.state.categorySearch
        : details.category
        ? t(
            `general:category_${
              categories.find((c) => c.id === details.category).name
            }`
          )
        : "";
    let isCountryFocused =
      this.state.focusedInput === "country" ? "focused" : "";
    let isCategoryFocused =
      this.state.focusedInput === "category" ? "focused" : "";

    return (
      <div className="shop-details-container">
        <h4 className="blue-Bold-20px-left">
          {t("details:shop_details_heading")}
        </h4>
        <div className="form-group">
          <Input
            name="name"
            required
            autocomplete="on"
            value={details.name}
            placeholder={t("details:shop_details_name")}
            errorMessage={t("details:shop_details_error_name")}
            onChange={this.onChange}
          />
        </div>
        <div className="form-group">
          <Input
            name="address"
            required
            autocomplete="on"
            value={details.address}
            placeholder={t("details:shop_details_address")}
            errorMessage={t("details:shop_details_error_address")}
            onChange={this.onChange}
          />
        </div>
        <div className="form-group">
          <div className="row">
            <div className="col-xl-6 col-12">
              <Input
                name="city"
                required
                autocomplete="on"
                value={details.city}
                placeholder={t("details:shop_details_city")}
                errorMessage={t("details:shop_details_error_city")}
                onChange={this.onChange}
              />
            </div>
            <div className="col-xl-6 col-12">
              <Input
                name="postcode"
                required
                autocomplete="on"
                value={details.postcode}
                placeholder={t("details:shop_details_postcode")}
                errorMessage={t("details:shop_details_error_postcode")}
                onChange={this.onChange}
              />
            </div>
          </div>
        </div>
        <div className="form-group">
          <DropdownSearch
            search={countryValue}
            selectedValue={details.country}
            className={`country-select ${isCountryFocused}`}
            name="country"
            placeholder={t("details:shop_details_country")}
            errorMessage={t("details:shop_details_error_country")}
            onFocus={() => this.focusInput("country")}
            onBlur={this.onBlur}
            collection={countries}
            handleOptionSelect={this.handleOptionSelect}
            handleInputChange={(ev) =>
              this.handleDropdownInputChange(ev, "countrySearch")
            }
          />
        </div>
        <div className="form-group">
          <div className="opening-hours" onClick={() => this.toggleOh(true)}>
            {t("details:opening_hours_heading")}{" "}
            <img
              src={`${process.env.PUBLIC_URL}/images/assets/add.svg`}
              alt="+"
            />
          </div>
        </div>
        <div className="form-group">
          <DropdownSearch
            search={categoryValue}
            selectedValue={details.category}
            name="category"
            className={`category-select ${isCategoryFocused}`}
            required
            placeholder={t("details:shop_details_category")}
            errorMessage={t("details:shop_details_error_category")}
            onFocus={() => this.focusInput("category")}
            onBlur={this.onBlur}
            collection={categories}
            handleOptionSelect={this.handleOptionSelect}
            handleInputChange={(ev) =>
              this.handleDropdownInputChange(ev, "categorySearch")
            }
          />
        </div>
        <div className="form-group">
          <TextArea
            name="description"
            placeholder={t("details:shop_details_description")}
            noValidation
            hasCounter
            value={details.description}
            onChange={this.onChange}
            maxLength={150}
          />
        </div>
        {this.state.oh_toggled && (
          <OpeningHoursModal close={() => this.toggleOh(false)} />
        )}
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  details: state.register.shop_details,
});

const mapDispatchToProps = (dispatch) => ({
  handleInputChange: (name, value) =>
    dispatch(registerActions.handleInputChange("shop_details", name, value)),
  handleDropdownSelect: (dropdown, value) =>
    dispatch(registerActions.handleDropdownSelect(dropdown, value)),
});

let reduxWrapped = connect(mapStateToProps, mapDispatchToProps)(ShopDetails);
export default withTranslation()(reduxWrapped);
