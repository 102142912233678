import {
  countries,
  INTERNATIONAL_ZONE_1,
  INTERNATIONAL_ZONE_2,
} from "../constants/internationals";

/**
 * Get a country's name by its code.
 * @param {string} country_code
 * @return {string}
 */
export const getCountryName = (country_code) => {
  const country = countries.find((c) => c.code === country_code);
  return country ? country.name : "";
};

export const isFromInternationalZone = (country_code) =>
  INTERNATIONAL_ZONE_1.includes(country_code) ||
  INTERNATIONAL_ZONE_2.includes(country_code);
