import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import routes from "../../../constants/routes";
import designsConfig from "../../../constants/designs";
import { navigate } from "../../shared/routes/CustomBrowserRouter";
import { config as bags_config } from "../../../constants/designs";
import layoutActions from "../../../actions/layout";
import {
  getBagsQuantities,
  validateReorderDetails,
} from "../../../helpers/ProcessHelpers";

import OrderTotals from "../../register/summary/OrderTotals";
import NeedHelp from "../../register/summary/NeedHelp";

export default ({ step }) => {
  const { t } = useTranslation("reorder");
  const dispatch = useDispatch();
  const {
    selected_plan,
    designs,
    shipping_address,
    billing_address,
    contact_info,
    editing_step,
    registered,
  } = useSelector((s) => s.reorder);

  const urlArea = window.location.pathname.match(/\/new.*/)
    ? "new_business"
    : "reorder";

  const { charge_bags, charge_cups, charge_bags_free, custom_large_bags, custom_medium_bags } = React.useMemo(
    () => getBagsQuantities(selected_plan, designs, designsConfig),
    [selected_plan, designs]
  );

  const detailsValid = React.useMemo(
    () =>
      validateReorderDetails({
        shipping_address,
        billing_address,
        contact_info,
      }),
    [shipping_address, billing_address, contact_info]
  );

  let isStepValid =
    step === "designs"
      ? charge_bags + charge_cups + charge_bags_free + custom_large_bags + custom_medium_bags >=
        bags_config.MIN_QUANTITY_REGULAR_REORDER
      : detailsValid;

  const onSubmit = () => {
    if (!isStepValid) {
      if (step === "designs")
        // If the minimum order quantity is not fulfilled show an error
        return dispatch(
          layoutActions.toggleErrorPopup("designs", true, {
            title: t("step_3_error_bags_title"),
            text: t("step_3_error_bags_text", {
              count: bags_config.MIN_QUANTITY_REGULAR_REORDER,
            }),
          })
        );

      return;
    }

    window.scrollTo({ top: 0, behavior: "smooth" });
    // Authenticated (already registered) shops skip the details step
    // Also since this summary is used by both designs and details steps
    // we check at which step we are currently when defining the next one.
    let nextStep =
      step === "designs"
        ? registered
          ? routes[urlArea].confirmation
          : routes[urlArea].details
        : "confirmation";

    if (step === editing_step) nextStep = routes[urlArea].confirmation;

    navigate(nextStep);
  };

  return (
    <div className="designs-summary summary-content">
      <div className={`summary-content-wrapper`}>
        <h4 className="blue-Bold-20px-left">
          {t(
            editing_step === "details"
              ? "step_1_editing"
              : "step_3_heading_mobile"
          )}
        </h4>
        {editing_step !== "details" && (
          <OrderTotals
            plan={selected_plan}
            quantities={designs}
            country={shipping_address.country_code}
            step="designs"
            procedure="reorder"
          />
        )}
        <div className="proceed">
          <button
            className={`btn btn-block btn-primary ${
              isStepValid ? "" : "disabled"
            }`}
            type="submit"
            form="addresses-form"
            onClick={onSubmit}
          >
            {["designs", "details"].includes(editing_step)
              ? t("save_changes")
              : t("next_step")}
          </button>
        </div>
        {editing_step === "details" && <NeedHelp />}
      </div>
    </div>
  );
};
