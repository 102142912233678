import React from "react";
import { Route } from "react-router-dom";
import routes from "../../../constants/routes";
import ConnectedIntlProvider from "../../../providers/ConnectedIntlProvider";
import { useSelector } from "react-redux";

import ChooseDesignsSummary from "./ChooseDesignsSummary";
import ConfirmationSummary from "./ConfirmationSummary";
import ShippingCalculator from "./ShippingCalculator";

import "../../../stylesheets/reorder/summary.scss";

export default () => {
  const { shipping_calculator: shippingCalculatorOpen } = useSelector((s) => s.layout.popups);

  return (
    <ConnectedIntlProvider>
      <div className="summary reorder">
        <div className="summary-container">
          {/*  /reorder/:action  */}
          <Route
            path={routes.reorder.designs}
            render={() => <ChooseDesignsSummary step="designs" />}
          />
          <Route
            path={routes.reorder.details}
            render={() => <ChooseDesignsSummary step="details" />}
          />
          <Route
            path={routes.reorder.confirmation}
            component={ConfirmationSummary}
          />
          {/*  /new/:action  */}
          <Route
            path={routes.new_business.designs}
            render={() => <ChooseDesignsSummary step="designs" />}
          />
          <Route
            path={routes.new_business.details}
            render={() => <ChooseDesignsSummary step="details" />}
          />
          <Route
            path={routes.new_business.confirmation}
            component={ConfirmationSummary}
          />
        </div>
      </div>
      {shippingCalculatorOpen && <ShippingCalculator />}
    </ConnectedIntlProvider>
  );
};
