import React from "react";
import { connect } from "react-redux";
import layoutActions from "../../../actions/layout";

import { withTranslation } from "react-i18next";
import terms_texts from "../texts/terms_texts";

import { ScaleIn } from "../../hocs/AnimationHOC";
import "../../../stylesheets/legal.scss";

const TermsAndConditionsPopup = ({ t, i18n, close }) => {
  let language = i18n.language.split("-")[0];

  return (
    <div className="legal terms-and-conditions popup">
      <ScaleIn className="content">
        <div className="close" onClick={close}>
          <img src="/images/assets/close.svg" alt="x" />
        </div>

        {terms_texts[language] || terms_texts.en}

        <div className="mt-5 d-flex justify-content-end">
          <div className="btn ml-auto close-legal-info" onClick={close}>
            {t("close")}
          </div>
        </div>
      </ScaleIn>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  close: () => dispatch(layoutActions.togglePopup("terms", false)),
});

const Reduxified = connect(null, mapDispatchToProps)(TermsAndConditionsPopup);

export default withTranslation("general")(Reduxified);
