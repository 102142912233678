import "./utilities/polyfills";
import CONFIG from "./config";
import React, { Suspense } from "react";
import CustomBrowserRouter, {
  navigate,
} from "./components/shared/routes/CustomBrowserRouter";
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from "./services/queries/QueryClient";

import { Provider } from "react-redux";
import store from "./config/store";
import HtmlLanguage from "./components/shared/HtmlLanguage";
import CookieConsent from "./components/legal/CookieConsent";
import { useTranslation } from "react-i18next";

export const Loader = () => (
  <div className="app-wrapper">
    <div className="page-loader">
      <i className="fas fa-spinner fa-pulse" />
    </div>
  </div>
);

const App = () => {
  const {
    i18n: { language },
  } = useTranslation();

  React.useEffect(() => {
    const pathLanguage = window.location.pathname.split("/")[1];
    // If the the path language is not supported or it differs from i18n's language redirect to the home page.
    if (!CONFIG.SUPPORTED_LANGUAGES.includes(pathLanguage) || pathLanguage !== language) {
      // Replace "/en" (for example) in the beginning of the current pathname with "/de" (the new language, for example)
      const newPath = window.location.pathname.replace(/^\/\w{2}/, `/${language}`);
      navigate(newPath, { hidePrompt: true });
    }
  });

  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<Loader />}>
        <Provider store={store}>
          <div className="app-wrapper">
            <HtmlLanguage />
            <CookieConsent />
            <CustomBrowserRouter />
          </div>
        </Provider>
      </Suspense>
    </QueryClientProvider>
  );
};

export default App;
