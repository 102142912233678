import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import { withTranslation } from "react-i18next";
import { ScaleIn } from "../../hocs/AnimationHOC";

import Input from "../../shared/forms/Input";
import TextArea from "../../shared/forms/TextArea";

import ContactService from "../../../services/business/ContactService";

class ContactFormDesigns extends Component {
  state = {
    company: this.props.company,
    contact: this.props.contact,
    email: this.props.email,
    notes: "",
    submitted: false,
    showForm: true,
    success: null,
  };

  wrapper = new React.createRef();
  submitButton = new React.createRef();

  componentDidMount = () => {
    document.addEventListener("keydown", this.handleKeyDown);
    document.addEventListener("mousedown", this.handleClickOutside);
  };

  componentWillUnmount = () => {
    document.removeEventListener("keydown", this.handleKeyDown);
    document.removeEventListener("mousedown", this.handleClickOutside);
  };

  handleKeyDown = async (ev) => {
    // Submit form on "Enter" key press
    if (ev.keyCode === 13) await this.submitButton.click();
    // Close modal on "Escape" key press
    else if (ev.keyCode === 27) this.props.close();
  };

  handleClickOutside = (event) =>
    this.wrapper && !this.wrapper.contains(event.target)
      ? this.props.close()
      : null;

  handleInputChange = ({ target }) =>
    this.setState({ [target.name]: target.value });

  handleSubmit = async (ev) => {
    ev.preventDefault();
    // Prevent double submission
    if (this.state.submitted) return;

    this.setState({ submitted: true });

    let { company, contact, email, notes } = this.state;
    let { success } = await ContactService.sendMessage({
      company_name: company,
      contact_name: contact,
      email,
      message: notes,
    });

    this.setState(
      { showForm: false }, // Trigger result animation
      () => this.setState({ success })
    );
  };

  reloadForm = () =>
    this.setState(
      { success: null }, // Trigger form animation
      () =>
        this.setState({
          submitted: false,
          showForm: true,
        })
    );

  renderForm = () => {
    let { t, close } = this.props;
    let submitDisabled = this.state.submitted ? "disabled" : "";

    return (
      <div className="contact-form-designs">
        <ScaleIn className="form-wrapper" ref={(node) => (this.wrapper = node)}>
          <form onSubmit={this.handleSubmit}>
            <div className="close-form" onClick={close}>
              <img
                src={`${process.env.PUBLIC_URL}/images/assets/close.svg`}
                alt="x"
              />
            </div>
            <div className="form-heading">
              <div className="heading blue-Bold-30px-center">
                {t("designs:form_heading")}
              </div>
              <div className="sub-heading blue-Regular-18px">
                {t("designs:form_text")}
              </div>
            </div>
            <div className="form-content">
              <div className="row">
                <div className="col-6">
                  <Input
                    name="company"
                    required
                    autocomplete
                    value={this.state.company}
                    onChange={this.handleInputChange}
                    errorMessage={t("designs:form_error_company")}
                    placeholder={t("designs:form_company")}
                  />
                  <Input
                    name="contact"
                    required
                    autocomplete
                    value={this.state.contact}
                    onChange={this.handleInputChange}
                    errorMessage={t("designs:form_error_contact")}
                    placeholder={t("designs:form_contact")}
                  />
                  <Input
                    name="email"
                    required
                    autocomplete
                    type="email"
                    pattern="[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?"
                    value={this.state.email}
                    onChange={this.handleInputChange}
                    errorMessage={t("designs:form_error_email")}
                    placeholder={t("designs:form_email")}
                  />
                </div>
                <div className="col-6">
                  <TextArea
                    name="notes"
                    value={this.state.notes}
                    required
                    minLength={5}
                    onChange={this.handleInputChange}
                    errorMessage={t("designs:form_error_notes")}
                    placeholder={t("designs:form_notes")}
                  />
                </div>
              </div>
            </div>
            <div className="form-action">
              <button
                type="submit"
                className={`btn btn-primary bulky submit-form ${submitDisabled}`}
                ref={(node) => (this.submitButton = node)}
              >
                {t("designs:form_send")}
              </button>
            </div>
          </form>
        </ScaleIn>
      </div>
    );
  };

  renderResult = () => {
    let { t, close } = this.props;
    let { success } = this.state;

    if (success === null) return null;

    return (
      <div className="contact-form-branches">
        <ScaleIn
          className="result-wrapper"
          ref={(node) => (this.wrapper = node)}
        >
          <div className="close-form" onClick={close}>
            <img src="/images/assets/close.svg" alt="x" />
          </div>
          <div className="result-content">
            <Fragment>
              <div className="image">
                {success ? (
                  <img src="/images/contact/success.png" alt="Success!" />
                ) : (
                  <img src="/images/contact/error.svg" alt="Oops!" />
                )}
              </div>
              <div className="heading blue-Bold-30px-center">
                {success
                  ? t("designs:form_success_heading")
                  : t("designs:form_failure_heading")}
              </div>
              <div
                className="text blue-Regular-18px"
                dangerouslySetInnerHTML={{
                  __html: success
                    ? t("designs:form_success_text")
                    : t("designs:form_failure_text"),
                }}
              />
              <div
                className="btn btn-primary bulky"
                onClick={success ? close : this.reloadForm}
              >
                {success
                  ? t("designs:form_dismiss")
                  : t("designs:form_try_again")}
              </div>
            </Fragment>
          </div>
        </ScaleIn>
      </div>
    );
  };

  render = () =>
    this.state.showForm ? this.renderForm() : this.renderResult();
}

const mapStateToProps = (state) => ({
  company: state.register.contact_info.company,
  contact: state.register.contact_info.contact,
  email: state.register.contact_info.email,
});

let Reduxified = connect(mapStateToProps)(ContactFormDesigns);

export default withTranslation()(Reduxified);
