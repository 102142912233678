import reduxStore from "../../config/store";
import ContactAPIService from "../network/ContactAPIService";
import { getI18n } from "react-i18next";

class ContactService {
  /**
   * Submits a contact form message to the ContactAPIService.
   * This method prepares the body for the request to the backend,
   * gathering the message and additional info.
   * @param params {{
   *   company_name: string,
   *   contact_name: string,
   *   email: string,
   *   [number_of_branches]: number,
   *   message: string|null
   * }}
   * @return {Promise<{success: boolean}>}
   */
  static async sendMessage(params) {
    const { shop_details, coupon_options } = reduxStore.getState().register;

    const body = {
      ...params,
      number_of_branches: params.number_of_branches
        ? params.number_of_branches
        : 0,

      lang: getI18n().language.split("-")[0],
      place_id: shop_details.place_id,
      shop_name: shop_details.name,

      offers_project_tree: coupon_options.offers_project_tree,
      offers_project_ocean: coupon_options.offers_project_ocean,
      offers_discount: coupon_options.offers_discount,
      discount: coupon_options.custom_text || coupon_options.discount_text,
      country_code: shop_details.country,
    };

    return await ContactAPIService.sendMessage(body);
  }
}

export default ContactService;
