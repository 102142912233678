export default [
  {
    id: 1,
    name: "other",
    types: [],
  },
  {
    id: 2,
    name: "food_and_drink",
    types: [
      "bakery",
      "bar",
      "restaurant",
      "meal_delivery",
      "meal_takeaway",
      "liquor_store",
    ],
  },
  {
    id: 3,
    name: "groceries",
    types: ["grocery_or_supermarket", "supermarket", "convenience_store"],
  },
  {
    id: 4,
    name: "fashion_and_design",
    types: ["clothing_store", "jewelry_store", "shoe_store"],
  },
  {
    id: 5,
    name: "kids",
    types: [
      "amusement_park",
      "primary_school",
      "secondary_school",
      "park",
      "school",
    ],
  },
  {
    id: 6,
    name: "home_and_garden",
    types: [
      "electronics_store",
      "florist",
      "furniture_store",
      "hardware_store",
      "home_goods_store",
      "pet_store",
    ],
  },
  {
    id: 7,
    name: "health_and_wellness",
    types: [
      "beauty_salon",
      "dentist",
      "doctor",
      "drugstore",
      "gym",
      "hair_care",
      "hospital",
      "veterinary_care",
      "spa",
    ],
  },
  {
    id: 8,
    name: "Coffee Shop",
    icon: "coffee_shop",
    types: ["cafe", "food", "point_of_interest"],
  },
];
