export default {
  TOGGLE_PREVIEW_PANEL: "TOGGLE_PREVIEW_PANEL",
  TOGGLE_SHOP_DETAILS_PANEL: "TOGGLE_SHOP_DETAILS_PANEL",
  TOGGLE_CONSENT_CHECKBOX: "TOGGLE_CONSENT_CHECKBOX",
  TOGGLE_ERROR_POPUP: "TOGGLE_ERROR_POPUP",
  TOGGLE_LANG_SWITCH: 'TOGGLE_LANG_SWITCH',
  TOGGLE_POPUP: "TOGGLE_POPUP",
  RESET_LAYOUT: "RESET_LAYOUT",
  HIDE_ERROR_POPUPS: "HIDE_ERROR_POPUPS",
  UPDATE_DIMENSION: "UPDATE_DIMENSION",
};
