import "../utilities/extensions";
import categories from "../constants/categories";

const getAddressComponent = (components, name, variant = "long_name") => {
  let isEmpty = components && components.length === 0;

  if (!isEmpty) {
    let hasComponent = components.find((comp) => comp.types.indexOf(name) > -1);
    if (hasComponent)
      return components.find((comp) => comp.types.indexOf(name) > -1)[variant];

    return "";
  }
  return "";
};

const days_of_week = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

class ShopsHelpers {
  /**
   * Extract human-readable version of the address components that the Google PlacesAPI retrieves.
   * Covers the case when the street name is split into 4-5 components and needs to be assembled into one simple string.
   * Note: Sublocality components order might not always be the accurate.
   * @param address_components
   * @returns {{
   * 	country: {String},
   * 	city: {String},
   * 	postcode: {String},
   * 	phone: {String},
   * 	website: {String},
   * 	street: {(String|undefined)},
   * 	address: {(String|undefined)}
   * }}
   */
  static extractAddress = ({ address_components, ...details }) => {
    let hasComponents = address_components && address_components.length > 0;

    let address = {
      postcode: getAddressComponent(address_components, "postal_code"),
      city:
        getAddressComponent(address_components, "locality") ||
        getAddressComponent(
          address_components,
          "administrative_area_level_1"
        ),
      country: getAddressComponent(address_components, "country", "short_name"),
      phone: details.international_phone_number,
      website: details.website,
    };

    let route = getAddressComponent(address_components, "route");

    if (!hasComponents) {
      address.street = "";
    } else if (route) {
      let street_number = getAddressComponent(
        address_components,
        "street_number"
      );
      address.address = `${route}${street_number ? " " + street_number : ""}`;
    } else {
      let street_parts = address_components.filter(
        (comp) => comp.types.indexOf("sublocality") > -1
      );

      let sublocality_level_1 = getAddressComponent(
        street_parts,
        "sublocality_level_1"
      );
      let sublocality_level_2 = getAddressComponent(
        street_parts,
        "sublocality_level_2"
      );
      let sublocality_level_3 = getAddressComponent(
        street_parts,
        "sublocality_level_3"
      );
      let sublocality_level_4 = getAddressComponent(
        street_parts,
        "sublocality_level_4"
      );
      let premise = getAddressComponent(address_components, "premise");

      sublocality_level_2 = sublocality_level_2
        ? `${sublocality_level_2}${sublocality_level_1 ? "-" : ""}`
        : "";
      sublocality_level_3 = sublocality_level_3
        ? `${sublocality_level_3}-`
        : "";
      sublocality_level_4 = sublocality_level_4
        ? `${sublocality_level_4}-`
        : "";
      premise = premise ? premise : "";

      address.address =
        `${sublocality_level_3}${sublocality_level_4}${premise} ${sublocality_level_2}${sublocality_level_1}`;

      if (!address.address.trim().length) address.address = details.formatted_address.split(",")[0];
    }

    return address;
  };

  /**
   * Get a shop's category by its types.
   * @param {String[]} types - The shop types returned by the Google Places API
   * @returns {number} - The id of the category that fits the types passed.
   */
  static getShopCategory = (types) => {
    let shop_type_id = 1;
    for (let i = 0; i < types.length; i++) {
      let category = categories.find((c) => c.types.indexOf(types[i]) > -1);
      if (category) {
        shop_type_id = category.id;
        break;
      }
    }
    return shop_type_id;
  };

  /**
   * Parse the details from the Google Places API result to a readable object.
   * @param details
   * @returns {{
   * 	country: {String},
   * 	city: {String},
   * 	postcode: {String},
   * 	street: {(String|undefined)},
   * 	address: {(String|undefined)}
   * 	geo_lat: {number},
   * 	geo_lng: {number}
   * 	name: {String},
   * 	place_id: {string},
   * 	opening_hours: {Object},
   * 	category: {number}
   * }}
   */
  static parseGooglePlaceDetails = (details) => {
    let address_components = ShopsHelpers.extractAddress(details);

    let coordinates = {
      geo_lat: details.geometry.location.lat(),
      geo_lng: details.geometry.location.lng(),
    };

    return {
      ...address_components,
      ...coordinates,
      name: details.name,
      place_id: details.place_id,
      category: this.getShopCategory(details.types),
      opening_hours: this.parseOpeningHours(details.opening_hours),
    };
  };

  /**
   * Parse the opening hours object, returned by the Google Places API
   * to an easily readable JS object.
   * @param opening_hours
   * @returns {Object} - If opening hours are not null, undefined or false,
   * parse them. If they are, return a default empty opening hours object.
   */
  static parseOpeningHours = (opening_hours) => {
    if (!opening_hours) return this.initializeOpeningHours();
    else {
      let parsed_result = {};

      Object.values(opening_hours.periods).forEach((entry) => {
        if (entry.open && entry.close) {
          let day_name = days_of_week[entry.open.day];

          parsed_result[day_name] = {
            from: entry.open.time.insert(2, ":"),
            to: entry.close.time.insert(2, ":"),
            open: true,
          };
        }
      });

      return parsed_result;
    }
  };

  static initializeOpeningHours = () => {
    let opening_hours = {};

    for (let i in days_of_week) {
      opening_hours[days_of_week[i]] = {
        from: "",
        to: "",
      };
    }

    return opening_hours;
  };
}

export default ShopsHelpers;
