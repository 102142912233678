import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { navigate } from "../shared/routes/CustomBrowserRouter";

import {
  getBagsQuantities,
  validateReorderDetails,
} from "../../helpers/ProcessHelpers";

import routes from "../../constants/routes";
import { config as bags_config } from "../../constants/designs";
import designsConfig from "../../constants/designs";
import { REORDER_STEPS as steps } from "../../constants/steps";

import "../../stylesheets/steps_navigation.scss";

export default ({ current }) => {
  const { t } = useTranslation("general");
  const {
    editing_step,
    designs,
    token: registered,
    shipping_address,
    contact_info,
    billing_address,
  } = useSelector((s) => s.reorder);

  const renderLink = (step, completedCondition) => {
    const status =
      editing_step === step && current === step && completedCondition
        ? "editing"
        : current === step
        ? "current"
        : completedCondition
        ? "completed"
        : "";

    return (
      <span
        className={`navigation-step orange-Bold-16px-left ${step} ${status}`}
        onClick={() => redirectToStep(step)}
      >
        {t(
          `navigation_reorder_${steps.indexOf(step) + 1}_step${
            step === "details" && registered ? "_registered" : ""
          }`
        )}
      </span>
    );
  };

  const redirectToStep = (step) => {
    // If the URL starts with "/new" redirect to "/new/:action" urls.
    // Otherwise redirect to "/reorder/:action" urls.
    const urlArea = window.location.pathname.match(/\/new.*/) ? 'new_business' : 'reorder';
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate(`${routes[urlArea].start}/${step}`);
  };

  const { charge_bags, charge_cups, custom_bags, charge_bags_free } = React.useMemo(
    () => getBagsQuantities("ondemand", designs, designsConfig),
    [designs]
  );

  const designsStepCompleted =
    charge_bags + charge_cups + custom_bags + charge_bags_free >=
    bags_config.MIN_QUANTITY_REGULAR_REORDER;

  const detailsValid = React.useMemo(
    () =>
      validateReorderDetails({
        shipping_address,
        billing_address,
        contact_info,
      }),
    [shipping_address, billing_address, contact_info]
  );

  return (
    <div className="steps-navigation">
      <div className="my-container">
        <div
          className={`steps-navigation-container ${
            registered ? "registered" : ""
          }`}
        >
          {registered && editing_step === "details"
            ? null
            : renderLink("designs", designsStepCompleted)}
          {/* Render Contact info & Shipping address step only if the user is not authenticated. */}
          {(registered && editing_step === "details") || !registered
            ? renderLink("details", detailsValid)
            : null}
          {registered && editing_step === "details"
            ? null
            : renderLink("confirmation", false)}
        </div>
      </div>
    </div>
  );
};
