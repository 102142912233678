export default {
  ondemand: {
    display_name: "On-Demand",
    free_bags: 0,
    price: 0,
    display_price: 0,
    bag_price: 4.90,
    cup_price: 15,
    promotional: false,
  },
  standard: {
    display_name: "Standard",
    free_bags: 0,
    price: 49,
    display_price: 49,
    bag_price: 4.75,
    cup_price: 14.5,
    stand_price: 39,
    promotional: false,
  },
  standard_free: {
    display_name: "Standard",
    free_bags: 0,
    price: 0,
    display_price: 49,
    bag_price: 4.75,
    cup_price: 14.5,
    stand_price: 39,
    promotional: true,
  },
  premium: {
    display_name: "Premium",
    free_bags: 0,
    price: 149,
    display_price: 149,
    old_price: 200,
    bag_price: 4.50,
    cup_price: 14.00,
    promotional: false,
  },
};
