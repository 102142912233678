const BASE_URL = process.env.REACT_APP_BASE_URL;

export const GET_COUNTRY_URL = `${BASE_URL}/partner/v1/country_code`;

export const BUSINESS_DETAILS_URL = `${BASE_URL}/partner/v1/me`;
export const BGOOD_PRODUCTS_URL = `${BASE_URL}/public/v1/bgood_products`;

// used by the contact forms.
export const SEND_MESSAGE_URL = `${BASE_URL}/partner/v1/contact`;

// Endpoint to submit a register order with bags and company/shop details after agreeing to T&C.
export const SUBMIT_REGISTER_URL = `${BASE_URL}/partner/v1/orders`;
export const SUBMIT_REORDER_URL = `${BASE_URL}/partner/v1/reorder`;

// Endpoints for progress to gather leads.
export const PLACE_LEAD_URL = `${BASE_URL}/partner/v1/progress/place`;
export const CUSTOMER_LEAD_URL = `${BASE_URL}/partner/v1/progress/customer`;
export const SHOP_LEAD_URL = `${BASE_URL}/partner/v1/progress/shop`;
