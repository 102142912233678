import React, { Component } from "react";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { injectIntl } from "react-intl";

import Dropdown from "../../shared/Dropdown";

import plans from "../../../constants/plans";
import designs from "../../../constants/designs";
import { currency_rates } from "../../../constants/internationals";

import {
  getBagPrice,
  getBagsQuantities,
} from "../../../helpers/ProcessHelpers";

class PotentialEarnings extends Component {
  state = {
    bag_price: 9.9,
  };

  setBagPrice = (bag_price) => this.setState({ bag_price });

  render = () => {
    let { t, intl, quantities, plan, country } = this.props;
    if (!plan) return null;

    const round = (number, decimals = 2) =>
      Number(Math.round(parseFloat(number + "e" + decimals)) + "e-" + decimals);
    const formatPrice = (price, decimals) =>
      intl.formatNumber(price, {
        style: "currency",
        currency: currency.code,
        currencyDisplay: "symbol",
        maximumFractionDigits: decimals,
        minimumFractionDigits: decimals,
      });

    let currency = currency_rates[country] || currency_rates.default;
    let bag_price = round(this.state.bag_price * currency.rate, 2);

    let { charge_bags, custom_large_bags, custom_medium_bags, charge_bags_free } = getBagsQuantities(
      plan,
      quantities,
      designs,
    );

    let chargeBagsCost =
      charge_bags * round(plans[plan].bag_price * currency.rate, 2);
    let customLargeBagsCost =
      custom_large_bags *
      round(getBagPrice("custom_large", custom_large_bags) * currency.rate, 2);
    let customMediumBagsCost =
      custom_medium_bags *
      round(getBagPrice("custom_medium", custom_medium_bags) * currency.rate, 2);

    let custom_large_bags_profit = custom_large_bags * bag_price - customLargeBagsCost;
    let custom_medium_bags_profit = custom_medium_bags * bag_price - customMediumBagsCost;
    let charge_bags_profit = charge_bags * bag_price - chargeBagsCost;
    let charge_bags_free_profit = charge_bags_free * bag_price;

    let total_earnings =
      (custom_large_bags + custom_medium_bags + charge_bags + charge_bags_free) * bag_price;
    let total_profit =
      custom_large_bags_profit + custom_medium_bags_profit + charge_bags_profit + charge_bags_free_profit;

    let totalEarningsStr = formatPrice(total_earnings, 2);
    let totalProfitStr = formatPrice(total_profit, 2);

    return (
      <div className="potential-earnings">
        <div className="potential-earnings-container">
          <div className="heading blue-Bold-18px text-left">
            {t("summary:step_3_potential_earnings_heading")}
          </div>
          <Dropdown
            placeholder={`${formatPrice(bag_price, 2)} ${t(
              "summary:step_3_potential_earnings_per_bag"
            )}`}
          >
            {[9.9, 4.9, 14.9].map((price) => (
              <div
                className="option"
                onClick={() => this.setBagPrice(price)}
                key={price}
              >
                {formatPrice(round(price * currency.rate, 2), 2)}{" "}
                {t("summary:step_3_potential_earnings_per_bag")}
              </div>
            ))}
          </Dropdown>
          <div className="earnings">
            <div className="d-flex justify-content-between">
              <span className="blue-Regular-14px">
                {t("summary:step_3_total_earnings")}
              </span>
              <span className="blue-Bold-14px earnings-value">
                {total_earnings === 0
                  ? totalEarningsStr.replace("00", "-")
                  : totalEarningsStr}
              </span>
            </div>
            <div className="d-flex justify-content-between profit">
              <span className="green-Regular-14px">
                {t("summary:step_3_your_profit")}
              </span>
              <span className="green-Bold-14px profit-value">
                {total_profit === 0
                  ? totalProfitStr.replace("00", "-")
                  : totalProfitStr}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  quantities: state.register.designs,
  plan: state.register.plan,
  country: state.register.shop_details.country,
});

const Reduxified = connect(mapStateToProps, null)(PotentialEarnings);
const WithIntl = injectIntl(Reduxified);

export default withTranslation()(WithIntl);
