import actionTypes from "../constants/action_types/reorder";

export default {
  saveCustomBag: (details) => ({
    type: actionTypes.SAVE_CUSTOM_BAG,
    details,
  }),
  saveBusinessDetails: (details) => ({
    type: actionTypes.SAVE_SHOP_DETAILS,
    details,
  }),
  saveBusinessGoogleDetails: (details) => ({
    type: actionTypes.SAVE_BUSINESS_GOOGLE_DETAILS,
    details,
  }),
  setPlan: (plan) => ({
    type: actionTypes.SET_PLAN,
    plan,
  }),
  setFirstOrder: () => ({ type: actionTypes.SET_FIRST_ORDER }),
  setPreselectedPlan: (plan) => ({
    type: actionTypes.SET_PRESELECTED_PLAN,
    plan,
  }),
  setEditingStep: (step) => ({
    type: actionTypes.SET_EDITING_STEP,
    step,
  }),
  handleInputChange: (panel, field, value) => ({
    type: actionTypes.HANDLE_INPUT_CHANGE,
    panel,
    field,
    value,
  }),
  handleQuantityChange: (size, design, quantity) => ({
    type: actionTypes.HANDLE_QUANTITY_CHANGE,
    size,
    design,
    quantity,
  }),
  handleCountrySelect: (panel, country) => ({
    type: actionTypes.HANDLE_COUNTRY_SELECT,
    panel,
    country,
  }),
  toggleSameAddresses: () => ({
    type: actionTypes.TOGGLE_SAME_ADDRESS,
  }),
  resetReorder: () => ({
    type: actionTypes.RESET_REORDER,
  }),
};
