import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import UploadDesign from "./UploadDesign";
import Input from "../../shared/forms/Input";

class DesignOption extends Component {
  constructor(props) {
    super(props);

    this.state = {
      height: 130,
      value: this.props.quantity,
    };
  }

  logoContainer = new React.createRef();
  inputRef = new React.createRef();

  componentDidMount = () => {
    this.adjustHeight();
    window.addEventListener("resize", this.adjustHeight);
  };

  componentWillUnmount = () =>
    window.removeEventListener("resize", this.adjustHeight);

  componentDidUpdate() {
    if (this.state.value !== this.props.quantity) {
      this.setState({ value: this.props.quantity });
    }
  }

  handleChange = (ev) => {
    let { value } = ev.target;
    this.setState({ value });
    this.props.setQuantity(value);
  };

  // On input blur, if the value is:
  // - greater than 1000
  // - less than the minimum
  // - not a number
  // empty the input ( give the user a second chance to get it right )
  onBlur = () =>
    (this.props.quantity > 1000 ||
      this.props.quantity < this.props.min ||
      (isNaN(this.props.quantity) && this.props.quantity !== undefined)) &&
    this.props.setQuantity("");

  handleInput = (ev) => {
    let { value } = ev.target;
    value = value.replace(/\D|-/g, ""); // Remove all non-digit values
    value = Math.abs(parseInt(value)) || ""; // Handle negative values
    value = value < 0 || isNaN(value) ? "" : value; // Handle NaN values
    value = value > 1000 ? this.state.value : value; // If the new value is greater than 1000, keep the old value

    const setValue = Object.getOwnPropertyDescriptor(
      this.inputRef.__proto__,
      "value"
    ).set;
    // Change physical input's value
    setValue.call(this.inputRef, value);
  };

  /**
   * Adjust the height of the logo container in the custom bag design option
   */
  adjustHeight = () =>
    this.props.custom_logo &&
    this.setState({ height: this.logoContainer.clientWidth });

  focusInput = () => this.inputRef.focus();

  render = () => {
    let {
      t,
      i18n,
      image,
      name,
      color,
      price,
      placeholder,
      custom_logo,
      showDetails,
      min,
      item,
    } = this.props;
    let isCustom = custom_logo ? "custom-design" : "";
    const productType = item?.product_type === 'goodcup' ? 'goodcup' : 'goodbag';

    return (
      <div className={`bag-design-option ${isCustom}`}>
        <div className="option-wrapper">
          <div
            className="image"
            onClick={() => (showDetails ? showDetails() : null)}
          >
            <img src={image} alt={name} />
            {custom_logo && this.state.value >= 100 && (
              <div
                className="logo-container"
                ref={(node) => (this.logoContainer = node)}
                style={{
                  width: "61%",
                  height: `${this.state.height}px`,
                }}
              >
                {custom_logo.logo.url && (
                  <div
                    className="image-wrapper"
                    style={{
                      width: `${
                        (custom_logo.size.width / custom_logo.parent.width) *
                        100
                      }%`,
                      height: `${
                        (custom_logo.size.height / custom_logo.parent.height) *
                        100
                      }%`,
                      marginLeft: `${
                        (custom_logo.position.x / custom_logo.parent.width) *
                        100
                      }%`,
                      marginTop: `${
                        (custom_logo.position.y / custom_logo.parent.height) *
                        100
                      }%`,
                    }}
                  >
                    <img src={custom_logo.logo.url} alt="Logo" />
                  </div>
                )}
              </div>
            )}
          </div>
          <div
            className="color"
            onClick={() => (showDetails ? showDetails() : null)}
          >
            {color}
          </div>
          <div
            className="name blue-Regular-20px-center"
            onClick={() => (showDetails ? showDetails() : null)}
          >
            {name}
          </div>
          <div className="price blue-Bold-20px-center">{price}{isCustom && "*"}</div>
          <div
            className={`quantity ${i18n.language.split("-")[0]}`}
            onClick={this.focusInput}
          >
            <div className="quantity-input">
              <Input
                value={this.state.value}
                type="number"
                min={min}
                placeholder={placeholder}
                onChange={this.handleChange}
                onInput={this.handleInput}
                pattern={"[0-9]*"}
                onBlur={this.onBlur}
                setRef={(node) => (this.inputRef = node)}
              />
              <span className="blue-Regular-15px">
                {productType === 'goodbag' ? t("bag_option_input_label") : t("cup_option_input_label")}
              </span>
            </div>
          </div>
        </div>
        {custom_logo && this.state.value >= 100 && (
          <UploadDesign
            saveLogo={this.props.saveLogo}
            logo={this.props.custom_logo.logo}
            size={this.props.custom_logo.size}
            position={this.props.custom_logo.position}
            parent={this.props.custom_logo.parent}
          />
        )}
      </div>
    );
  };
}

export default withTranslation("reorder")(DesignOption);
