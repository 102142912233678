import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  adjustProcessNavigationScroll,
  goToFirstIncompleteStep,
} from "../../../helpers/ProcessHelpers";
import reorderActions from "../../../actions/reorder";
import layoutActions from "../../../actions/layout";

import { Loader } from "../../../App";
import Checkbox from "../../shared/forms/Checkbox";
import ContactInfo from "../../register/details/ContactInfo";
import InfoMessage from "../../shared/InfoMessage";
import AddressPanel from "./AddressPanel";

import "../../../stylesheets/reorder/details.scss";

export default () => {
  const [shouldRender, setShouldRender] = React.useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation("reorder");
  const {
    shipping_address,
    billing_address,
    registered,
    contact_info,
  } = useSelector((s) => s.reorder);
  const { details: displayError } = useSelector((s) => s.layout.error_popups);

  const urlArea = window.location.pathname.match(/\/new.*/) ? 'new_business' : 'reorder';

  React.useEffect(() => {
    setShouldRender(!goToFirstIncompleteStep(urlArea));
    adjustProcessNavigationScroll("details");
  }, [urlArea]);

  const handleSameAsShipping = () => {
    dispatch(reorderActions.toggleSameAddresses());
  };

  const handleInputChange = (panel, name, value) =>
    dispatch(reorderActions.handleInputChange(panel, name, value));

  return !shouldRender ? (
    <Loader />
  ) : (
    <div className="details-step">
      <div className="my-container">
        <form
          className="addresses-form"
          id="addresses-form"
          onSubmit={(ev) => ev.preventDefault()}
        >
          <div className="row">
            {!registered && (
              <div className="column contact-info">
                <ContactInfo
                  inputLabels={true}
                  vatRequired={true}
                  phoneRequired={true}
                  details={contact_info}
                  handleInputChange={(name, value) =>
                    handleInputChange("contact_info", name, value)
                  }
                />
              </div>
            )}
            <div className="column shipping-address">
              <h4 className="blue-Bold-20px-left">
                {t("shipping_address_heading")}
              </h4>
              <AddressPanel
                required={true}
                name="shipping_address"
                details={shipping_address}
              />
            </div>
            <div className="column billing-address">
              <h4 className="blue-Bold-20px-left">
                {t("billing_address_heading")}
              </h4>
              <AddressPanel
                required={!billing_address.same_as_shipping}
                name="billing_address"
                details={billing_address}
                className={!billing_address.same_as_shipping ? "toggled" : ""}
              />
              <Checkbox
                onClick={handleSameAsShipping}
                initialValue={billing_address.same_as_shipping}
              >
                {t("same_as_shipping")}
              </Checkbox>
            </div>
          </div>
        </form>
      </div>
      {displayError && (
        <InfoMessage title={displayError.title} text={displayError.text}>
          <div
            className="btn btn-primary bulky dismiss-validation-message"
            onClick={() =>
              dispatch(layoutActions.toggleErrorPopup("details", false))
            }
          >
            {t("dismiss_error")}
          </div>
        </InfoMessage>
      )}
    </div>
  );
};
