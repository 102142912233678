import React from "react";
import { useTranslation } from "react-i18next";
import RedirectWithQuery from "./RedirectWithQuery";
import { Switch, Route, Redirect } from "react-router-dom";

import plans from "../../../constants/plans";
import routes from "../../../constants/routes";

import LandingPage from "../../landing/LandingPage";
import RegisterPage from "../../register/RegisterPage";
import ReorderPage from "../../reorder/ReorderPage";
import OrderPlacedScreen from "../../register/completed/OrderPlacedScreen";
import NonExistingRoute from "./NonExistingRoute";
import TermsAndConditions from "../../legal/TermsAndConditions";
import PrivacyPolicy from "../../legal/PrivacyPolicy";

export default () => {
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <Switch>
      {/* Browser language detection will happen only for the first 3 routes. */}
      <Redirect from="/" exact to={`/${language}`} />
      {/* Redirect /new/:plan(ondemand|standard|standard_free|premium|...)/:google_place_id to /:lang/new/:action/:google_place_id where :lang is replaced with the site language, :action with the pre-selected  */}
      {/* plan name and :google_place_id with the Google Place Id from the route match. */}
      <RedirectWithQuery
        from={`/new/:plan(${Object.keys(plans).join("|")})/:google_place_id(\\S{20,})`}
        to={({ params }) =>
          routes.new_business.index
            .replace(":action?", params.plan)
            .replace(":google_place_id?", params.google_place_id)
        }
      />
      {/* Redirect from /new/:action to /:language/new/:action, :language and :action in the new path will be replaced with the site language and the action parameter value from the match. */}
      <RedirectWithQuery
        from="/new/:action?"
        to={({ params }) =>
          routes.new_business.index.replace(
            ":action?",
            params.action === "undefined" ? "" : params.action
          )
        }
      />
      {/* Redirect from /new/:action to /:language/new/:action, :language and :action in the new path will be replaced with the site language and the action parameter value from the match. */}
      <RedirectWithQuery
        from="/reorder/:action?"
        to={({ params }) =>
          routes.reorder.index.replace(
            ":action?",
            params.action === "undefined" ? "" : params.action
          )
        }
      />
      <Route path="/:lang(\w{2})" exact component={LandingPage} />
      <Route path={routes.register.completed} component={OrderPlacedScreen} />
      <Route path={routes.reorder.completed} component={OrderPlacedScreen} />
      <Route
        path={routes.new_business.completed}
        component={OrderPlacedScreen}
      />
      <Route path={routes.register.index} component={RegisterPage} />
      <Route path={routes.reorder.index} component={ReorderPage} />
      <Route path={routes.new_business.index} component={ReorderPage} />
      <Redirect from="/terms" to={`/${language}/terms`} />
      <Redirect from="/privacy" to={`/${language}/privacy`} />
      <Route path={routes.terms} component={TermsAndConditions} />
      <Route path={routes.privacy} component={PrivacyPolicy} />
      <Route path="*" component={NonExistingRoute} />
    </Switch>
  );
};
