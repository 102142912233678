import React, { Component } from "react";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import layoutActions from "../../../actions/layout";

import ContactUs from "../ContactUs";
import Plans from "./Plans";
import CompanyStores from "./CompanyStores";
import ChoosePlanSummary from "../summary/ChoosePlanSummary";

import "../../../stylesheets/register/plan.scss";

import {
  adjustProcessNavigationScroll,
  goToFirstIncompleteStep,
} from "../../../helpers/ProcessHelpers";
import ContactFormBranches from "./ContactFormBranches";
import InfoMessage from "../../shared/InfoMessage";

class ChoosePlanStep extends Component {
  state = {
    showContactForm: false,
  };

  componentDidMount = () => {
    goToFirstIncompleteStep("register");
    adjustProcessNavigationScroll("plan");
  };

  toggleForm = (toggled) => this.setState({ showContactForm: toggled });

  render = () => {
    let { t } = this.props;

    return (
      <div className="choose-plan-step">
        <div className="my-container">
          <Plans />
        </div>
        <div className="mobile-summary">
          <ChoosePlanSummary />
        </div>
        <ContactUs
          heading={t("plan:form_heading")}
          text={t("plan:form_text")}
          action={t("plan:form_action")}
          onClick={() => this.toggleForm(true)}
        />
        <CompanyStores headingStyle="blue-ExtraBold-40px-center" />
        {this.state.showContactForm && (
          <ContactFormBranches close={() => this.toggleForm(false)} />
        )}
        {this.props.displayError && (
          <InfoMessage
            title={t("summary:step_2_error_title")}
            text={t("summary:step_2_error_text")}
          >
            <div
              className="btn btn-primary bulky dismiss-validation-message"
              onClick={() => this.props.toggleError(false)}
            >
              {t("summary:dismiss_error")}
            </div>
          </InfoMessage>
        )}
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  displayError: state.layout.error_popups.plan,
});

const mapDispatchToProps = (dispatch) => ({
  toggleError: (toggled) =>
    dispatch(layoutActions.toggleErrorPopup("plan", toggled)),
});

let Reduxified = connect(mapStateToProps, mapDispatchToProps)(ChoosePlanStep);

export default withTranslation()(Reduxified);
