import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import Input from "../../shared/forms/Input";

class ContactInfo extends Component {
  onChange = (ev) => {
    let { name, value } = ev.target;
    this.props.handleInputChange(name, value);
  };

  render = () => {
    let { details, inputLabels, t } = this.props;

    return (
      <div className="contact-info-container">
        <h4 className="blue-Bold-20px-left">{t("contact_heading")}</h4>
        <div className="form-group">
          <Input
            withLabel={inputLabels}
            onChange={this.onChange}
            name="company"
            minLength={2}
            autocomplete="organization"
            required
            value={details.company}
            errorMessage={t("contact_error_company")}
            placeholder={t("contact_company")}
          />
        </div>
        <div className="form-group">
          <Input
            withLabel={inputLabels}
            onChange={this.onChange}
            minLength={2}
            name="contact"
            autocomplete="organization name"
            required
            value={details.contact}
            errorMessage={t("contact_error_name")}
            placeholder={t("contact_name")}
          />
        </div>
        <div className="form-group">
          <Input
            withLabel={inputLabels}
            onChange={this.onChange}
            name="email"
            type="email"
            autocomplete="organization email"
            required
            pattern="[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?"
            value={details.email}
            errorMessage={t("contact_error_email")}
            placeholder={t("contact_email")}
          />
        </div>
        <div className="form-group">
          <Input
            withLabel={inputLabels}
            onChange={this.onChange}
            name="phone"
            type="tel"
            minLength={5}
            required={!!this.props.phoneRequired}
            autocomplete="organization tel"
            value={details.phone}
            errorMessage={t("contact_error_phone", { minLength: 5 })}
            placeholder={t("contact_phone")}
          />
        </div>
        <div className="form-group">
          <Input
            withLabel={inputLabels}
            onChange={this.onChange}
            name="vat_number"
            autocomplete="true"
            required={!!this.props.vatRequired}
            value={details.vat_number}
            errorMessage={t("contact_error_vat")}
            placeholder={t("contact_vat")}
          />
        </div>
      </div>
    );
  };
}

export default withTranslation("reorder")(ContactInfo);
