import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import BrowserLanguageDetector from "i18next-browser-languagedetector";
import LocalStorageBackend from "i18next-localstorage-backend";
import CONFIG from "../config";

const resources = {
  en: {
    general: require("./locales/general_en"),
    reorder: require('./locales/reorder_en.json'),
    landing: require("./locales/landing_en"),
    summary: require("./locales/summary_en"),
    details: require("./locales/details_en"),
    plan: require("./locales/plan_en"),
    designs: require("./locales/designs_en"),
    confirmation: require("./locales/confirmation_en"),
  },
  de: {
    general: require("./locales/general_de"),
    reorder: require('./locales/reorder_de.json'),
    landing: require("./locales/landing_de"),
    summary: require("./locales/summary_de"),
    details: require("./locales/details_de"),
    plan: require("./locales/plan_de"),
    designs: require("./locales/designs_de"),
    confirmation: require("./locales/confirmation_de"),
  },
  it: {
    general: require("./locales/general_it"),
    reorder: require('./locales/reorder_it.json'),
    landing: require("./locales/landing_it"),
    summary: require("./locales/summary_it"),
    details: require("./locales/details_it"),
    plan: require("./locales/plan_it"),
    designs: require("./locales/designs_it"),
    confirmation: require("./locales/confirmation_it"),
  },
  bg: {
    general: require("./locales/general_bg"),
    reorder: require('./locales/reorder_bg.json'),
    landing: require("./locales/landing_bg"),
    summary: require("./locales/summary_bg"),
    details: require("./locales/details_bg"),
    plan: require("./locales/plan_bg"),
    designs: require("./locales/designs_bg"),
    confirmation: require("./locales/confirmation_bg"),
  },
};

// Custom browser navigator language detector,
// that will get the first language that the website supports.
// It will extract the language part of the locales "de-CH", "it-FR", etc..
// Will return the project default language if none is found.
const navigatorLanguageDetector = {
  name: 'navigatorLanguageDetector',
  lookup() {
    // Get the first browser language that is supported by our website.
    const browserLang = Array.from(
      new Set(window.navigator.languages?.map((l) => l.substr(0, 2)))
    )?.find((lang) => CONFIG.SUPPORTED_LANGUAGES.includes(lang));

    return browserLang || CONFIG.DEFAULT_LANGUAGE;
  }
}

const LanguageDetector = new BrowserLanguageDetector();
LanguageDetector.addDetector(navigatorLanguageDetector);

i18n
  .use(initReactI18next)
  .use(LocalStorageBackend)
  .use(LanguageDetector)
  .init({
    // Browser language detector's settings
    detection: {
      // order and from where user language should be detected
      order: ["querystring", "localStorage", "path", "navigatorLanguageDetector"],
      // keys or params to lookup language from
      lookupQuerystring: "lang",
      lookupLocalStorage: "i18nextLng",
      lookupFromPathIndex: 0,
      // cache user language on
      caches: ["localStorage"],
      // only detect languages that are in the whitelist
      checkWhiteList: true,
    },
    resources,
    whitelist: CONFIG.SUPPORTED_LANGUAGES,
    fallbackLng: CONFIG.DEFAULT_LANGUAGE,
    defaultNS: "general",
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    cache: {
      enabled: true,
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;

export const getLanguage = () => i18n.language;
