import actionTypes from "../constants/action_types/reorder";

import categories from "../constants/categories";

const initialState = {
  editing_step: "",
  token: null,
  first_order: false,
  google_place_details: null,
  contact_info: {
    company: "",
    contact: "",
    email: "",
    phone: "",
    vat_number: "",
  },
  shipping_address: {
    contact_name: "",
    address: "",
    city: "",
    postcode: "",
    country_code: "",
  },
  billing_address: {
    same_as_shipping: true,
    contact_name: "",
    address: "",
    city: "",
    postcode: "",
    country_code: "",
  },
  // The plan that is currently selected in the reorder process.
  // By default the ondemand plan is selected
  selected_plan: "ondemand",
  // The preselected plan in the backoffice tool.
  preselected_plan: null,
  // The plan that the client is currently on.
  current_plan: {
    name: "",
    valid_until: "",
  },
  designs: {
    cups_large: {
    },
    medium: {
      custom: "",
    },
    large: {
      custom: "",
    },
  },
  bag_logo: {
    logo: {
      url: "",
      file: null,
    },
    position: {
      x: 0,
      y: 0,
    },
    size: {
      width: 130,
      height: 130,
    },
    parent: {
      width: 130,
      height: 130,
    },
  },
};

export default function reorder(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SAVE_CUSTOM_BAG: {
      return {
        ...state,
        bag_logo: action.details,
      };
    }

    case actionTypes.SAVE_SHOP_DETAILS: {
      let newState = {
        ...state,
        token: action.details.token,
        contact_info: action.details.contact_info,
        shipping_address: {
          ...state.shipping_address,
          ...action.details.shipping_address,
        },
        selected_plan: action.details.plan.name || "ondemand",
        current_plan: action.details.plan.name
          ? action.details.plan
          : state.current_plan,
        billing_address: {
          ...state.billing_address,
          ...action.details.billing_address,
        },
      };

      if (action.details.token) {
        newState.db_billing_address = action.details.db_billing_address;
        newState.db_shipping_address = action.details.db_shipping_address;
      }

      return newState;
    }

    case actionTypes.SAVE_BUSINESS_GOOGLE_DETAILS: {
      const { country: country_code, ...details } = action.details;
      // Fill shipping address details using google pace details.
      const shippingAddress = {
        contact_name: details.name,
        address: details.address,
        city: details.city,
        postcode: details.postcode,
        country_code: country_code,
      };

      return {
        ...state,
        google_place_details: {
          ...details,
          country_code,
          category: categories.find(c => c.id === details.category)?.name
        },
        shipping_address: {
          ...state.shipping_address,
          ...shippingAddress,
        },
        contact_info: {
          ...state.contact_info,
          company: details.name,
          phone: details.phone,
        },
      };
    }

    case actionTypes.SET_PLAN: {
      return {
        ...state,
        selected_plan:
          action.plan === state.selected_plan
            ? state.preselected_plan || state.current_plan.name || initialState.selected_plan
            : action.plan,
      };
    }

    case actionTypes.SET_EDITING_STEP: {
      return {
        ...state,
        editing_step: action.step,
      };
    }

    case actionTypes.SET_FIRST_ORDER: {
      return { ...state, first_order: true };
    }

    case actionTypes.SET_PRESELECTED_PLAN: {
      return {
        ...state,
        selected_plan: action.plan,
        preselected_plan: action.plan,
      };
    }

    case actionTypes.HANDLE_INPUT_CHANGE: {
      return {
        ...state,
        [action.panel]: {
          ...state[action.panel],
          [action.field]: action.value,
        },
      };
    }

    case actionTypes.HANDLE_QUANTITY_CHANGE: {
      return {
        ...state,
        designs: {
          ...state.designs,
          [action.size]: {
            ...state.designs[action.size],
            [action.design]: action.quantity,
          },
        },
      };
    }

    case actionTypes.HANDLE_COUNTRY_SELECT: {
      return {
        ...state,
        [action.panel]: {
          ...state[action.panel],
          country_code: action.country,
        },
      };
    }

    case actionTypes.TOGGLE_SAME_ADDRESS: {
      return {
        ...state,
        billing_address: {
          ...state.billing_address,
          same_as_shipping: !state.billing_address.same_as_shipping,
        },
      };
    }

    case actionTypes.RESET_REORDER: {
      return initialState;
    }

    default:
      return state;
  }
}
