import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";

import { urlToFile } from "../../../utilities/files";

import { ScaleIn } from "../../hocs/AnimationHOC";

import { resizable, draggable, unset } from "../../../utilities/gestures";

/**
 * The basic logic behind the whole component can be broken down in a few simple steps:
 * 1. When the "Upload logo/Change logo" button is clicked, a hidden file input is triggered
 * 		and a prompt shows up.
 * 2. When a file is chosen it's being read and loaded into the resize and reposition tool. The tool
 * 		would pop up and the user can resize and reposition his picture/logo within the dedicated
 * 		rectangle area.
 * 3. When he clicks on "Save" a few things are being actually saved:
 * 		- The width and the height of the logo he uploaded, resized and repositioned.
 * 		- The x and y coordinates of the logo he uploaded within the dedicated rectangle.
 * 		- The width and the height of the dedicated rectangle.
 * 		All this info is being saved so things are looked at and displayed in proportions and
 * 		not just pixels (we all now pixels are not responsive enough). *
 * 4. When the uploaded logo is being displayed(previewed) the size of the dedicated rectangle plus the size and
 * 		the coordinates of the uploaded logo that were saved earlier are now used to calculate the position
 * 		and the size of the custom bag logo in percentages.
 */
class UploadDesign extends Component {
  state = {
    cropperVisible: false,
    loading: false,
    replaceImage: false,
    upload_error: false,
    gestures_set: false,
    src: "",
    position: this.props.position,
    size: this.props.size,
    ratio: 1,
    parent: this.props.parent,
  };

  inputRef = new React.createRef();
  logoWrapper = new React.createRef();

  /**
   * Attach event listener to the document body when the file select dialog is opened
   * in order to be able to check if the "Cancel" button was clicked
   * or a file was uploaded
   */
  initializeFileSelect = () => (document.body.onfocus = this.checkFileSelect);

  handleFileUpload = (ev) => this.readImageUpload(ev.target);

  /**
   * This method will get the uploaded image from the file select and
   * will check if its dimensions are less than the required ones
   * (400px x 400px). If positive it will display an error message.
   * If not it will load the image into the cropper tool.
   * @param {HTMLElement} input
   */
  readImageUpload = (input) => {
    let { t } = this.props;

    if (input.files && input.files[0]) {
      this.setState({ loading: true, upload_error: false }, () => {
        let img = new Image();
        img.src = window.URL.createObjectURL(input.files[0]);
        img.onload = () => {
          let width = img.naturalWidth,
            height = img.naturalHeight;

          window.URL.revokeObjectURL(img.src);
          if (width < 100 || height < 100)
            return this.setState({
              upload_error: t("general:uploader_error_size"),
              src: "",
              loading: false,
            });

          if (!input.files[0].name.match(/.(jpg|jpeg|png|gif)$/i))
            return this.setState({
              upload_error: t("general:uploader_error_format"),
              src: "",
              loading: false,
            });

          // Persist image size ratio
          const ratio = width / height;
          const newWidth =
            ratio >= 1
              ? width / (width / 130)
              : (height / (height / 130)) * ratio;
          const newHeight =
            ratio >= 1 ? newWidth / ratio : height / (height / 130);

          this.setState({
            size: {
              width: newWidth,
              height: newHeight,
            },
            ratio,
          });

          let reader = new FileReader();
          reader.onload = this.setUploadedImage;
          reader.readAsDataURL(input.files[0]);
        };
      });
    }
  };

  setUploadedImage = (e) => {
    this.setState(
      {
        upload_error: false,
        cropperVisible: true,
        src: e.target.result,
        loading: false,
      },
      () => {
        this.setParent();
        this.setGestures();
      }
    );
  };

  setParent = () => {
    let { clientWidth, clientHeight } = this.logoWrapper;

    this.setState({
      parent: {
        width: clientWidth,
        height: clientHeight,
      },
    });
  };

  setGestures = () => {
    setTimeout(() => {
      unset(".uploaded-logo-img");
      draggable(".uploaded-logo-img", (position) =>
        this.setState((state) => ({
          x: (state.position.x += position.x),
          y: (state.position.y += position.y),
        }))
      );
      resizable(
        ".uploaded-logo-img",
        ({ size, position }) => this.setState({ size, position }),
        50,
        this.state.ratio
      );
    }, 0);
  };

  /**
   * Check if a file was selected or the "Cancel" button was clicked
   * in the file select dialog window.
   */
  checkFileSelect = () => {
    let cropperVisible = true;

    if (
      this.inputRef &&
      !this.inputRef.value.length &&
      !this.state.replaceImage
    )
      cropperVisible = false;

    this.setState({ cropperVisible });
    document.body.onfocus = null;
  };

  /**
   * Save the cropped image preview and file
   */
  saveLogo = async () => {
    let { src, position, size } = this.state;
    let { clientWidth, clientHeight } = this.logoWrapper;

    let data = {
      logo: {
        url: src,
        file: await urlToFile(src, "logo.jpg", "image/jpg"),
      },
      size: {
        width: size.width > clientWidth ? clientWidth : size.width,
        height: size.height > clientHeight ? clientHeight : size.height,
      },
      position: {
        x: position.x < 0 ? 0 : position.x,
        y: position.y < 0 ? 0 : position.y,
      },
      parent: {
        width: clientWidth,
        height: clientHeight,
      },
    };

    this.props.saveLogo(data);
    this.setState({ cropperVisible: false });
  };

  openFilePrompt = (replacing) => {
    if (this.inputRef.current !== undefined) return;
    this.setState({ replaceImage: replacing });
    this.inputRef.click();
  };

  closeCropper = () =>
    this.setState({
      cropperVisible: false,
      replaceImage: false,
      position: { x: 0, y: 0 },
    });

  render = () => {
    let { t } = this.props;
    let {
      src,
      upload_error,
      cropperVisible,
      position,
      size,
      parent,
      loading,
    } = this.state;

    return (
      <div className="upload-design-container">
        <input
          type="file"
          accept=".jpg,.png,.jpeg,.gif"
          className="image-uploader-input"
          ref={(node) => (this.inputRef = node)}
          onChange={this.handleFileUpload}
          onClick={this.initializeFileSelect}
        />
        <div
          className="btn btn-primary mt-4 upload-design-btn"
          onClick={() => this.openFilePrompt(false)}
        >
          {this.props.logo.url
            ? t("change_logo")
            : t("upload_logo")}
        </div>
        {cropperVisible && (
          <div className="design-cropper-container">
            <ScaleIn className="cropper-content">
              <div className="cropper-title d-flex justify-content-between align-items-center">
                <h6 className="blue-Bold-20px-left">
                  {upload_error
                    ? t("editor_heading_error")
                    : loading
                    ? t("editor_heading_loading")
                    : t("editor_heading_regular")}
                </h6>
                <img
                  src={`${process.env.PUBLIC_URL}/images/assets/close.svg`}
                  className="close-design-crop"
                  onClick={this.closeCropper}
                  alt="x"
                />
              </div>
              <div className="image-container">
                {upload_error ? (
                  <span
                    className="blue-Regular-16px-center design-upload-error"
                    dangerouslySetInnerHTML={{
                      __html: this.state.upload_error,
                    }}
                  />
                ) : (
                  loading && (
                    <div className="lds-ring">
                      <div />
                      <div />
                      <div />
                      <div />
                    </div>
                  )
                )}
                {!loading && src && (
                  <Fragment>
                    <img
                      src={`${process.env.PUBLIC_URL}/images/register/designs/bag/large/empty.png`}
                      className="plain-bag-background"
                      alt=""
                    />
                    <div className="uploaded-logo-container">
                      <div
                        className="logo-wrapper"
                        ref={(node) => (this.logoWrapper = node)}
                      >
                        <div
                          className="uploaded-logo-img"
                          data-x={position.x}
                          data-y={position.y}
                          style={{
                            marginLeft: `${(position.x / parent.width) * 100}%`,
                            marginTop: `${(position.y / parent.height) * 100}%`,
                            backgroundImage: `url(${src})`,
                            width: `${size.width}px`,
                            height: `${size.height}px`,
                          }}
                        ></div>
                      </div>
                    </div>
                  </Fragment>
                )}
              </div>
              <div className="cropper-controls d-flex justify-content-between align-items-center">
                {loading ? null : upload_error ? (
                  <Fragment>
                    <span />
                    <div
                      className="btn btn-primary"
                      onClick={() => this.openFilePrompt(true)}
                    >
                      {t("editor_try_again")}
                    </div>
                  </Fragment>
                ) : (
                  <Fragment>
                    <span onClick={() => this.openFilePrompt(true)}>
                      {t("editor_replace")}
                    </span>
                    <div className="btn btn-primary" onClick={this.saveLogo}>
                      {t("editor_save")}
                    </div>
                  </Fragment>
                )}
              </div>
            </ScaleIn>
          </div>
        )}
      </div>
    );
  };
}

export default withTranslation("reorder")(UploadDesign);
