import React, { Component } from "react";
import { onInput, onInputInvalid } from "../../../helpers/FormHelpers";

import "../../../utilities/extensions";

class Input extends Component {
  constructor(props) {
    super(props);

    this.state = {
      beenFocused: false,
      status: "empty",
      value: props.value,
    };

    this.reference = new React.createRef();
  }

  componentDidUpdate = (prevProps) => {
    // If the newly received value is different from the old one act as if
    // there was an input by the user and validate the new value
    if (this.props.value !== prevProps.value) {
      this.setState(
        {
          beenFocused: true,
          value: this.props.value,
        },
        () =>
          this.checkValidity(
            {
              target: {
                value: this.props.value,
              },
            },
            true,
            () => {
              if (this.state.status === "invalid") this.invalidateInput();
              else this.validateInput();
            }
          )
      );
    }
  };

  /**
   * Clear input validation error message.
   */
  validateInput = () => onInput({ target: this.reference });

  /**
   * Display input field error message.
   */
  invalidateInput = () =>
    onInputInvalid({ target: this.reference }, this.props.errorMessage);

  checkValidity = (ev, afterBlur = false, callback = () => {}) => {
    let { required, minLength, maxLength, pattern } = this.props;
    let value =
      typeof ev.target.value === "string"
        ? ev.target.value.trim()
        : ev.target.value;

    if (afterBlur && !required) return this.setState({ status: "" });

    if (!required && !minLength && !maxLength && !pattern)
      return this.setState({ status: "" });

    if (required && !value)
      return this.setState({ status: "invalid" }, this.invalidateInput);

    if (minLength && value.length < minLength)
      return this.setState({ status: "invalid" }, this.invalidateInput);

    if (maxLength && maxLength < value.length)
      return this.setState({ status: "invalid" }, this.invalidateInput);

    if (pattern && !RegExp(pattern).test(value))
      return this.setState({ status: "invalid" }, this.invalidateInput);

    this.setState({ status: "valid" }, () => {
      this.validateInput();
      callback();
    });
  };

  handleBlur = (ev) => {
    if (this.props.onBlur)
      this.props.onBlur(
        ev || { target: this.reference, value: this.state.value }
      );

    this.checkValidity({ target: this.reference }, true);
  };

  handleFocus = (ev) => {
    this.setState({ beenFocused: true });
    this.props.onFocus && this.props.onFocus(ev);
  };

  handleOnChange = (ev) => {
    this.setState({ value: ev.target.value });
    this.props.onChange(ev);
    this.checkValidity(ev);
  };

  setReference = (node) => {
    this.reference = node;
    if (this.props.setRef) this.props.setRef(node);
  };

  render = () => {
    let {
      required,
      reference,
      type,
      autocomplete,
      placeholder,
      validateOnMount,
      onChange,
      errorMessage,
      setRef,
      withLabel = false,
      ...props
    } = this.props;

    return (
      <div
        className={`input ${withLabel ? "with-label" : ""} ${
          this.state.status
        }`}
      >
        {withLabel && (
          <label className="blue-Regular-14px" htmlFor={props.name || ""}>
            {placeholder}
          </label>
        )}
        <input
          {...props}
          type={type || "text"}
          autoComplete={autocomplete || "none"}
          required={required}
          ref={this.setReference}
          value={this.state.value}
          placeholder={!withLabel ? placeholder : ""}
          onInvalid={this.invalidateInput}
          onChange={this.handleOnChange}
          onBlur={this.handleBlur}
          onFocus={this.handleFocus}
        />
      </div>
    );
  };
}

export default Input;
