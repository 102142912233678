import React, { Component } from "react";

import { connect } from "react-redux";
import layoutActions from "../../../actions/layout";
import { withTranslation } from "react-i18next";

import { navigate } from "../../shared/routes/CustomBrowserRouter";
import routes from "../../../constants/routes";
import { config as bags_config } from "../../../constants/designs";

import NeedHelp from "./NeedHelp";
import OrderTotals from "./OrderTotals";
import PotentialEarnings from "./PotentialEarnings";

class ChooseDesignsSummary extends Component {
  onFormSubmit = (ev) => {
    let { t } = this.props;

    if (this.getOrderQuantities() < bags_config.MIN_QUANTITY_REGULAR)
      return this.setError(
        t("summary:step_3_error_bags_title"),
        t("summary:step_3_error_bags_text", { count: bags_config.MIN_QUANTITY_REGULAR })
      );

    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate(routes.register.confirmation);
  };

  getOrderQuantities = () => {
    let { designs } = this.props.details;
    let regular_bags = 0;
    let custom_large_bags = parseInt(designs.large.custom, 10) || 0;
    let custom_medium_bags = parseInt(designs.medium.custom, 10) || 0;

    Object.keys(designs).forEach((size) =>
      Object.keys(designs[size]).forEach(
        (design) =>
          design !== "custom" &&
          (regular_bags += parseInt(designs[size][design], 10) || 0)
      )
    );

    return regular_bags + custom_large_bags + custom_medium_bags;
  };

  setError = (title, text) =>
    this.props.toggleError(title && text, { title, text });

  render = () => {
    let { t, editingDesigns, designsHeight } = this.props;
    let isNextValid =
      this.getOrderQuantities() >= bags_config.MIN_QUANTITY_REGULAR
        ? ""
        : "disabled";

    return (
      <div className="summary designs" style={{ height: designsHeight + 140 }}>
        <div className="summary-container">
          <div className="choose-designs-summary summary-content">
            <div className={`summary-content-wrapper`}>
              <h4 className="blue-Bold-20px-left">
                {window.innerWidth <= 780
                  ? t("summary:step_3_heading_mobile")
                  : t("summary:step_3_heading_desktop")}
              </h4>
              <OrderTotals
                plan={this.props.plan}
                quantities={this.props.quantities}
                country={this.props.country}
                procedure="registration"
              />
              <div className="proceed">
                <button
                  className={`btn btn-block btn-primary ${isNextValid}`}
                  type="submit"
                  form="shop-details-form"
                  onClick={this.onFormSubmit}
                >
                  {editingDesigns
                    ? t("summary:save_changes")
                    : t("summary:step_3_final_step")}
                </button>
              </div>
              <PotentialEarnings />
              <NeedHelp />
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  details: state.register,
  editingDesigns: state.register.editing_step === "designs",
  displayError: state.layout.error_popups.designs,
  designsHeight: state.layout.dimensions.designs.options_list.height,
  quantities: state.register.designs,
  plan: state.register.plan,
  country: state.register.shop_details.country,
});

const mapDispatchToProps = (dispatch) => ({
  toggleError: (toggled, error) =>
    dispatch(layoutActions.toggleErrorPopup("designs", toggled, error)),
});

let reduxWrapped = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChooseDesignsSummary);

export default withTranslation()(reduxWrapped);
