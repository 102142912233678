import { plainRequest } from "../../utilities/request";
import { BUSINESS_DETAILS_URL } from "../../constants/endpoints";

/**
 * This service is responsible for handling business-related requests to the backend.
 */
class BusinessAPIService {
  /**
   * Fetches information about a registered shop (contact info, current plan, addresses).
   * @param {string} token
   * @return {Promise<{success: boolean, details: object}|{success: boolean}>}
   */
  static async fetchBusinessDetails(token) {
    try {
      return await plainRequest(BUSINESS_DETAILS_URL, "GET", null, {
        Authorization: token,
        "Content-Type": "application/json",
      });
    } catch (ex) {
      return { success: false };
    }
  }
}

export default BusinessAPIService;
