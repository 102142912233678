import React from "react";
import { Route, useLocation } from "react-router-dom";
import { navigate } from "./CustomBrowserRouter";

/**
 * Use this component to render a simple redirect that preserves the URL query.
 * If the "to" prop has a value of type function, the match object should be passed as an argument to it.
 * If the "to" prop has a value of type string, the user will be redirected to its value on component mount.
 * @param {string} from - Where to navigate from.
 * @param {string|function} to - Where to navigate to.
 * @param {object} rest - The rest of the props to pass on to the <Route/> component.
 * @return {JSX.Element}
 */
export default ({ from, to, ...rest }) => {
  const { search } = useLocation();

  return (
    <Route
      {...rest}
      path={from}
      render={({ match }) => {
        navigate((typeof to === "function" ? to(match) : to) + search);
        return null;
      }}
    />
  );
};
