import React from "react";
import { Prompt, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { navigate } from "../shared/routes/CustomBrowserRouter";
import { getLanguage } from "../../config/i18n";
import ConnectedIntlProvider from "../../providers/ConnectedIntlProvider";

import plans from "../../constants/plans";
import routes from "../../constants/routes";
import layoutActions from "../../actions/layout";
import reorderActions from "../../actions/reorder";

import ProgressService from "../../services/business/ProgessService";
import BusinessService from "../../services/business/BusinessService";
import LocationService from "../../services/business/LocationService";
import ShopsHelpers from "../../helpers/ShopsHelpers";

import ReorderNavigation from "./ReorderNavigation";
import ReorderSummary from "./summary/Summary";
import ChooseDesignsStep from "./designs/ChooseDesignsStep";
import DetailsStep from "./details/DetailsStep";
import ConfirmationStep from "./confirmation/ConfirmationStep";

import "../../stylesheets/reorder/index.scss";

export const __DEVELOP_CONFIRMATION_SET_TO_FALSE_IN_PRODUCTION = false;

export default ({ history, match }) => {
  const { t } = useTranslation("general");
  const dispatch = useDispatch();

  const urlArea = window.location.pathname.match(/\/new.*/)
    ? "new_business"
    : "reorder";

  React.useEffect(() => {
    const keyPressEventListener = (ev) =>
      ev.keyCode === 27 && dispatch(layoutActions.hideErrorPopups());

    loadBusinessInfo();

    onbeforeunload = (e) => t("page_reload_prompt");
    document.addEventListener("keydown", keyPressEventListener);

    return () => {
      onbeforeunload = null;
      document.removeEventListener("keydown", keyPressEventListener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Load details from Google Places for an ID and pre-fill the business details with them.
   * @param {string} placeId
   */
  const loadGooglePlacesDetails = (placeId) =>
    LocationService.getPlaceDetails(placeId, (place) => {
      if (typeof place !== "undefined" && place !== null) {
        const parsedDetails = ShopsHelpers.parseGooglePlaceDetails(place);

        dispatch(reorderActions.saveBusinessGoogleDetails(parsedDetails));
        ProgressService.leadCustomer(placeId);
      }
    });

  /**
   * Parses the token param value from the uri query and
   * fetches the business details using that token.
   */
  const loadBusinessInfo = async () => {
    const params = Object.fromEntries(
      history.location.search
        .substr(1)
        .split("&")
        .map((param) => param.split("="))
    );

    if (urlArea === "new_business") {
      // If a new business is registering (he is hitting the "/new" route), set the first_order flag to true by default.
      dispatch(reorderActions.setFirstOrder());
    } else {
      if (params.token) {
        const { success, data } = await BusinessService.getBusinessDetails(
          params.token
        );

        if (success) dispatch(reorderActions.saveBusinessDetails(data));
      }
    }

    // Check if this is the business' first order. If yes, different settings will be applied further in the process.
    if (params.first_order === "1") dispatch(reorderActions.setFirstOrder());

    // Check if the a specific plan should be preselected. If yes, different settings will be applied further in the process.
    // If the pathname is /new/premium or /en/new/standard_free, the premium and standard_free plans will be pre-selected.
    if (Object.keys(plans).includes(match.params.action))
      dispatch(reorderActions.setPreselectedPlan(match.params.action));

    // If there is :google_place_id parameter in the url, try to fetch and load the details from Google for that
    // place Id in the process state.
    if (match.params.google_place_id?.match(/^\S{20,}$/))
      loadGooglePlacesDetails(match.params.google_place_id);


    // CONFIRMATION TESTING START
    if(__DEVELOP_CONFIRMATION_SET_TO_FALSE_IN_PRODUCTION) {
      dispatch(reorderActions.saveBusinessDetails({
        contact_info: {
          company: "Test",
          contact: "Test",
          email: "jh@goodbag.io",
          phone: "234234",
          vat_number: "234234",
        },
        shipping_address: {
          contact_name: "Johannes Herrnegger",
          address: "Wintergasse",
          city: "Purkersdorf",
          postcode: "3002",
          country_code: "AT",
        },
        plan: {
          name: 'ondemand',
        }
      }));
      dispatch(reorderActions.handleQuantityChange('large', 'cat_large_dark','10'))
      dispatch(reorderActions.handleQuantityChange('cups_large', 'goodcup_circularco_makerefillcount_large_white_green','10'))
    }
    // CONFIRMATION TESTING END
    if(!__DEVELOP_CONFIRMATION_SET_TO_FALSE_IN_PRODUCTION) {
      navigate(routes[urlArea].designs, { hidePrompt: true });
    }
  };

  return (
    <div className="reorder-page">
      <div id="map" />
      {/* Prompt user to confirm if he wants to leave the /reorder(or /new) route and lose all progress. */}
      <Prompt
        when={true}
        message={(location) =>
          location.state?.hidePrompt ||
          (location.pathname.startsWith(
            routes.reorder.start.replace(":lang", getLanguage())
          ) &&
            // Don't show prompt when redirecting from /:lang/reorder?token=TOKEN to /:lang/reorder/designs
            location.pathname !==
              routes.reorder.start.replace(":lang", getLanguage())) ||
          location.pathname.startsWith(
            routes.new_business.start.replace(":lang", getLanguage())
          )
            ? true
            : t("process_leave_prompt", {
                process:
                  urlArea === "new_business" ? t("register") : t("reorder"),
              })
        }
      />
      <ConnectedIntlProvider>
        <ReorderNavigation current={match.params.action} />
      </ConnectedIntlProvider>
      <div className="reorder-step-container">
        <ConnectedIntlProvider>
          <Route path={routes.reorder.designs} component={ChooseDesignsStep} />
          <Route path={routes.reorder.details} component={DetailsStep} />
          <Route
            path={routes.reorder.confirmation}
            component={ConfirmationStep}
          />
          <Route
            path={routes.new_business.designs}
            component={ChooseDesignsStep}
          />
          <Route path={routes.new_business.details} component={DetailsStep} />
          <Route
            path={routes.new_business.confirmation}
            component={ConfirmationStep}
          />
        </ConnectedIntlProvider>
      </div>
      <ReorderSummary />
    </div>
  );
};
