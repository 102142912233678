import React, { Component } from "react";

import { connect } from "react-redux";
import layoutActions from "../../../actions/layout";

class PreviewSection extends Component {
  render = () => {
    let {
      heading,
      link,
      children,
      className,
      style,
      name,
      panels,
      togglePanel,
      editEnabled,
    } = this.props;
    let isToggled = panels[name] ? "toggled" : "";

    return (
      <div className={`preview-section`}>
        <div className="section-heading blue-Bold-16px d-flex justify-content-between">
          <div>{heading}</div>
          <div className="actions">
            {typeof editEnabled !== "undefined" && !editEnabled ? null : (
              <span className="action" onClick={link}>
                <img
                  src={`${process.env.PUBLIC_URL}/images/assets/pencil.svg`}
                  alt="Edit"
                />
              </span>
            )}
            <span onClick={() => togglePanel(name)} className="action">
              <i className={`fas fa-chevron-${panels[name] ? "up" : "down"}`} />
            </span>
          </div>
        </div>
        <div
          className={`section-content ${isToggled} ${className || ""}`}
          style={style || {}}
        >
          {children}
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  panels: state.layout.toggles.confirmation,
});

const mapDispatchToProps = (dispatch) => ({
  togglePanel: (panel) => dispatch(layoutActions.togglePreviewPanel(panel)),
});

const ReduxWrapped = connect(
  mapStateToProps,
  mapDispatchToProps
)(PreviewSection);

export default ReduxWrapped;
