import React, { Fragment } from "react";

export default {
  en: (
    <Fragment>
      <h2>
        Privacy Information by bgood GmbH
        <br />
        for the use of the goodbag smartphone-app and Platform
      </h2>
      <p className="mt-4">
        In the following we provide you with information about your rights
        regarding the collection and processing of your personal data. This
        information is provided in accordance with Articles 13 and 14 of the
        General Data Protection Regulation (GDPR).
      </p>
      <div className="ordered-list">
        <div className="entry">
          <div className="li-title">1. General information</div>
          The <strong>controller</strong> of the data processing is
          <br />
          <br />
          <strong>bgood GmbH</strong>
          <br />
          Laimgrubengasse 19/7 <br />
          1060 Vienna
          <br />
          Austria
          <br />
          Commercial Register: FN 442550i, Commercial Court Vienna
          <br />
          E-Mail: dataprotection@goodbag.io
          <br />
        </div>

        <div className="entry">
          <div className="li-title">
            2. Lawfulness and purpose of processing
          </div>
          <p>
            The lawfulness of the data processing is based on the 
            <strong>necessity for the performance of the contract</strong>
             concluded with you via the goodbag smartphone-app (Art. 6 sec 1b
            GDPR) namely regarding the use of your data for the purposes of
            technical operation of the goodbag smartphone-app (e.g. display of
            shops nearby), the processing of orders via the web shop and general
            customer administration.
          </p>
          <p>
            Some data processing activities, in particular about interactions
            within the goodbag smartphone-app, are based on our 
            <strong>legitimate interests</strong> which are not overridden by
            your interests (Art 6 sec 1f GDPR). Our legitimate interest relates
            to the analysis of usage as well as improvement of the goodbag
            smartphone-app's performance and usability.
          </p>
          <p>
            The storage of the data during the legal storage periods is carried
            out in accordance with corresponding 
            <strong>legal obligations</strong> (Art. 6 sec 1c GDPR).
          </p>
        </div>

        <div className="entry">
          <div className="li-title">3. Personal data</div>
          <p>
            We process the following personal data when 
            <strong>using the bag</strong>:
          </p>
          <ul>
            <li>
              Identification number (Universal Unique Identifier) of the bag
            </li>
            <li>Serial number of the chips built into the bag</li>
          </ul>
          <p>
            If you additionally use the goodbag smartphone-app (as shown below)
            these data will be connected with your customer profile. In this
            case these data are personal data.
          </p>
          <p className="mt-4">
            In case of using the goodbag 
            <strong>smartphone-app without registration</strong> we additionally
            process the following personal data:
          </p>
          <ul>
            <li>Identification number of the customer</li>
            <li>Position data (GPS coordinates), region and time zone</li>
            <li>Installation-ID of the app</li>
            <li>IP-address of the device used</li>
            <li>
              Shops, in which a voucher was redeemed (ID and name of the shop)
            </li>
            <li>
              Identification number (Universal Unique Identifier) of previous
              bags used with the same device
            </li>
            <li>Language- and regional settings of the used device</li>
            <li>Date and time of a bag-scan</li>
            <li>Interactions within the goodbag smartphone-app</li>
            <li>Interactions with the NFC-chip of the bag</li>
            <li>Places/shops, in which the bag was scanned</li>
          </ul>
          <p className="mt-4">
            In case of using the goodbag 
            <strong>smartphone-app with registration</strong> we additionally
            process the following personal data:
          </p>
          <ul>
            <li>Name</li>
            <li>Date of birth or age</li>
            <li>E-mail address</li>
            <li>Gender</li>
            <li>Interests</li>
            <li>
              Data regarding social media log-ins (e.g. Facebook-, Google-,
              Twitter-account data)
            </li>
          </ul>
          <p className="mt-4">
            In case of an <strong>order</strong> via the goodbag
            smartphone-app's <strong>web shop</strong> we process the following
            personal data:
          </p>
          <ul>
            <li>Name</li>
            <li>Contact data (e-mail address, phone number)</li>
            <li>Delivery address</li>
            <li>Invoice address</li>
            <li>Payment details</li>
          </ul>
          <p className="mt-4">
            In case of <strong>contacting us</strong> (e.g. feedback, suggestion
            of a partner shop) via the goodbag smartphone-app we process the
            following personal data:
          </p>
          <ul>
            <li>Name</li>
            <li>E-mail address</li>
            <li>Content of the message/recommendation</li>
          </ul>
          <p className="mt-4">
            If you want to <strong>apply as partner shop</strong> we process the
            following personal data:
          </p>
          <ul>
            <li>Name</li>
            <li>Name of the company</li>
            <li>Name of the contact person</li>
            <li>Contact data (e-mail address, phone number)</li>
            <li>Name of the shop</li>
            <li>Description of the shop</li>
            <li>Address</li>
            <li>Opening hours</li>
            <li>VAT</li>
            <li>Website</li>
            <li>Facebook Account</li>
            <li>Instagram Account</li>
          </ul>
          <p className="mt-4">
            If you use the <strong>form for planting a tree</strong> we process
            the following personal data:
          </p>
          <ul>
            <li>Name</li>
            <li>E-mail address</li>
            <li>Code to prove authorization of planting a tree</li>
          </ul>
          <p className="mt-4">
            If you <strong>refer a friend</strong>, we process the following
            personal data:
          </p>
          <ul>
            <li>E-mail address of the recipient</li>
            <li>
              Identity of the referring person (connection with goodbag account)
            </li>
            <li>Status of the recruit-a-friend-code</li>
          </ul>
        </div>

        <div className="entry">
          <div className="li-title">4. Disclosure of personal data</div>
          <p>
            As far as necessary for the purposes mentioned in section 3, we use
            the following services and disclose your personal data to our 
            <strong>technical service provider</strong>:
          </p>
          <ul>
            <li>
              "Google Maps" and "Firebase" of Google LLC with its seat in the
              USA; to guarantee the safety of your data, it is certified under
              the EU-US Privacy Shield Agreement;
            </li>
            <li>"Apple Maps" of Apple Inc. with its seat in the USA;</li>
            <li>
              "shopify" of Shopify International Ltd with its seat in Ireland;
            </li>
            <li>
              Newsletter mailing: The Rocket Science Group LLC d/b/a MailChimp
              with its seat in the USA; to guarantee the safety of your data, it
              is certified under the EU-US Privacy Shield Agreement;
            </li>
            <li>
              Diverse services of the "Visual Studio App Center" of Microsoft
              Corporation; some of the Microsoft corporations have their seat in
              the USA; to guarantee the safety of your data, those are certified
              under the EU-US Privacy Shield Agreement; and
            </li>
            <li>
              Content Delivery Network and Proxy-Server of Cloudflare, Inc.,
              with its seat in the USA; to guarantee the safety of your data,
              those are certified under the EU-US Privacy Shield Agreement.
            </li>
          </ul>
          <p>
            As <strong>host provider</strong> we use, inter alia, DigitalOcean
            with its seat in the USA (certified under the EU-US Privacy Shield
            Agreement), Google Cloud of Google LLC with its seat in the USA
            (certified under the EU-US Privacy Shield Agreement), Amazon AWS of
            Amazon.com, Inc. with its seat in the USA (certified under the EU-US
            Privacy Shield Agreement) and of Amazon Web Services EMEA SARL with
            its seat in Luxembourg as well as Azure of Microsoft Corporation
            with its seat in the USA (certified under the EU-US Privacy Shield
            Agreement).
          </p>
          <p>
            In case you choose using a <strong>social media log-in</strong>
             (e.g. Facebook Connect, Google Sign-In, Sign in with Twitter)
            personal data will be transferred to the responsible social media
            provider as well as to the service "Firebase Authentication" of
            Google LLC with its seat in the USA (certified under the EU-US
            Privacy Shield Agreement).
          </p>
          <p>
            Furthermore other <strong>service providers (processors)</strong>
             contracted by us will receive your personal data in order to render
            their respective services. All service providers are contractually
            obligated to keep your personal data confidential and may use your
            personal data only on our behalf and in line with our instructions.
          </p>
          <p>
            We will transfer your personal data to 
            <strong>public authorities</strong> and institutions (e.g. tax
            authority, courts, public prosecutor's office) if we are legally
            obligated to do so.
          </p>
        </div>

        <div className="entry">
          <div className="li-title">
            5. Duration of processing / storage period
          </div>
          <p>
            We just process your personal data as long as necessary. As soon as
            your data is no longer needed and there is no requirement for a
            longer storage period, it will be deleted automatically. We store
            your personal data necessary for the performance of the contract at
            least for the duration of the entire business relationship as well
            as, furthermore, in accordance with the legal storage and
            documentation obligations.
          </p>
        </div>

        <div className="entry">
          <div className="li-title">6. Your rights</div>
          <div className="font-italic">Right to information</div>
          <p className="mt-3">
            Provided that we process your personal data, you have the right to
            information about the purposes of processing, the categories of
            personal data, the origin and the recipients of your personal data,
            the duration of storage, your rights and the existence of an
            automated decision-making.
          </p>

          <div className="font-italic mt-3">
            Rectification and erasure of data
          </div>
          <p className="mt-3">
            If we process inaccurate or incomplete personal data you have the
            right to rectification of such data. You may also request the
            erasure of your personal data if your data is processed unlawfully,
            subject to legal obligations preventing the erasure of your personal
            data.
          </p>

          <div className="font-italic mt-3">Limitation of processing</div>
          <p className="mt-3">
            You may request to limit the processing of your personal data in
            certain cases.
          </p>

          <div className="font-italic mt-3">Data portability</div>
          <p className="mt-3">
            You have the right to receive the personal data, which you have
            provided to us, in a structured, commonly used and machine-readable
            format. You have the right to direct transmission of those data to
            another controller as far as this is technically feasible.
          </p>

          <div className="font-italic mt-3">Right to object</div>
          <p className="mt-3">
            You have the right to object, on grounds relating to your particular
            situation, at any time to processing of personal data concerning
            you. If you object to processing of your personal data, we shall
            cease to process this data unless our legitimate interests to
            processing your personal data override your interests, rights and
            freedoms or the processing serves the assertion, exercise or defence
            of legal claims. Where personal data are processed for direct
            marketing purposes, you shall have the right to object at any time
            to processing of personal data concerning you. In this case, we will
            cease the processing of your personal data for marketing purposes
            immediately.
          </p>

          <div className="font-italic mt-3">Complaint</div>
          <p className="mt-3">
            You have the right to lodge a complaint with the Austrian Data
            Protection Authority (Österreichische Datenschutzbehörde),
            Barichgasse 40-42, 1030 Vienna, +43 1 52 152-0, e-mail address:
            dsb@dsb.gv.at if you believe that your rights to protection of your
            personal data have been infringed.
          </p>
        </div>
      </div>
    </Fragment>
  ),
};
