import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  adjustProcessNavigationScroll,
  getNextReorderStep,
  goToFirstIncompleteStep,
} from "../../../helpers/ProcessHelpers";
import layoutActions from "../../../actions/layout";
import reorderActions from "../../../actions/reorder";
import routes from "../../../constants/routes";

import PrivacyPolicyPopup from "../../legal/popup/PrivacyPolicyPopup";
import TermsAndConditionsPopup from "../../legal/popup/TermsAndConditionsPopup";
import InfoMessage from "../../shared/InfoMessage";
import { Loader } from "../../../App";
//import PlanPreview from "../../register/confirmation/PlanPreview";
import DesignsPreview from "../../register/confirmation/DesignsPreview";
import DetailsPreview from "./DetailsPreview";

import "../../../stylesheets/reorder/confirmation.scss";

export default () => {
  const { t } = useTranslation("reorder");
  const [shouldRender, setShouldRender] = React.useState(false);
  const dispatch = useDispatch();
  const {
    error_popups: { confirmation: displayError },
    popups: { privacy: privacyOpened, terms: termsOpened },
  } = useSelector((s) => s.layout);

  const urlArea = window.location.pathname.match(/\/new.*/) ? 'new_business' : 'reorder';

  const {
    selected_plan: plan,
    designs,
    bag_logo,
    registered,
    shipping_address: { country_code },
  } = useSelector((s) => s.reorder);

  React.useEffect(() => {
    const willRedirect = !goToFirstIncompleteStep(urlArea);
    if (!willRedirect) {
      // If the business is registered and his details step is incomplete
      // set editing step to details so that the navigation bar and summary
      // update accordingly
      const nextStep = getNextReorderStep();
      if (nextStep === "details" && registered)
        dispatch(reorderActions.setEditingStep("details"));
      return;
    }

    setShouldRender(willRedirect);

    // When a registered user comes from editing details, the navbar
    // updates after the confirmation step is mounted and it throws an error
    const adjustNavbar = () => {
      try {
        adjustProcessNavigationScroll("confirmation");
      } catch (ex) {
        setTimeout(adjustNavbar, 5);
      }
    };

    adjustNavbar();
    dispatch(reorderActions.setEditingStep(""));
  }, [urlArea, dispatch, registered]);

  return !shouldRender ? (
    <Loader />
  ) : (
    <div className="confirmation-step">
      <div className="my-container">
        <div className="steps-preview">
          <div className="heading blue-Bold-20px-left">{t("confirmation_heading")}</div>
          <div
            className="row preview-columns"
            style={{ width: "calc(100% - (376px - ((100vw - 1291px) / 2)))" }}
          >
            <DetailsPreview />
            {/*<PlanPreview*/}
            {/*  editRedirect={routes[urlArea].designs}*/}
            {/*  plan={plan}*/}
            {/*  country={country_code}*/}
            {/*  setEditingStep={() =>*/}
            {/*    dispatch(reorderActions.setEditingStep("designs"))*/}
            {/*  }*/}
            {/*/>*/}
            <DesignsPreview
              editRedirect={routes[urlArea].designs}
              quantities={designs}
              bag_logo={bag_logo}
              country={country_code}
              plan={plan}
              setEditingStep={() =>
                dispatch(reorderActions.setEditingStep("designs"))
              }
            />
          </div>
        </div>
      </div>
      {displayError && (
        <InfoMessage title={displayError.title} text={displayError.text}>
          <div
            className="btn btn-primary bulky dismiss-validation-message"
            onClick={() =>
              dispatch(layoutActions.toggleErrorPopup("confirmation", false))
            }
          >
            {t("dismiss_error")}
          </div>
        </InfoMessage>
      )}
      {privacyOpened && <PrivacyPolicyPopup />}
      {termsOpened && <TermsAndConditionsPopup />}
    </div>
  );
};
