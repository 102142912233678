import React from "react";
import { useTranslation } from "react-i18next";
import PreviewSection from "./PreviewSection";

export default ({ contact_info, redirectToStep, editEnabled }) => {
  const { t } = useTranslation("reorder");

  return (
    <PreviewSection
      editEnabled={editEnabled}
      heading={t("contact_info")}
      link={redirectToStep}
      className="contact-info-section"
      name="contact"
    >
      <div className="info-row">
        <div className="blue-Bold-14px text-left">{t("company")}</div>
        <div className="blue-Regular-14px company">{contact_info.company}</div>
      </div>
      <div className="info-row">
        <div className="blue-Bold-14px text-left">{t("contact")}</div>
        <div className="blue-Regular-14px contact">{contact_info.contact}</div>
      </div>
      <div className="info-row">
        <div className="blue-Bold-14px text-left">{t("email")}</div>
        <div className="blue-Regular-14px email">{contact_info.email}</div>
      </div>
      <div className="info-row">
        <div className="blue-Bold-14px text-left">{t("phone")}</div>
        <div className="blue-Regular-14px phone">
          {contact_info.phone || "-"}
        </div>
      </div>
      <div className="info-row">
        <div className="blue-Bold-14px text-left">{t("vat")}</div>
        <div className="blue-Regular-14px vat">
          {contact_info.vat_number || "-"}
        </div>
      </div>
    </PreviewSection>
  );
};
