import React from "react";
import { navigate } from "./CustomBrowserRouter";
import routes from "../../../constants/routes";

export default () => {
  React.useEffect(() => {
    navigate(routes.index);
  }, []);

  return null;
};
