import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import ManualShopForm from "./ManualShopForm";
import GoogleShopForm from "./GoogleShopForm";

import { adjustProcessNavigationScroll } from "../../../helpers/ProcessHelpers";

import "../../../stylesheets/register/details.scss";
import layoutActions from "../../../actions/layout";
import InfoMessage from "../../shared/InfoMessage";

class ShopDetailsStep extends Component {
  componentDidMount = () => adjustProcessNavigationScroll("details");

  render = () => {
    let { entryType, t, displayError } = this.props;

    return (
      <div className="shop-details-step">
        <div className="my-container">
          {entryType === "manual" && <ManualShopForm />}
          {entryType === "google" && <GoogleShopForm />}
        </div>
        {this.props.displayError && (
          <InfoMessage title={displayError.title} text={displayError.text}>
            <div
              className="btn btn-primary bulky dismiss-validation-message"
              onClick={() => this.props.toggleError(false)}
            >
              {t("summary:dismiss_error")}
            </div>
          </InfoMessage>
        )}
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  entryType: state.register.entry_type,
  editingDetails: state.register.editing_step === "details",
  displayError: state.layout.error_popups.details,
});

const mapDispatchToProps = (dispatch) => ({
  toggleError: (toggled) =>
    dispatch(layoutActions.toggleErrorPopup("details", toggled)),
});

const Reduxified = connect(
  mapStateToProps,
  mapDispatchToProps
)(ShopDetailsStep);

export default withTranslation()(Reduxified);
