import ProgressAPIService from "../network/ProgressAPIService";
import reduxStore from "../../config/store";
import registerActions from "../../actions/register";

class ProgressService {
  /**
   * Leads a place by id that the user selected from the Google Places' API
   * suggestions.
   * @param {string} place_id
   * @return {Promise<{success: boolean}>}
   */
  static async leadPlace(place_id) {
    return await ProgressAPIService.leadPlace(place_id);
  }

  /**
   * Leads a customer that clicked on a order link that includes Google Place Id
   * suggestions.
   * @param {string} place_id
   * @return {Promise<{success: boolean}>}
   */
  static async leadCustomer(place_id) {
    return await ProgressAPIService.leadCustomer(place_id);
  }

  /**
   * Leads a shop with the info that was filled about it in step 1.
   * Gather all the needed information from the redux store and
   * send a request to the backend API.
   * @return {Promise<{success: boolean}>}
   */
  static async leadShop() {
    const {
      contact_info,
      shop_details,
      coupon_options,
      entry_type,
    } = reduxStore.getState().register;
    let body = {
      email: contact_info.email,
      company_name: contact_info.company,
      contact_name: contact_info.contact,
      offers_discount: coupon_options.offers_discount,
      offers_project_tree: coupon_options.offers_project_tree,
      offers_project_ocean: coupon_options.offers_project_ocean,
      discount: coupon_options.custom_text || coupon_options.discount_text,
    };

    if (entry_type === "google") body.place_id = shop_details.place_id;

    let result = await ProgressAPIService.leadShop(body);
    // If the shop has been leaded successfully into our db,
    // mark it as such in the redux store so that it doesn't get leaded
    // again on shop details edit.
    if (result.success) reduxStore.dispatch(registerActions.markShopLeaded());

    return result;
  }
}

export default ProgressService;
