import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useAsyncState } from "../../../utilities/hooks";
import { useIntl } from "react-intl";
import { countries, currency_rates } from "../../../constants/internationals";
import { shipping_prices } from "../../../constants/prices";

import { calculateShippingPrice } from "../../../helpers/ProcessHelpers";
import {
  getCountryName,
  isFromInternationalZone,
} from "../../../helpers/LocationHelpers";
import LocationAPIService from "../../../services/network/LocationAPIService";
import layoutActions from "../../../actions/layout";

import { ScaleIn } from "../../hocs/AnimationHOC";
import { IMaskInput } from "react-imask";
import DropdownSearch from "../../register/details/DropdownSearch";

import "../../../stylesheets/reorder/shipping_calculator.scss";

const SHIPPING_MESSAGE_QUANTITY_TRIGGER = 2500;
const COUNTRY_CODE_FALLBACK = "AT";

export default () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { t, i18n: { language } } = useTranslation("reorder");
  const [clientCountry, setClientCountry] = useAsyncState(
    useSelector((s) => s.reorder.shipping_address.country_code)
  );
  const [selectedCountry, selectCountry] = React.useState(clientCountry);
  const [isFocused, setFocused] = React.useState(false);
  const [quantity, setQuantity] = React.useState("");
  const [search, setSearch] = React.useState("");
  const [result, setResult] = React.useState({ cost: 0, boxes: 0 });

  const currency = currency_rates[clientCountry] || currency_rates.default;
  const countriesList = shipping_prices.eligibleCountries.map((code) => ({
    code,
    name: countries.find((c) => c.code === code).name,
  }));

  const inputRef = React.createRef(null);

  React.useEffect(() => {
    // Fetch client country
    (async () => {
      const { success, data } = await LocationAPIService.getUserCountry();
      if (
        success &&
        shipping_prices.eligibleCountries.includes(data.country_code)
      )
        return setClientCountry(data.country_code);

      // Handle user country that's not contained in the eligible for shipping countries list
      setClientCountry(COUNTRY_CODE_FALLBACK);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const close = () =>
    dispatch(layoutActions.togglePopup("shipping_calculator", false));

  const calculate = () =>
    setResult(
      calculateShippingPrice(
        parseInt(quantity),
        selectedCountry ? selectedCountry : clientCountry
      )
    );

  // Bags quantity change handler
  const handleQuantityChange = (val) => {
    if (!val) return;
    setQuantity(val);
  };

  // Country select input change handler
  const handleDropdownInputChange = ({ target }) => setSearch(target.value);

  // Country dropdown select handler
  const handleOptionSelect = (select, value) => {
    if (!value) return null;

    setSearch(getCountryName(value));
    selectCountry(value);
  };

  // Country select input focus handler
  const onFocus = () => {
    let searchValue = selectedCountry
      ? getCountryName(selectedCountry)
      : clientCountry
      ? getCountryName(clientCountry)
      : "";

    setSearch(searchValue);
    setFocused(true);
  };

  // Country select input blur handler
  const onBlur = ({ target }) => {
    // Clears current selection
    if (!target.value) selectCountry(clientCountry);

    let searchValue = clientCountry ? getCountryName(clientCountry) : "";
    setSearch(searchValue);
    setFocused(false);
  };

  const formatPrice = (price, decimals = 0) => {
    const formattedPrice = intl.formatNumber(price * currency.rate, {
      style: "currency",
      currency: currency.code,
      currencyDisplay: "symbol",
      maximumFractionDigits: decimals,
      minimumFractionDigits: decimals,
    });
    const delimiter = formattedPrice.includes(",") ? "." : ",";
    return `${formattedPrice}${delimiter}–`;
  };

  // Get price for package for a given range upper boundary
  const getRangePrice = (range) => {
    const price = Object.entries(shipping_prices[range]).find(([price, list]) =>
      list.includes(selectedCountry ? selectedCountry : clientCountry)
    );
    return price ? price[0] : 0;
  };

  // The country select input value
  let countryValue = isFocused
    ? search
    : selectedCountry || clientCountry
    ? getCountryName(selectedCountry ? selectedCountry : clientCountry)
    : "";

  let showCustomSolutionMessage =
    result ?
    (isFromInternationalZone(selectedCountry) &&
    parseInt(quantity) >= SHIPPING_MESSAGE_QUANTITY_TRIGGER) : true;

  return (
    <div className="shipping-calculator">
      <ScaleIn
        className={`shipping-calculator-container ${
          showCustomSolutionMessage ? "international" : ""
        } ${language}`}
      >
        <div className="close" onClick={close}>
          <img src="/images/assets/close.svg" alt="x" />
        </div>
        <div className="header">
          <h5 className="blue-Bold-20px-left">
            {t("shipping_calculator_header")}
          </h5>
          <DropdownSearch
            required={true}
            search={countryValue}
            selectedValue={selectedCountry}
            className={`country-select ${isFocused ? "focused" : ""}`}
            name="country"
            placeholder={t("shop_details_country")}
            errorMessage={t("shop_details_error_country")}
            onFocus={onFocus}
            onBlur={onBlur}
            collection={countriesList}
            handleOptionSelect={handleOptionSelect}
            handleInputChange={handleDropdownInputChange}
          />
          <div className="calc-input d-flex justify-content-between align-items-center">
            <div
              className="input blue-SemiBold-15px"
              onClick={() => inputRef.current.element.focus()}
            >
              <IMaskInput
                ref={inputRef}
                mask={(val) => val[0] !== "0" && !!val.match(/^[\d+\d?]{0,7}$/)}
                style={{ minWidth: quantity.length * 9 }}
                value={quantity}
                onAccept={handleQuantityChange}
                onChange={({ target }) => !target.value && setQuantity("")}
                placeholder="0"
              />
              {" " + t("bags_quantity")}
            </div>
            <button className="btn btn-primary" onClick={calculate}>
              {t("calculate")}
            </button>
          </div>
          <div className="result">
            <span className="blue-Bold-14px">{t("shipping_costs")}</span>{" "}
            <span className="blue-Regular-14px">
              {formatPrice(result?.cost || 0)}
            </span>
          </div>
          <div
            className="blue-Regular-14px"
            style={{ display: result && result?.boxes > 0 ? "block" : "none" }}
          >
            {t("boxes_amount", { number: result?.boxes })}
          </div>
        </div>
        <div
          className={`international-zone-message ${
            showCustomSolutionMessage ? "" : "hidden"
          }`}
        >
          <img src="/images/assets/info-white.svg" alt="?" />
          <p className="white-SemiBold-14px">
            {t("international_shipping_info")}
          </p>
        </div>
        <div className="pricing-table">
          <div className="blue-Bold-14px text-left">
            {t("shipping_cost_table_header", {
              country: getCountryName(
                selectedCountry ? selectedCountry : clientCountry
              ),
            })}
          </div>
          <div className="table-content">
            <div className="table-row table-heading">
              <div className="col-6">
                <div className="blue-Regular-14px text-center">
                  {t("number_of_bags")}
                </div>
              </div>
              <div className="col-6">
                <div className="blue-Regular-14px text-center">
                  {t("price_per_box")}
                </div>
              </div>
            </div>
            <div className="blue-SemiBold-14px-center">
              <div className="table-row">
                <div className="col-6">1-20</div>
                <div className="col-6">{formatPrice(getRangePrice(20))}</div>
              </div>
              <div className="table-row">
                <div className="col-6">21-50</div>
                <div className="col-6">{formatPrice(getRangePrice(50))}</div>
              </div>
              <div className="table-row">
                <div className="col-6">51-80</div>
                <div className="col-6">{formatPrice(getRangePrice(80))}</div>
              </div>
              <div className="table-row">
                <div className="col-6">81-100</div>
                <div className="col-6">{formatPrice(getRangePrice(100))}</div>
              </div>
            </div>
          </div>
          <div className="blue-Regular-14px footer">
            {t("shipping_calculator_footer")}
          </div>
        </div>
      </ScaleIn>
    </div>
  );
};
