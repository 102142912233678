export default {
  MARK_SHOP_LEADED: "MARK_SHOP_LEADED",
  SELECT_ENTRY_TYPE: "SELECT_ENTRY_TYPE",
  SELECT_DISCOUNT_TEXT: "SELECT_DISCOUNT_TEXT",
  SELECT_PLAN: "SELECT_PLAN",
  HANDLE_INPUT_CHANGE: "HANDLE_INPUT_CHANGE",
  HANDLE_DROPDOWN_SELECT: "HANDLE_DROPDOWN_SELECT",
  HANDLE_QUANTITY_CHANGE: "HANDLE_QUANTITY_CHANGE",
  SAVE_LOGO: "SAVE_LOGO",
  SAVE_CUSTOM_BAG: "SAVE_CUSTOM_BAG",
  SAVE_CUSTOM_DISCOUNT_TEXT: "SAVE_CUSTOM_DISCOUNT_TEXT",
  SAVE_OPENING_HOURS: "SAVE_OPENING_HOURS",
  SAVE_GOOGLE_SHOP_DETAILS: "SAVE_GOOGLE_SHOP_DETAILS",
  TOGGLE_COUPON_OPTION: "TOGGLE_COUPON_OPTION",
  SET_EDITING_STEP: "SET_EDITING_STEP",
  UPDATE_BAG_LOGO_PARENT: "UPDATE_BAG_LOGO_PARENT",
  RESET_REGISTER: "RESET_PROCESS",
};
