import React from "react";
import { useIntl } from "react-intl";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import plans from "../../../constants/plans";
import { countries, currency_rates } from "../../../constants/internationals";

//import Plan from "./Plan";
//import { Swipeable } from "react-swipeable";

export default () => {
  const intl = useIntl();
  const { t } = useTranslation("reorder");
  const {
    current_plan,
    // selected_plan,
    contact_info,
    shipping_address,
    first_order,
    //preselected_plan,
  } = useSelector((state) => state.reorder);

  //const [benefitsToggled, toggleBenefits] = React.useState(false);
  //const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  //const [plansMounted, setPlansMounted] = React.useState(false);

  // Attach window resize listener so that the plan select remains responsive no matter what
  // React.useEffect(() => {
  //   const handleResize = () => {
  //     setWindowWidth((prevWindowWidth) => {
  //       // If resizing from <992px to >992px set slide to 0 so that the container is scrollable.
  //       // ( no scrollbar will appear if the inner container does NOT overflow on the right sight of the parent container)
  //       if (prevWindowWidth <= 991 && window.innerWidth > 991)
  //         setPlanPreviewed(0);
  //       return window.innerWidth;
  //     });
  //   };
  //
  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

  //let container = React.useRef(null);
  //let plan = React.useRef(null);

  let currency =
    currency_rates[shipping_address.country_code] || currency_rates.default;

  const formatPrice = (price, decimals = 0) =>
    intl.formatNumber(price * currency.rate, {
      style: "currency",
      currency: currency.code,
      currencyDisplay: "symbol",
      maximumFractionDigits: decimals,
      minimumFractionDigits: decimals,
    });

  // Excludes all plans that are lower than the current one
  // const plansToRender = Object.fromEntries(
  //   Object.entries(plans).filter(([plan, details]) => {
  //     // Hide the standard paid version of the preselected promotional plan.
  //     if (`${plan}_free` === preselected_plan) return false;
  //     // If a plan is promotional and is not preselected, hide it by default.
  //     if (details.promotional)
  //       return preselected_plan ? preselected_plan === plan : false;
  //     // Return only the current plan and the ones after it.
  //     return (
  //       Object.keys(plans).indexOf(plan) >=
  //       Object.keys(plans).indexOf(current_plan.name)
  //     );
  //   })
  // );

  // const [planPreviewed, setPlanPreviewed] = React.useState(
  //   Object.keys(plansToRender).indexOf(
  //     preselected_plan || current_plan.name || selected_plan
  //   )
  // );

  // const handleSwipe = ({ dir: direction }) => {
  //   switch (direction) {
  //     case "Left": {
  //       if (planPreviewed < Object.keys(plansToRender).length - 1)
  //         setPlanPreviewed((prev) => prev + 1);
  //       break;
  //     }
  //     case "Right": {
  //       if (planPreviewed > 0) setPlanPreviewed((prev) => prev - 1);
  //       break;
  //     }
  //     default:
  //       break;
  //   }
  // };

  // Here the plans container width and left margin are calculated
  // const [
  //   containerWidth,
  //   planWidth,
  //   planSelectOffset,
  //   navigationDisabled,
  // ] = React.useMemo(() => {
  //   // TL;DR - expanded option is 305px wide; folded one - 292px and
  //   // on screens narrower than 350px it is {window width} - 48px (2 x 24px margin)
  //   let planWidth =
  //     windowWidth < 350 ? windowWidth - 48 : benefitsToggled ? 305 : 292;
  //   // The plans container element width
  //   let plansWidth =
  //     planWidth * Object.keys(plansToRender).length +
  //     (Object.keys(plansToRender).length - 1) * (windowWidth <= 1119 ? 15 : 35);
  //   // The parent element of the plans container
  //   let containerWidth = container.current
  //     ? container.current.getBoundingClientRect().width
  //     : plansWidth;
  //
  //   let navigationDisabled = containerWidth >= plansWidth;
  //
  //   let planSelectOffset;
  //   if (navigationDisabled) planSelectOffset = 0;
  //   else
  //     planSelectOffset =
  //       planPreviewed === 0 // is first
  //         ? 0
  //         : planPreviewed === Object.keys(plansToRender).length - 1 // is last
  //         ? navigationDisabled
  //           ? 0
  //           : containerWidth - plansWidth + (benefitsToggled ? 0 : 0)
  //         : (containerWidth - plansWidth) / 2 + (benefitsToggled ? 0 : 0);
  //
  //   return [plansWidth, planWidth, planSelectOffset, navigationDisabled];
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [
  //   plansToRender,
  //   planPreviewed,
  //   windowWidth,
  //   benefitsToggled,
  //   plansMounted,
  // ]);

  return (
    <div className="plan-select">
      <div className="my-container">
        <div className="plan-select-inner-container">
          {current_plan.name && !first_order && (
            <div className="current-plan-details">
              <h3 className="blue-SemiBold-18px-left mb-0">
                {contact_info.company}
                {shipping_address.country_code &&
                  `, ${shipping_address.city}, 
            ${
              countries.find((c) => c.code === shipping_address.country_code)
                .name
            }`}
              </h3>
              {current_plan.name === "premium" ? (
                <p className="blue-Regular-12px-left">
                  <span className="blue-Bold-12px">
                    {plans.premium.display_name} {t("plan")}
                  </span>
                  <br />
                  {formatPrice(plans.premium.price) + t("price_per_year")},{" "}
                  {t("price_per_bag", {
                    bag_price: formatPrice(plans.premium.bag_price),
                  })}
                  <br />
                  {t("valid_until", { date: current_plan.valid_until })}
                </p>
              ) : (
                <p className="blue-Regular-12px-left">
                  {plans[current_plan.name].display_name} {t("plan")}
                  {current_plan.name !== "ondemand" &&
                    " " + t("valid_until", { date: current_plan.valid_until })}
                </p>
              )}
            </div>
          )}
          <div className="greeting">
            <div className="greeting-container">
              <h4 className="blue-Bold-20px-left">
                {t(
                  `general:${
                    first_order
                      ? "greeting_first_order_title"
                      : "greeting_reorder_title"
                  }`
                )}
              </h4>
              <p className="blue-Regular-14px">
                {t(
                  `general:${
                    first_order
                      ? "greeting_first_order_text"
                      : "greeting_reorder_text"
                  }`
                )}
              </p>
            </div>
          </div>
          <div className="hint">
            <div className="hint-container">
              <img src="/images/plan/hint.png" alt="" className="hint-img" />
              <div className="hint-content">
                <div className="white-ExtraBold-18px title">
                  {t("hint_title")}
                </div>
                <div className="white-SemiBold-14px text">{t("hint_text")}</div>
              </div>
            </div>
          </div>
          {/*{current_plan.name !== "premium" && (*/}
          {/*  <>*/}
          {/*    <h4 className="blue-Bold-20px-left">{t("subscription_plan")}</h4>*/}
          {/*    <div*/}
          {/*      className={`plans-navigation ${*/}
          {/*        benefitsToggled && !navigationDisabled ? "toggled" : ""*/}
          {/*      }`}*/}
          {/*    >*/}
          {/*      {Object.keys(plansToRender).map((plan) => (*/}
          {/*        <div*/}
          {/*          key={plan}*/}
          {/*          className={`btn btn-${*/}
          {/*            planPreviewed === Object.keys(plansToRender).indexOf(plan)*/}
          {/*              ? "secondary"*/}
          {/*              : "light"*/}
          {/*          }`}*/}
          {/*          onClick={() =>*/}
          {/*            setPlanPreviewed(Object.keys(plansToRender).indexOf(plan))*/}
          {/*          }*/}
          {/*        >*/}
          {/*          {t(`${plan}_name`)}*/}
          {/*        </div>*/}
          {/*      ))}*/}
          {/*    </div>*/}
          {/*    <div*/}
          {/*      className={`plans ${benefitsToggled ? "expanded" : ""}`}*/}
          {/*      ref={container}*/}
          {/*      onLoad={() => !plansMounted && setPlansMounted(true)}*/}
          {/*    >*/}
          {/*      <Swipeable*/}
          {/*        onSwiped={handleSwipe}*/}
          {/*        className={`plans-container`}*/}
          {/*        style={{*/}
          {/*          marginLeft: planSelectOffset,*/}
          {/*          width: containerWidth,*/}
          {/*        }}*/}
          {/*      >*/}
          {/*        {Object.entries(plansToRender).map(*/}
          {/*          ([name, details], index) => (*/}
          {/*            <Plan*/}
          {/*              // Get ref for the 1st plan only since they all are identical*/}
          {/*              ref={index === 0 ? plan : null}*/}
          {/*              name={name}*/}
          {/*              details={details}*/}
          {/*              style={{ width: planWidth }}*/}
          {/*              key={name}*/}
          {/*              benefitsToggled={benefitsToggled}*/}
          {/*              toggleBenefits={toggleBenefits}*/}
          {/*              toggled={*/}
          {/*                (!planPreviewed && name === "ondemand") ||*/}
          {/*                planPreviewed ===*/}
          {/*                  Object.keys(plansToRender).indexOf(name)*/}
          {/*                  ? "active"*/}
          {/*                  : ""*/}
          {/*              }*/}
          {/*            />*/}
          {/*          )*/}
          {/*        )}*/}
          {/*      </Swipeable>*/}
          {/*      {current_plan.name !== "premium" && (*/}
          {/*        <div className="plans-nav">*/}
          {/*          {Object.keys(plansToRender).map((plan) => (*/}
          {/*            <div*/}
          {/*              key={plan}*/}
          {/*              className={`nav-btn ${*/}
          {/*                planPreviewed ===*/}
          {/*                Object.keys(plansToRender).indexOf(plan)*/}
          {/*                  ? "active"*/}
          {/*                  : ""*/}
          {/*              }`}*/}
          {/*              onClick={() =>*/}
          {/*                setPlanPreviewed(*/}
          {/*                  Object.keys(plansToRender).indexOf(plan)*/}
          {/*                )*/}
          {/*              }*/}
          {/*            >*/}
          {/*              <div />*/}
          {/*            </div>*/}
          {/*          ))}*/}
          {/*        </div>*/}
          {/*      )}*/}
          {/*    </div>*/}
          {/*  </>*/}
          {/*)}*/}
        </div>
      </div>
    </div>
  );
};
