import React, { Component } from "react";

import { injectIntl } from "react-intl";
import { withTranslation } from "react-i18next";

import plans from "../../../constants/plans";
import { currency_rates } from "../../../constants/internationals";

import { connect } from "react-redux";
import registerActions from "../../../actions/register";

import PlanOption from "./PlanOption";

import { scrollToElement } from "../../../utilities/scroll";

class Plans extends Component {
  state = {
    selected_preview: "ondemand",
  };

  selectPreview = (plan) => this.setState({ selected_preview: plan });

  selectPlan = (plan) => {
    this.props.selectPlan(plan);
    if (window.innerWidth <= 780 && plan) {
      scrollToElement(".mobile-summary", 1000, -150);
    }
  };

  renderPlanSelectButton = (plan) => {
    let { selectedPlan, t } = this.props;
    let className = "btn btn-primary bulky select-plan-btn ";
    if (plan === selectedPlan) className += "selected";

    return (
      <div
        className={className}
        onClick={() => this.selectPlan(plan === selectedPlan ? "" : plan)}
        dangerouslySetInnerHTML={{
          __html:
            plan === selectedPlan
              ? t("plan:selected_plan_action")
              : t(`plan:${plan}_action`),
        }}
      />
    );
  };

  render = () => {
    let { t, country, intl } = this.props;
    let preview = this.state.selected_preview;

    let currency = currency_rates[country] || currency_rates.default;
    let formatCurrency = (number, decimals = 0) =>
      intl.formatNumber(number * currency.rate, {
        style: "currency",
        currency: currency.code,
        currencyDisplay: "symbol",
        maximumFractionDigits: decimals,
        minimumFractionDigits: decimals,
      });
    let getPlanPriceStr = (plan) => formatCurrency(plans[plan].price);

    return (
      <div className="plan-select">
        <div className="plans">
          <div className="plans-navigation">
            <div
              className={`btn btn-${
                preview === "ondemand" ? "secondary" : "light"
              }`}
              onClick={() => this.selectPreview("ondemand")}
            >
              {t("plan:ondemand_name")}
            </div>
            <div
              className={`btn btn-${
                preview === "standard" ? "secondary" : "light"
              } ml-1 mr-1`}
              onClick={() => this.selectPreview("standard")}
            >
              {t("plan:standard_name")}
            </div>
            <div
              className={`btn btn-${
                preview === "premium" ? "secondary" : "light"
              }`}
              onClick={() => this.selectPreview("premium")}
            >
              {t("plan:premium_name")}
            </div>
          </div>
          <div className="plans-container">
            <div
              className={`plan-option ${
                preview === "ondemand" ? "selected" : ""
              }`}
            >
              <PlanOption
                super_text={t("plan:ondemand_super_text")}
                name="ondemand"
                price={t("plan:ondemand_price")}
                bag_price={formatCurrency(plans.ondemand.bag_price, 2)}
                cup={formatCurrency(plans.ondemand.cup_price, 2)}
              >
                {this.renderPlanSelectButton("ondemand")}
              </PlanOption>
            </div>
            <div
              className={`plan-option ${
                preview === "standard" ? "selected" : ""
              }`}
            >
              <PlanOption
                name="standard"
                price={getPlanPriceStr("standard") + t("plan:price_per_year")}
                price_cups={getPlanPriceStr("standard") + t("plan:price_per_year")}
                free_bags={plans.standard.free_bags}
                bag_price={formatCurrency(plans.standard.bag_price, 2)}
                stand_price={formatCurrency(plans.standard.stand_price)}
              >
                {this.renderPlanSelectButton("standard")}
                <div className="in-demand-sticker">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/register/in-demand-sticker.png`}
                    alt=""
                  />
                </div>
              </PlanOption>
            </div>
            <div
              className={`plan-option ${
                preview === "premium" ? "selected" : ""
              }`}
            >
              <PlanOption
                color="orange"
                super_text={t("plan:premium_super_text")}
                name="premium"
                price={getPlanPriceStr("premium") + t("plan:price_per_year")}
                bag_price={formatCurrency(plans.premium.bag_price, 2)}
                free_bags={plans.premium.free_bags}
              >
                {this.renderPlanSelectButton("premium")}
                <div className="best-value-sticker">
                  <img
                    src={`${process.env.PUBLIC_URL}/images/register/best-value-sticker.png`}
                    alt=""
                  />
                </div>
              </PlanOption>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  selectedPlan: state.register.plan,
  country: state.register.shop_details.country,
  city: state.register.shop_details.city,
});

const mapDispatchToProps = (dispatch) => ({
  selectPlan: (plan) => dispatch(registerActions.selectPlan(plan)),
});

const Reduxified = connect(mapStateToProps, mapDispatchToProps)(Plans);
const WithIntl = injectIntl(Reduxified);

export default withTranslation()(WithIntl);
