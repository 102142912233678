import React from "react";

import { withTranslation } from "react-i18next";

import "../../../utilities/extensions";

import { connect } from "react-redux";
import plans from "../../../constants/plans";
import { currency_rates } from "../../../constants/internationals";
import { injectIntl } from "react-intl";

// Represents a bullet point from the "You chose the
// {x} plan which includes:" list.
const InfoRow = ({ children }) => (
  <div className="info-row">
    <img
      src={`${process.env.PUBLIC_URL}/images/assets/check-green.svg`}
      alt=">"
    />
    {children}
  </div>
);

const PlanOfferings = ({ plan, t, country, intl }) => {
  let currency = currency_rates[country] || currency_rates.default;
  // Format number to currency.
  // Two decimal symbols after the decimal point will be displayed.
  let formatCurrency = (number) =>
    intl.formatNumber(number * currency.rate, {
      style: "currency",
      currency: currency.code,
      currencyDisplay: "symbol",
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });

  return (
    <div className="order-additional-bags">
      <div className="blue-Bold-20px-left heading">{t("designs:heading")}</div>
      <div className="blue-Regular-16px-left">
        <div className="info-row">
          {t("designs:selected_plan", {
            plan:
              plan === "ondemand" ? t("plan:ondemand_name") : plan.capitalize(),
          })}
        </div>
        <InfoRow>
          {plan === "ondemand"
            ? t("designs:pay_as_you_go", {
                bag_price: formatCurrency(plans.ondemand.bag_price),
              })
            : t("plan:includes_free_bags", { amount: plans[plan].free_bags })}
        </InfoRow>
        {/* If the user selected the Premium plan display "Free cardboard stand" bullet point */}
        {plan === "premium" && <InfoRow>{t("designs:free_cardboard")}</InfoRow>}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  plan: state.register.plan,
  country: state.register.shop_details.country,
});

const Reduxified = connect(mapStateToProps, null)(PlanOfferings);
const WithIntl = injectIntl(Reduxified);

export default withTranslation()(WithIntl);
