import { plainRequest } from "../../utilities/request";
import {
  SUBMIT_REGISTER_URL,
  SUBMIT_REORDER_URL,
} from "../../constants/endpoints";

/**
 * This service is responsible for handling the order requests to the API.
 */
class OrderAPIService {
  /**
   * Submit a register order request to the API.
   * Accepts the request body in the params argument.
   * @param {object} params
   * @return {Promise<{success: boolean}>}
   */
  static async submitRegister(params) {
    try {
      let { success } = await plainRequest(SUBMIT_REGISTER_URL, "POST", params);

      return { success };
    } catch (ex) {
      return { success: false };
    }
  }

  /**
   * Submit a reorder request to the API.
   * Accepts the request body in the params argument.
   * @param {object} params
   * @param {string|null} token
   * @return {Promise<{success: boolean}>}
   */
  static async submitReorder(params, token) {
    try {
      let headers = {};
      if (token) headers.Authorization = token;

      return await plainRequest(SUBMIT_REORDER_URL, "POST", params, headers);
    } catch (ex) {
      return { success: false };
    }
  }
}

export default OrderAPIService;
