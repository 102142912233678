import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import { connect } from "react-redux";
import registerActions from "../../../actions/register";

import Input from "../../shared/forms/Input";
import TextArea from "../../shared/forms/TextArea";

class ShopDetails extends Component {
  state = {
    toggled: true,
  };

  onChange = (ev) => {
    let { name, value } = ev.target;
    this.props.handleInputChange(name, value);
  };

  toggle = () => this.setState((state) => ({ toggled: !state.toggled }));

  render = () => {
    let { details, t } = this.props;
    let chevronDirection = this.state.toggled ? "up" : "down";
    let isToggled = this.state.toggled ? "toggled" : "";

    return (
      <div className={`shop-details-container ${isToggled}`}>
        <div className="details-heading" onClick={this.toggle}>
          <h4 className="blue-Bold-20px-left">
            {t("details:shop_details_heading")}
          </h4>
          <i className={`fas fa-chevron-${chevronDirection}`} />
        </div>
        <div className="inputs-wrapper">
          <div className="form-group">
            <Input
              name="name"
              required
              value={details.name}
              placeholder={t("details:shop_details_name")}
              errorMessage={t("details:shop_details_error_name")}
              onChange={this.onChange}
            />
          </div>
          <div className="form-group">
            <TextArea
              name="description"
              placeholder={t("details:shop_details_description")}
              noValidation
              hasCounter
              value={details.description}
              onChange={this.onChange}
              maxLength={150}
            />
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  details: state.register.shop_details,
});

const mapDispatchToProps = (dispatch) => ({
  handleInputChange: (name, value) =>
    dispatch(registerActions.handleInputChange("shop_details", name, value)),
});

let reduxWrapped = connect(mapStateToProps, mapDispatchToProps)(ShopDetails);
export default withTranslation()(reduxWrapped);
