import React from "react";
import { withTranslation } from "react-i18next";

const ContactUs = ({ heading, text, action, onClick }) => (
  <div className="contact-us">
    <div className="text-center">
      <div className="blue-Bold-30px-center heading">{heading}</div>
      <div className="blue-Regular-18px text">{text}</div>
      <div className="btn btn-primary bulky action" onClick={onClick}>
        {action}
      </div>
    </div>
  </div>
);

export default withTranslation()(ContactUs);
