import { post } from "../../utilities/request";
import { SEND_MESSAGE_URL } from "../../constants/endpoints";

/**
 * This service is responsible for handling contact form requests to the API.
 */
class ContactAPIService {
  /**
   * Sends a request with the contact info and message to the backend API.
   * Accepts the request body in the params argument.
   * @param params
   * @return {Promise<{success: boolean}>}
   */
  static async sendMessage(params) {
    try {
      let { success } = await post(SEND_MESSAGE_URL, params);
      return { success };
    } catch (ex) {
      return { success: false };
    }
  }
}

export default ContactAPIService;
