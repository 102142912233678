import React, { Component } from "react";

import { connect } from "react-redux";
import registerActions from "../../../actions/register";

import { validateShopDetails } from "../../../helpers/ProcessHelpers";

import { navigate } from "../../shared/routes/CustomBrowserRouter";
import routes from "../../../constants/routes";

import ContactInfo from "./ContactInfo";
import ShopDetails from "./ShopDetails";
import CouponsOffered from "./CouponsOffered";
import UploadLogo from "./UploadLogo";
import SocialMedia from "./SocialMedia";
import AppPreview from "../AppPreview";

class ManualShopForm extends Component {
  handleSubmit = (ev) => {
    ev.preventDefault();

    // Prevent opening hours modal form from submitting parent form.
    if (ev.target.id === "opening-hours-form") return;

    if (validateShopDetails(this.props.details))
      navigate(
        this.props.editing_details
          ? routes.register.confirmation
          : routes.register.plan
      );
  };

  render = () => {
    const { contact_info } = this.props.details;

    return (
      <form
        className="manual-shop-form"
        id="shop-details-form"
        onSubmit={this.handleSubmit}
        style={{ width: "calc(100% - (376px - ((100vw - 1291px) / 2)))" }}
      >
        <div className="row">
          <div className="details-column">
            <ContactInfo
              details={contact_info}
              handleInputChange={this.props.handleInputChange}
            />
            <ShopDetails />
          </div>
          <div className="coupons-logo-column">
            <CouponsOffered />
            <UploadLogo />
            <SocialMedia />
          </div>
          <div className="app-preview-column">
            <AppPreview />
          </div>
        </div>
      </form>
    );
  };
}

const mapStateToProps = (state) => ({
  details: state.register,
  editing_details: state.register.editing_step === "details",
});

const mapDispatchToProps = (dispatch) => ({
  setEditingStep: (step) => dispatch(registerActions.setEditingStep(step)),
  handleInputChange: (name, value) =>
    dispatch(registerActions.handleInputChange("contact_info", name, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ManualShopForm);
