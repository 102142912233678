import actionTypes from "../constants/action_types/register";
import i18n from "../config/i18n";

import initializeOpeningHours from "../helpers/OpeningHoursHelpers";

export const initialState = {
  entry_type: "",
  shop_leaded: false,
  contact_info: {
    company: "",
    contact: "",
    email: "",
    phone: "",
    vat_number: "",
  },
  shop_details: {
    place_id: null,
    name: "",
    address: "",
    city: "",
    postcode: "",
    country: "",
    category: "",
    description: "",
    geo_lat: null,
    geo_lng: null,
  },
  coupon_options: {
    offers_project_tree: true,
    offers_project_ocean: true,
    offers_discount: true,
    discount_text: i18n.t("details:coupons_default_discount_text_3_percent"),
    custom_text: "",
  },
  logo: {
    url: "",
    file: null,
  },
  social_media: {
    website: "",
    facebook: "",
    instagram: "",
  },
  opening_hours: initializeOpeningHours(),
  plan: "",
  designs: {
    cups_large: {
    },
    medium: {
      custom: "",
    },
    large: {
      custom: "",
    },
  },
  bag_logo: {
    logo: {
      url: "",
      file: null,
    },
    position: {
      x: 0,
      y: 0,
    },
    size: {
      width: 130,
      height: 130,
    },
    parent: {
      width: 130,
      height: 130,
    },
  },
  editing_step: "",
};

export default function register(state = initialState, action) {
  switch (action.type) {
    case actionTypes.MARK_SHOP_LEADED: {
      return {
        ...state,
        shop_leaded: true,
      };
    }

    case actionTypes.SELECT_ENTRY_TYPE: {
      return {
        ...state,
        entry_type: action.entry_type,
      };
    }

    case actionTypes.SELECT_DISCOUNT_TEXT: {
      return {
        ...state,
        coupon_options: {
          ...state.coupon_options,
          custom_text: "",
          discount_text: action.discount_text,
        },
      };
    }

    case actionTypes.SELECT_PLAN: {
      return {
        ...state,
        plan: action.plan,
      };
    }

    case actionTypes.HANDLE_INPUT_CHANGE: {
      return {
        ...state,
        [action.section]: {
          ...state[action.section],
          [action.name]: action.value,
        },
      };
    }

    case actionTypes.HANDLE_DROPDOWN_SELECT: {
      return {
        ...state,
        shop_details: {
          ...state.shop_details,
          [action.dropdown]: action.value,
        },
      };
    }

    case actionTypes.HANDLE_QUANTITY_CHANGE: {
      return {
        ...state,
        designs: {
          ...state.designs,
          [action.size]: {
            ...state.designs[action.size],
            [action.design]: action.quantity,
          },
        },
      };
    }

    case actionTypes.SAVE_CUSTOM_DISCOUNT_TEXT: {
      return {
        ...state,
        coupon_options: {
          ...state.coupon_options,
          custom_text: action.custom_text,
        },
      };
    }

    case actionTypes.SAVE_LOGO: {
      return {
        ...state,
        logo: action.logo,
      };
    }

    case actionTypes.SAVE_CUSTOM_BAG: {
      return {
        ...state,
        bag_logo: action.details,
      };
    }

    case actionTypes.SAVE_OPENING_HOURS: {
      return {
        ...state,
        opening_hours: action.opening_hours,
      };
    }

    case actionTypes.SAVE_GOOGLE_SHOP_DETAILS: {
      let { opening_hours, ...details } = action.details;

      return {
        ...state,
        entry_type: "google",
        shop_details: {
          ...state.shop_details,
          ...details,
        },
        opening_hours: {
          ...state.opening_hours,
          ...opening_hours,
        },
      };
    }

    case actionTypes.TOGGLE_COUPON_OPTION: {
      return {
        ...state,
        coupon_options: {
          ...state.coupon_options,
          [action.option]:
            action.toggled || !state.coupon_options[action.option],
        },
      };
    }

    case actionTypes.SET_EDITING_STEP: {
      return {
        ...state,
        editing_step: action.editing_step,
      };
    }

    case actionTypes.UPDATE_BAG_LOGO_PARENT: {
      return {
        ...state,
        bag_logo: {
          ...state.bag_logo,
          parent: action.parent,
        },
      };
    }

    case actionTypes.RESET_REGISTER: {
      return initialState;
    }

    default:
      return state;
  }
}
