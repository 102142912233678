import actionTypes from "../constants/action_types/register";

export default {
  markShopLeaded: () => ({
    type: actionTypes.MARK_SHOP_LEADED,
  }),
  selectEntryType: (entry_type) => ({
    type: actionTypes.SELECT_ENTRY_TYPE,
    entry_type,
  }),
  selectDiscountText: (discount_text) => ({
    type: actionTypes.SELECT_DISCOUNT_TEXT,
    discount_text,
  }),
  selectPlan: (plan) => ({
    type: actionTypes.SELECT_PLAN,
    plan,
  }),
  handleInputChange: (section, name, value) => ({
    type: actionTypes.HANDLE_INPUT_CHANGE,
    section,
    name,
    value,
  }),
  handleDropdownSelect: (dropdown, value) => ({
    type: actionTypes.HANDLE_DROPDOWN_SELECT,
    dropdown,
    value,
  }),
  handleQuantityChange: (size, design, quantity) => ({
    type: actionTypes.HANDLE_QUANTITY_CHANGE,
    size,
    design,
    quantity,
  }),
  saveLogo: (logo) => ({
    type: actionTypes.SAVE_LOGO,
    logo,
  }),
  saveCustomBag: (details) => ({
    type: actionTypes.SAVE_CUSTOM_BAG,
    details,
  }),
  saveCustomDiscountText: (custom_text) => ({
    type: actionTypes.SAVE_CUSTOM_DISCOUNT_TEXT,
    custom_text,
  }),
  saveOpeningHours: (opening_hours) => ({
    type: actionTypes.SAVE_OPENING_HOURS,
    opening_hours,
  }),
  saveGoogleShopDetails: (details) => ({
    type: actionTypes.SAVE_GOOGLE_SHOP_DETAILS,
    details,
  }),
  toggleCouponOption: (option, toggled) => ({
    type: actionTypes.TOGGLE_COUPON_OPTION,
    option,
    toggled,
  }),
  setEditingStep: (editing_step) => ({
    type: actionTypes.SET_EDITING_STEP,
    editing_step,
  }),
  updateBagLogoParent: (parent) => ({
    type: actionTypes.UPDATE_BAG_LOGO_PARENT,
    parent,
  }),
  resetRegister: () => ({
    type: actionTypes.RESET_REGISTER,
  }),
};
