Object.filter = (obj, predicate) =>
  Object.keys(obj)
    .filter((key) => predicate(obj[key]))
    .reduce((res, key) => Object.assign(res, { [key]: obj[key] }), {});

/*eslint no-extend-native: ["error", { "exceptions": ["String"] }]*/
String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

/*eslint no-extend-native: ["error", { "exceptions": ["String"] }]*/
String.prototype.insert = function (index, string) {
  if (index > 0)
    return (
      this.substring(0, index) + string + this.substring(index, this.length)
    );

  return string + this;
};

/**
 * Compare 2 objects.
 * @param {object} x
 * @param {object} y
 * @return {boolean}
 */
export const object_equals = (x, y) => {
  // if both x and y are null or undefined and exactly the same
  if (x === y) return true;
  // if they are not strictly equal, they both need to be Objects
  if (!(x instanceof Object) || !(y instanceof Object)) return false;
  // they must have the exact same prototype chain, the closest we can do is
  // test there constructor.
  if (x.constructor !== y.constructor) return false;

  for (var p in x) {
    // other properties were tested using x.constructor === y.constructor
    if (!x.hasOwnProperty(p)) continue;
    // allows to compare x[ p ] and y[ p ] when set to undefined
    if (!y.hasOwnProperty(p)) return false;
    // if they have the same strict value or identity then they are equal
    if (x[p] === y[p]) continue;
    // Numbers, Strings, Functions, Booleans must be strictly equal
    if (typeof x[p] !== "object") return false;
    // Objects and Arrays must be tested recursively
    if (!object_equals(x[p], y[p])) return false;
  }
  // allows x[ p ] to be set to undefined
  for (p in y) if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) return false;

  return true;
};

export function cloneEvent(e) {
  if (e === undefined || e === null) return undefined;

  function ClonedEvent() {}

  let clone = new ClonedEvent();
  for (let p in e) {
    let d = Object.getOwnPropertyDescriptor(e, p);
    if (d && (d.get || d.set)) Object.defineProperty(clone, p, d);
    else clone[p] = e[p];
  }
  Object.setPrototypeOf(clone, e);

  return clone;
}
