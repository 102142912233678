import React from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { countries } from "../../../constants/internationals";
import reorderActions from "../../../actions/reorder";

import Input from "../../shared/forms/Input";
import DropdownSearch from "../../register/details/DropdownSearch";

export default ({ details, name, required, className }) => {
  const { t } = useTranslation("reorder");
  const [isFocused, setFocused] = React.useState(false);
  const [search, setSearch] = React.useState("");

  const dispatch = useDispatch();

  let countryValue = isFocused
    ? search
    : details.country_code
    ? countries.find((c) => c.code === details.country_code).name
    : "";

  const handleDropdownInputChange = ({ target }) => setSearch(target.value);

  const handleOptionSelect = (select, value) => {
    if (!value) return null;

    setSearch(countries.find((c) => c.code === value).name);
    dispatch(reorderActions.handleCountrySelect(name, value));
  };

  const handleChange = ({ target }) => {
    dispatch(reorderActions.handleInputChange(name, target.name, target.value));
  };

  const onFocus = () => {
    let searchValue = details.country_code
      ? countries.find((c) => c.code === details.country_code).name
      : "";

    setSearch(searchValue);
    setFocused(true);
  };

  const onBlur = ({ target }) => {
    // Clears current selection
    if (!target.value) dispatch(reorderActions.handleCountrySelect(name, ""));

    let searchValue = details.country_code
      ? countries.find((c) => c.code === details.country_code).name
      : "";
    setSearch(searchValue);
    setFocused(false);
  };
  // The panelName value from the "{panelName}_address" string that comes as a prop
  let panelName = name.match(/^(.*)_address$/)[1];

  return (
    <div className={`address-panel ${name} ${className}`}>
      <div className="form-group">
        <Input
          withLabel={true}
          name="contact_name"
          required={required}
          autocomplete={`${panelName} name`}
          value={details.contact_name}
          placeholder={t("recipient")}
          errorMessage={t("recipient_error")}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <Input
          withLabel={true}
          name="address"
          required={required}
          autocomplete={`${panelName} street-address`}
          value={details.address}
          placeholder={t("shop_details_address")}
          errorMessage={t("shop_details_error_address")}
          onChange={handleChange}
        />
      </div>
      <div className="form-group">
        <div className="row">
          <div className="col-xl-6 col-12 city-col">
            <Input
              name="city"
              withLabel={true}
              required={required}
              autocomplete={`${panelName} address-level2`}
              value={details.city}
              placeholder={t("shop_details_city")}
              errorMessage={t("shop_details_error_city")}
              onChange={handleChange}
            />
          </div>
          <div className="col-xl-6 col-12">
            <Input
              name="postcode"
              withLabel={true}
              required={required}
              autocomplete={`${panelName} postal-code`}
              value={details.postcode}
              placeholder={t("shop_details_postcode")}
              errorMessage={t("shop_details_error_postcode")}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className="form-group">
        <DropdownSearch
          required={required}
          withLabel={true}
          search={countryValue}
          selectedValue={details.country_code}
          className={`country-select ${isFocused ? "focused" : ""}`}
          name="country"
          placeholder={t("shop_details_country")}
          errorMessage={t("shop_details_error_country")}
          onFocus={onFocus}
          onBlur={onBlur}
          collection={countries}
          handleOptionSelect={handleOptionSelect}
          handleInputChange={handleDropdownInputChange}
        />
      </div>
    </div>
  );
};
