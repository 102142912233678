import React from "react";
import ReactDOM from "react-dom";
import { destroyServiceWorker } from "./serviceWorker";

import App from "./App";

import "./config/i18n";

import "./stylesheets/index.scss";

destroyServiceWorker();

ReactDOM.render(<App />, document.getElementById("app"));
