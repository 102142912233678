import React from "react";
import CONFIG from '../../config';
import { useTranslation } from "react-i18next";
import terms_texts from "./texts/terms_texts";

import "../../stylesheets/legal.scss";

export default () => {
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <div className="col-12 col-md-6 mx-auto py-5 legal terms-and-conditions">
      {terms_texts[language] || terms_texts[CONFIG.DEFAULT_LANGUAGE]}
    </div>
  );
};
