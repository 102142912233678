import React from "react";
import styled, { keyframes } from "styled-components";
import {
  bounceIn,
  bounceInLeft,
  fadeIn,
  slideInUp,
  slideInDown,
  slideInLeft,
  zoomIn,
} from "react-animations";

export const BounceIn = styled.div`
  animation: 0.6s ${keyframes`${bounceIn}`};
`;
export const BounceInLeft = styled.div`
  animation: 0.6s ${keyframes`${bounceInLeft}`};
`;
export const FadeIn = styled.div`
  animation: 0.6s ${keyframes`${fadeIn}`};
`;
export const SlideInUp = styled.div`
  animation: 0.6s ${keyframes`${slideInUp}`};
`;
export const SlideInDown = styled.div`
  animation: 0.6s ${keyframes`${slideInDown}`};
`;
export const SlideInLeft = styled.div`
  animation: 0.7s ${keyframes`${slideInLeft}`};
`;
export const ScaleIn = styled.div`
  animation: 0.25s ${keyframes`${zoomIn}`};
`;

export default function animateBounceInLeft(WrappedComponent) {
  return class extends React.Component {
    render() {
      return (
        <BounceInLeft className="animation-wrapper">
          <WrappedComponent {...this.props} />
        </BounceInLeft>
      );
    }
  };
}

export function animateFadeIn(WrappedComponent) {
  return class extends React.Component {
    render() {
      return (
        <FadeIn className="animation-wrapper">
          <WrappedComponent {...this.props} />
        </FadeIn>
      );
    }
  };
}

export function animateSlideInUp(WrappedComponent) {
  return class extends React.Component {
    render() {
      return (
        <SlideInUp className="animation-wrapper">
          <WrappedComponent {...this.props} />
        </SlideInUp>
      );
    }
  };
}

export function animateSlideInDown(WrappedComponent) {
  return class extends React.Component {
    render() {
      return (
        <SlideInDown className="animation-wrapper">
          <WrappedComponent {...this.props} />
        </SlideInDown>
      );
    }
  };
}
