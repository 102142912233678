import OrderAPIService from "../network/OrderAPIService";
import { getI18n } from "react-i18next";
import reduxStore from "../../config/store";
import { formatOpeningHours, getBagPrice, getTotalPrice } from "../../helpers/ProcessHelpers";
import { object_equals } from "../../utilities/extensions";
import designConfig from "../../constants/designs";
import plans from "../../constants/plans";
import { currency_rates } from "../../constants/internationals";

/**
 * This service handles the business logic behind the orders.
 */
class OrderService {
  /**
   * Constructs the request body for the register order request
   * and calls the submit register method from the API service.
   * @return {Promise<{success: boolean}>}
   */
  static async submitRegister() {
    const {
      shop_details,
      social_media,
      contact_info,
      coupon_options,
      designs,
      bag_logo,
      ...register
    } = reduxStore.getState().register;

    // Request body construction start
    const body = new FormData();

    // General info
    body.append("lang", getI18n().language.split("-")[0]);
    body.append("entry_type", register.entry_type);

    // Pure contact info
    body.append("contact_info[company]", contact_info.company);
    body.append("contact_info[contact]", contact_info.contact);
    body.append("contact_info[email]", contact_info.email);
    if (contact_info.phone)
      body.append("contact_info[phone]", contact_info.phone);
    if (contact_info.vat_number)
      body.append("contact_info[vat_number]", contact_info.vat_number);
    body.append("contact_info[plan]", register.plan);
    if (register.logo.file)
      body.append("contact_info[logo]", register.logo.file);

    // Pure shop details
    if (shop_details.place_id)
      body.append("shop_details[place_id]", shop_details.place_id);
    body.append("shop_details[name]", shop_details.name);
    body.append("shop_details[address]", shop_details.address);
    body.append("shop_details[city]", shop_details.city);
    body.append("shop_details[postcode]", shop_details.postcode);
    body.append("shop_details[country_code]", shop_details.country);
    if (shop_details.description)
      body.append("shop_details[description]", shop_details.description);
    body.append("shop_details[category]", "" + shop_details.category);

    // Geo coordinates
    if (shop_details.geo_lat)
      body.append("shop_details[geo_lat]", shop_details.geo_lat);
    if (shop_details.geo_lng)
      body.append("shop_details[geo_lng]", shop_details.geo_lng);

    // Social media
    if (social_media.facebook)
      body.append("shop_details[facebook]", social_media.facebook);
    if (social_media.instagram)
      body.append("shop_details[instagram]", social_media.instagram);
    if (social_media.website)
      body.append("shop_details[website]", social_media.website);

    // Coupon options
    body.append(
      "shop_details[offers_project_tree]",
      coupon_options.offers_project_tree
    );
    body.append(
      "shop_details[offers_project_ocean]",
      coupon_options.offers_project_ocean
    );
    body.append(
      "shop_details[offers_discount]",
      coupon_options.offers_discount
    );
    body.append(
      "shop_details[discount]",
      coupon_options.custom_text || coupon_options.discount_text
    );

    // Opening hours
    body.append(
      "shop_details[opening_hours_os]",
      formatOpeningHours(register.opening_hours)
    );

    // Cart
    let i = 0;
    Object.keys(designs).forEach((size) =>
      Object.keys(designs[size])
        .filter((design) => design !== 'custom' && designs[size][design] !== "")
        .forEach((design) => {
          const product = designConfig[size]['bags'][design];
          const priceSingle = product.product_type === 'goodcup' ? plans[register.plan].cup_price : plans[register.plan].bag_price;
          const priceTotal = priceSingle * parseInt(designs[size][design], 10);
          // body.append(`order[${i}][internal_name]`, design);
          body.append(`order[${i}][name]`, product.name);
          body.append(`order[${i}][type]`, product.product_type);
          body.append(`order[${i}][size]`, product.variant_size);
          body.append(`order[${i}][color]`, product.variant_color);
          body.append(`order[${i}][quantity]`, designs[size][design]);
          body.append(`order[${i}][price_single]`, priceSingle );
          body.append(`order[${i}][price_total]`, priceTotal);
          i++;
        }
      )
    )

    // custom designs
    Object.keys(designs).forEach((size) =>
      Object.keys(designs[size])
        .filter((design) => design === 'custom' && designs[size][design] !== "")
        .forEach((design) => {
          // const product = designConfig[size]['bags'][design];
          const priceSingle = getBagPrice(`custom_${size}`, parseInt(designs[size][design], 10));
          const priceTotal = priceSingle * parseInt(designs[size][design], 10);
          // body.append(`order[${i}][internal_name]`, design);
          body.append(`order[${i}][name]`, 'Custom Design');
          body.append(`order[${i}][type]`, 'goodbag');
          body.append(`order[${i}][size]`, size);
          body.append(`order[${i}][quantity]`, designs[size][design]);
          body.append(`order[${i}][price_single]`, priceSingle );
          body.append(`order[${i}][price_total]`, priceTotal);
          i++;
        }
      )
    )

    const {totalBagsCost, totalCupsCost, productsTotalCost} = getTotalPrice(designs, register.plan, currency_rates['default']);
    body.append(`order[totalCupsCost]`, totalCupsCost);
    body.append(`order[totalBagsCost]`, totalBagsCost);
    body.append(`order[productCostWithoutShippingAndPlan]`, productsTotalCost);

    // Ordered medium bags quantities
    // Object.keys(designs.medium)
    //   .filter((design) => designs.medium[design] > 0)
    //   .forEach((design) =>
    //     body.append(`order[medium][${design}]`, designs.medium[design])
    //   );
    // // Ordered medium large quantities
    // Object.keys(designs.large)
    //   .filter((design) => designs.large[design] > 0)
    //   .forEach((design) =>
    //     body.append(`order[large][${design}]`, designs.large[design])
    //   );

    // Custom bag logo details
    if (bag_logo.logo.file) {
      body.append("order[bag_logo]", bag_logo.logo.file);
      body.append("order[bag_logo_position][x]", bag_logo.position.x);
      body.append("order[bag_logo_position][y]", bag_logo.position.y);
      body.append("order[bag_logo_size][width]", bag_logo.size.width);
      body.append("order[bag_logo_size][height]", bag_logo.size.height);
      body.append("order[bag_logo_parent][width]", bag_logo.parent.width);
      body.append("order[bag_logo_parent][height]", bag_logo.parent.height);
    }
    // Request body construction end

    return await OrderAPIService.submitRegister(body);
  }

  /**
   * Constructs the request body for the reorder request
   * and calls the reorder submit method from the API service.
   * @return {Promise<{success: boolean}>}
   */
  static async submitReorder() {
    let {
      token,
      contact_info,
      shipping_address,
      billing_address: { same_as_shipping, ...billing_address },
      designs,
      bag_logo,
      google_place_details,
      ...reorder
    } = reduxStore.getState().reorder;

    billing_address = same_as_shipping ? shipping_address : billing_address;

    // Request body construction start
    const body = new FormData();

    // General info
    body.append("lang", getI18n().language.split("-")[0]);

    if (google_place_details) {
      const { place_id, ...rest } = google_place_details;
      const { opening_hours, ...googlePlaceDetails } = rest;

      body.append("google_place_id", place_id);

      Object.entries(googlePlaceDetails).forEach(([key, value]) =>
        body.append(`google_place_details[${key}]`, value)
      );

      body.append(
        "google_place_details[opening_hours]",
        formatOpeningHours(opening_hours)
      );
    }

    if (reorder.first_order)
      body.append("first_order", true);

    // Pure contact info
    body.append("contact_info[company]", contact_info.company);
    body.append("contact_info[contact_name]", contact_info.contact);
    body.append("contact_info[email]", contact_info.email);
    if (contact_info.phone)
      body.append("contact_info[phone]", contact_info.phone);
    if (contact_info.vat_number)
      body.append("contact_info[vat_number]", contact_info.vat_number);
    // The plan the user selected (could remain unchanged)
    body.append("contact_info[selected_plan]", reorder.selected_plan);

    // Shipping address
    body.append(
      "shipping_address[contact_name]",
      shipping_address.contact_name
    );
    body.append("shipping_address[address]", shipping_address.address);
    body.append("shipping_address[city]", shipping_address.city);
    body.append("shipping_address[postcode]", shipping_address.postcode);
    body.append(
      "shipping_address[country_code]",
      shipping_address.country_code
    );

    // Billing address
    body.append("billing_address[contact_name]", billing_address.contact_name);
    body.append("billing_address[address]", billing_address.address);
    body.append("billing_address[city]", billing_address.city);
    body.append("billing_address[postcode]", billing_address.postcode);
    body.append("billing_address[country_code]", billing_address.country_code);

    if (token) {
      if (reorder.db_billing_address) {
        const { id, type, ...db_billing_address } = reorder[
          same_as_shipping ? "db_shipping_address" : "db_billing_address"
        ];
        if (object_equals(db_billing_address, billing_address))
          body.append("billing_address_id", id);
      }

      if (reorder.db_shipping_address) {
        const {
          id,
          type,
          ...db_shipping_address
        } = reorder.db_shipping_address;
        if (object_equals(db_shipping_address, shipping_address))
          body.append("shipping_address_id", id);
      }
    }

    // Cart
    let i = 0;
    Object.keys(designs).forEach((size) =>
      Object.keys(designs[size])
        .filter((design) => design !== 'custom' && designs[size][design] !== "")
        .forEach((design) => {
          const product = designConfig[size]['bags'][design];
          const priceSingle = product.product_type === 'goodcup' ? plans[reorder.selected_plan].cup_price : plans[reorder.selected_plan].bag_price;
          const priceTotal = priceSingle * parseInt(designs[size][design], 10);
          // body.append(`order[${i}][internal_name]`, design);
          body.append(`order[${i}][name]`, product.name);
          body.append(`order[${i}][type]`, product.product_type);
          body.append(`order[${i}][size]`, product.variant_size);
          body.append(`order[${i}][color]`, product.variant_color);
          body.append(`order[${i}][quantity]`, designs[size][design]);
          body.append(`order[${i}][price_single]`, priceSingle );
          body.append(`order[${i}][price_total]`, priceTotal);
          i++;
        }
      )
    )

    // custom designs
    Object.keys(designs).forEach((size) =>
      Object.keys(designs[size])
        .filter((design) => design === 'custom' && designs[size][design] !== "")
        .forEach((design) => {
          // const product = designConfig[size]['bags'][design];
          const priceSingle = getBagPrice(`custom_${size}`, parseInt(designs[size][design], 10));
          const priceTotal = priceSingle * parseInt(designs[size][design], 10);
          // body.append(`order[${i}][internal_name]`, design);
          body.append(`order[${i}][name]`, 'Custom Design');
          body.append(`order[${i}][type]`, 'goodbag');
          body.append(`order[${i}][size]`, size);
          body.append(`order[${i}][quantity]`, designs[size][design]);
          body.append(`order[${i}][price_single]`, priceSingle );
          body.append(`order[${i}][price_total]`, priceTotal);
          i++;
        }
      )
    )

    const {totalBagsCost, totalCupsCost, productsTotalCost} = getTotalPrice(designs, reorder.selected_plan, currency_rates['default']);
    body.append(`order[totalCupsCost]`, totalCupsCost);
    body.append(`order[totalBagsCost]`, totalBagsCost);
    body.append(`order[productCostWithoutShippingAndPlan]`, productsTotalCost);
    // Ordered medium bags quantities
    // Object.keys(designs.medium)
    //   .filter((design) => designs.medium[design] > 0)
    //   .forEach((design) =>
    //     body.append(`order[medium][${design}]`, designs.medium[design])
    //   );
    // // Ordered medium large quantities
    // Object.keys(designs.large)
    //   .filter((design) => designs.large[design] > 0)
    //   .forEach((design) =>
    //     body.append(`order[large][${design}]`, designs.large[design])
    //   );

    // Custom bag logo details
    if (bag_logo.logo.file) {
      body.append("order[bag_logo]", bag_logo.logo.file);
      body.append("order[bag_logo_position][x]", bag_logo.position.x);
      body.append("order[bag_logo_position][y]", bag_logo.position.y);
      body.append("order[bag_logo_size][width]", bag_logo.size.width);
      body.append("order[bag_logo_size][height]", bag_logo.size.height);
      body.append("order[bag_logo_parent][width]", bag_logo.parent.width);
      body.append("order[bag_logo_parent][height]", bag_logo.parent.height);
    }
    // Request body construction end

    return await OrderAPIService.submitReorder(body, token);
  }
}

export default OrderService;
