import React from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { navigate } from "./routes/CustomBrowserRouter";
import layoutActions from "../../actions/layout";

export default () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { language } = i18n;
  const toggled = useSelector((s) => s.layout.languageSwitchToggled);

  const switchRef = React.useRef(null);

  const languagesList = {
    de: "Deutsch",
    en: "English",
    it: "Italiano",
    bg: "Български",
  };

  React.useEffect(() => {
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggle = (langSwitchToggled) => {
    if (
      langSwitchToggled ||
      (typeof langSwitchToggled === "undefined" && !toggled)
    )
      document.addEventListener("click", handleClickOutside);
    // Attach event listener on show
    else document.removeEventListener("click", handleClickOutside); // Detach event listener on hide

    dispatch(layoutActions.toggleLangSwitch(langSwitchToggled));
  };

  /**
   * Handle click outside the cart popup
   */
  const handleClickOutside = (event) => {
    const targetClassList = event.target?.classList;

    if (
      targetClassList &&
      !switchRef?.current?.contains(event.target) &&
      !targetClassList.contains("current-language") &&
      !targetClassList.contains("list-container") &&
      !event.target.parentNode?.classList.contains("current-language")
    ) {
      toggle(false);
    }
  };

  const handleLanguageChange = (newLanguage) => {
    if (newLanguage !== language) {
      // Replace "/en" (for example) in the beginning of the current pathname with "/de" (the new language, for example)
      const newPath = pathname.replace(new RegExp(`^\\/${language}`), `/${newLanguage}`);

      i18n.changeLanguage(newLanguage);
      navigate(newPath);
    }
  };

  const LanguageOption = ({ code, name }) => (
    <div
      className={`blue-SemiBold-16px lang-option ${
        language === code ? "selected" : ""
      } option-${code}`}
      onClick={() => handleLanguageChange(code)}
    >
      <div className="radio-btn">
        <div className="inner" />
      </div>
      {name}
    </div>
  );

  return (
    <div className="navbar-nav language-switch" ref={switchRef}>
      <span
        className="orange-bold-16px-left current-language"
        onClick={() => toggle()}
      >
        {language.toUpperCase()}
        <img src="/images/assets/globe-orange.svg" alt="" />
      </span>
      {toggled && (
        <div className="languages-list">
          <div className="triangle">
            <img src="/images/lang_switch/triangle.svg" alt="" />
          </div>
          <div className="list-container">
            {Object.entries(languagesList).map(([key, val]) => (
              <LanguageOption name={val} code={key} key={key} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
