import React from "react";
import { createBrowserHistory } from "history";
import { getLanguage } from "../../../config/i18n";
import { Router, BrowserRouter } from "react-router-dom";

import Routes from "./";
import Navbar from "../Navbar";

export const history = createBrowserHistory();

/**
 * Navigates to a given route. If the route has a parameter ":lang" in it,
 * replace it with the current language.
 * @param {string} to - Where should the browser navigate to.
 * @param {object} state - The state to pass when navigating.
 * @return {*}
 */
export const navigate = (to, state = {}) =>
  history.push(to.replace(":lang", getLanguage()), state);

export default class CustomBrowserRouter extends BrowserRouter {
  render = () => (
    <Router history={history}>
      <Navbar />
      <div className="app-container">
        <Routes />
      </div>
    </Router>
  );
}
