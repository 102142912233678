export const destroyServiceWorker = () => {
  // Unregister all service workers and reload all clients.
  if (navigator.serviceWorker) {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      if (registrations.length > 0) {
        for (let registration of registrations)
          registration.unregister()
      }
    });
  }

  // Clear worker caches
  if ("caches" in window) {
    caches.keys().then(function (keyList) {
      return Promise.all(
        keyList.map(function (key) {
          return caches.delete(key);
        })
      );
    });
  }
};
