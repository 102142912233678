import React, { Component } from "react";

import { navigate } from "../../shared/routes/CustomBrowserRouter";
import routes from "../../../constants/routes";
import { withTranslation } from "react-i18next";

import { connect } from "react-redux";
import layoutActions from "../../../actions/layout";

import OrderService from "../../../services/business/OrderService";

import NeedHelp from "./NeedHelp";
import OrderTotals from "./OrderTotals";
import InfoBox from "../../shared/InfoBox";
import Checkbox from "../../shared/forms/Checkbox";

class ConfirmationSummary extends Component {
  state = {
    submitted: false,
    termsOpened: false,
    privacyOpened: false,
  };

  onFormSubmit = async () => {
    // Prevent double submission
    if (this.state.submitted) return;

    let { consentToggled, t } = this.props;
    if (!consentToggled)
      return this.setError(
        t("summary:step_4_error_title"),
        t("summary:comply_error")
      );

    this.setState({ submitted: true });
    let { success } = await OrderService.submitRegister();

    // Pass the result silently to the order completed screen
    navigate(routes.register.completed, { success, process: "register" });
  };

  setError = (title, text) =>
    this.props.toggleError(title && text, { title, text });

  handleLegalLinkClick = (ev) => {
    ev.stopPropagation();

    if (ev.target.classList.contains("privacy-link"))
      this.props.togglePopup("privacy", true);
    if (ev.target.classList.contains("terms-link"))
      this.props.togglePopup("terms", true);
  };

  render = () => {
    let { t, consentToggled, toggleConsent } = this.props;
    let isNextValid = consentToggled ? "" : "disabled";
    let isSubmitted = this.state.submitted ? "submitted" : "";

    return (
      <div className="summary confirmation">
        <div className="summary-container">
          <div className="confirmation-summary summary-content">
            <div className={`summary-content-wrapper`}>
              <h4 className="blue-Bold-20px-left">
                {window.innerWidth <= 780
                  ? t("summary:step_4_heading_mobile")
                  : t("summary:step_4_heading_desktop")}
              </h4>
              <InfoBox
                title={t("summary:step_4_info_box_title")}
                text={t("summary:step_4_info_box_text")}
              />
              <OrderTotals
                plan={this.props.plan}
                quantities={this.props.quantities}
                country={this.props.country}
                procedure="registration"
              />
              <Checkbox onClick={toggleConsent} initialValue={consentToggled}>
                <div
                  className="blue-Regular-14px"
                  onClick={this.handleLegalLinkClick}
                  dangerouslySetInnerHTML={{
                    __html: t("summary:comply_text", {
                      interpolation: { escapeValue: false },
                      terms: `<span class="orange-Regular-14px terms-link">${t(
                        "summary:terms_and_conditions"
                      )}</span>`,
                      privacy: `<span class="orange-Regular-14px privacy-link">${t(
                        "summary:privacy_policy"
                      )}</span>`,
                    }),
                  }}
                />
              </Checkbox>
              <div className="proceed">
                <button
                  className={`btn btn-block btn-primary ${isNextValid} ${isSubmitted}`}
                  type="submit"
                  form="shop-details-form"
                  onClick={this.onFormSubmit}
                >
                  {t("summary:step_4_checkout")}
                </button>
              </div>
              <NeedHelp />
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  editing_step: state.register.editing_step,
  consentToggled: state.layout.checkboxes.confirmation,
  display_error: state.layout.error_popups.confirmation,
  quantities: state.register.designs,
  plan: state.register.plan,
  country: state.register.shop_details.country,
});

const mapDispatchToProps = (dispatch) => ({
  toggleConsent: () => dispatch(layoutActions.toggleConsent("confirmation")),
  toggleError: (toggled, error) =>
    dispatch(layoutActions.toggleErrorPopup("confirmation", toggled, error)),
  togglePopup: (name, toggled) =>
    dispatch(layoutActions.togglePopup(name, toggled)),
});

let reduxWrapped = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmationSummary);

export default withTranslation()(reduxWrapped);
