import LocationAPIService from "../network/LocationAPIService";
import { currency_rates } from "../../constants/internationals";
import CONFIG from "../../config";

class LocationService {
  /**
   * Try to parse the country code from the window navigator object. If it is not
   * available there, fetch it from the LocationAPIService.
   * If the Location API service method fails, return the fallback country.
   * @returns {string}
   */
  static async getUserCountry() {
    // Get the country codes of all languages available in the window.navigator object
    const browserLangsCountries = window.navigator.languages.map(
      (l) => l.split("-")[1] || null
    );
    // Get the country codes of all currencies that we support
    const currenciesWhitelist = Object.keys(currency_rates);

    let countryCode = "";

    for (let i in browserLangsCountries) {
      // If any of the browser country codes match any of the currencies we support we are ready to go
      if (currenciesWhitelist.indexOf(browserLangsCountries[i]) > -1) {
        countryCode = browserLangsCountries[i];
        break;
      }
    }

    // If we support none of the country codes of the browser's languages,
    // get the country code of the client by making a request to the backend.
    if (!countryCode) {
      let result = await LocationAPIService.getUserCountry();
      if (result.success) countryCode = result.data.country_code;
      // Return fallback country if everything fails
      else countryCode = CONFIG.FALLBACK_CLIENT_COUNTRY;
    }

    return countryCode;
  }

  /**
   * Get the location details of a shop.
   * @param place_id - the Id of the place, returned by Google Places API
   * @param {function} callback
   * @returns {void}
   */
  static getPlaceDetails(place_id, callback) {
    let map = new window.google.maps.Map(document.getElementById("map"), {
      center: { lat: 0, lng: 0 },
      zoom: 0,
    });
    let request = {
      placeId: place_id,
      fields: [
        "formatted_address",
        "address_components",
        "international_phone_number",
        "geometry",
        "name",
        "type",
        "icon",
        "place_id",
        "opening_hours",
        "website",
      ],
    };

    let PlacesService = new window.google.maps.places.PlacesService(map);

    PlacesService.getDetails(request, callback);
  }
}

export default LocationService;
