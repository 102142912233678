import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import InfoBox from "../../shared/InfoBox";
import Input from "../../shared/forms/Input";

import { connect } from "react-redux";
import registerActions from "../../../actions/register";
import layoutActions from "../../../actions/layout";

class SocialMedia extends Component {
  onChange = ({ target }) => {
    let { name, value } = target;
    this.props.handleInputChange(name, value);
  };

  render = () => {
    let chevronDirection = this.props.toggled ? "up" : "down";
    let isToggled = this.props.toggled ? "toggled" : "";
    let { t, details } = this.props;

    return (
      <div className={`social-media-container ${isToggled}`}>
        <div
          className="social-media-heading d-flex justify-content-between align-items-center"
          onClick={this.props.toggle}
        >
          <h4 className="blue-Bold-20px-left">
            {t("details:social_media_heading")}
          </h4>
          <i className={`fas fa-chevron-${chevronDirection}`} />
        </div>
        <div className="inputs-wrapper">
          <InfoBox className="mt-0" text={t("details:social_media_infobox")} />
          <Input
            name="website"
            value={details.website}
            onChange={this.onChange}
            placeholder={t("details:social_media_website")}
          />
          <Input
            name="facebook"
            value={details.facebook}
            onChange={this.onChange}
            placeholder={t("details:social_media_facebook")}
          />
          <Input
            name="instagram"
            value={details.instagram}
            onChange={this.onChange}
            placeholder={t("details:social_media_instagram")}
          />
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  details: state.register.social_media,
  toggled: state.layout.toggles.details.social,
});

const mapDispatchToProps = (dispatch) => ({
  handleInputChange: (name, value) =>
    dispatch(registerActions.handleInputChange("social_media", name, value)),
  toggle: () => dispatch(layoutActions.toggleShopDetailsPanel("social")),
});

let reduxWrapped = connect(mapStateToProps, mapDispatchToProps)(SocialMedia);
export default withTranslation()(reduxWrapped);
