import designs from "../../constants/designs";
import BgoodAPIService from "../network/BgoodProductAPIService";

/**
 * This service handles all business-related requests' business logic.
 */
class BgoodProductService {
  /**
   * Get all designs
   * @param token
   * @return {Promise<{success: boolean, data: Object}|{success: boolean}>}
   */
  static async getDesigns(token) {
    let { success, data } = await BgoodAPIService.list(
      token
    );
    let returnDesigns = {...designs};
    if(success) {

      returnDesigns.cups_large.bags = data.bgood_products
        .filter(design => design.product_type === 'goodcup')
        .sort((a, b) => a.order - b.order)
        .reduce((obj, item) => ({...obj, [`${item.code}_${item.variant_size}_${item.variant_color}`]: {...item, key: `${item.code}_${item.variant_size}_${item.variant_color}`}}) ,{});

      returnDesigns.medium.bags = data.bgood_products
        .filter(design => design.variant_size === 'medium' && design.product_type === 'goodbag')
        .sort((a, b) => a.order - b.order)
        .reduce((obj, item) => ({...obj, [`${item.code}_${item.variant_size}_${item.variant_color}`]: {...item, key: `${item.code}_${item.variant_size}_${item.variant_color}`}}) ,{});
      
      returnDesigns.large.bags = data.bgood_products
        .filter(design => design.variant_size === 'large' && design.product_type === 'goodbag')
        .sort((a, b) => a.order - b.order)
        .reduce((obj, item) => ({...obj, [`${item.code}_${item.variant_size}_${item.variant_color}`]: {...item, key: `${item.code}_${item.variant_size}_${item.variant_color}`}}) ,{});
    }

    return {success, data: returnDesigns}
  }
}

export default BgoodProductService;
