export const days_of_week = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];
export const off_days = ["Saturday", "Sunday"];

// Create an empty objects collection for the opening hours
// with no values for open and close times
const initializeOpeningHours = () => {
  let opening_hours = {};
  days_of_week.forEach((day) => {
    opening_hours[day] = {
      open: off_days.indexOf(day) < 0,
      from: "",
      to: "",
    };
  });
  return opening_hours;
};

export default initializeOpeningHours;
