import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import { connect } from "react-redux";
import registerActions from "../../actions/register";
import layoutActions from "../../actions/layout";

import { Route, Prompt } from "react-router-dom";
import { navigate } from "../shared/routes/CustomBrowserRouter";
import { getLanguage } from "../../config/i18n";
import routes from "../../constants/routes";

import Summary from "./summary/Summary";
import RegisterNavigation from "./RegisterNavigation";

import ShopDetailsStep from "./details/ShopDetailsStep";
import ChoosePlanStep from "./plan/ChoosePlanStep";
import ChooseDesignsStep from "./designs/ChooseDesignsStep";
import ConfirmationStep from "./confirmation/ConfirmationStep";

import "../../stylesheets/register/index.scss";
import ConnectedIntlProvider from "../../providers/ConnectedIntlProvider";

class RegisterPage extends Component {
  constructor(props) {
    super(props);

    // Prompt the user to confirm page reloading.
    onbeforeunload = (e) => this.props.t("general:page_reload_prompt");

    // If user hasn't started the registration register by clicking "Enter shop details" or
    // hasn't selected a shop from Google places in the first place, he is not supposed to
    // be here. Redirect him to the landing page.
    if (!this.props.processStarted) return navigate(routes.index);
  }

  componentDidMount = () =>
    document.addEventListener("keydown", this.keyPressEventListener);

  componentWillUnmount = () => {
    this.props.setEditingStep("");

    onbeforeunload = null;

    document.removeEventListener("keydown", this.keyPressEventListener);
  };

  keyPressEventListener = (ev) => {
    if (ev.keyCode === 27) this.props.hideErrorPopups();
  };

  render = () => {
    return (
      <div className="onboarding-process-page">
        {/* Prompt user to confirm if he wants to leave the /register route and lose all data. */}
        <Prompt
          when={true}
          message={(location) =>
            location.state?.hidePrompt ||
            location.pathname.startsWith(routes.register.start.replace(':lang', getLanguage()))
              ? true
              : this.props.t("general:process_leave_prompt", {
                  process: this.props.t("general:register"),
                })
          }
        />
        <ConnectedIntlProvider>
          <RegisterNavigation current={this.props.match.params.action} />
        </ConnectedIntlProvider>
        <div className="process-step-container">
          <Route path={routes.register.details} component={ShopDetailsStep} />
          <ConnectedIntlProvider>
            <Route path={routes.register.plan} component={ChoosePlanStep} />
            <Route
              path={routes.register.designs}
              component={ChooseDesignsStep}
            />
            <Route
              path={routes.register.confirmation}
              component={ConfirmationStep}
            />
          </ConnectedIntlProvider>
        </div>
        <Summary />
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  processStarted: state.register.entry_type,
  process: state.register,
  checkboxes: state.layout.checkboxes,
});

const mapDispatchToProps = (dispatch) => ({
  setEditingStep: (step) => dispatch(registerActions.setEditingStep(step)),
  hideErrorPopups: () => dispatch(layoutActions.hideErrorPopups()),
});

let reduxWrapped = connect(mapStateToProps, mapDispatchToProps)(RegisterPage);

export default withTranslation()(reduxWrapped);
