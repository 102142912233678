import { post } from "../../utilities/request";
import {
  CUSTOMER_LEAD_URL,
  PLACE_LEAD_URL,
  SHOP_LEAD_URL,
} from "../../constants/endpoints";

/**
 * This service is responsible for handling various requests that are sent
 * during the shop registration process.
 */
class ProgressAPIService {
  /**
   * Lead a place_id of a place suggestion provided by
   * the Google Places API that the user selected.
   * @param {string} place_id
   * @return {Promise<{success: boolean}>}
   */
  static async leadPlace(place_id) {
    try {
      let { success } = await post(PLACE_LEAD_URL, { place_id });
      return { success };
    } catch (ex) {
      return { success: false };
    }
  }

  /**
   * Lead a place_id of a place that is included in a reorder link,
   * clicked by a potential customer.
   * @param {string} place_id
   * @return {Promise<{success: boolean}>}
   */
  static async leadCustomer(place_id) {
    try {
      let { success } = await post(CUSTOMER_LEAD_URL, { place_id });
      return { success };
    } catch (ex) {
      return { success: false };
    }
  }

  /**
   * Lead a shop with the info gathered from step 1 into our db.
   * @param {{
   *   [place_id]: string,
   *   email: string,
   *   company_name: string,
   *   contact_name: string,
   *   offers_discount: boolean,
   *   offers_project_ocean: boolean,
   *   offers_project_tree: boolean,
   *   discount: string
   * }} shop_details
   * @return {Promise<{success: boolean}>}
   */
  static async leadShop(shop_details) {
    try {
      let { success } = await post(SHOP_LEAD_URL, shop_details);
      return { success };
    } catch (ex) {
      return { success: false };
    }
  }
}

export default ProgressAPIService;
