import React from "react";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { currency_rates } from "../../../constants/internationals";
import plans from "../../../constants/plans";
import designs from "../../../constants/designs";

import {
  getBagsQuantities,
  calculateShippingPrice,
  getTotalPrice,
} from "../../../helpers/ProcessHelpers";
import layoutActions from "../../../actions/layout";
import { isFromInternationalZone } from "../../../helpers/LocationHelpers";

export default ({ quantities, plan, procedure, country, step }) => {
  const intl = useIntl();
  const { t } = useTranslation("reorder");
  const dispatch = useDispatch();
  let current_plan =
    // stringify and parse current plan, so that redux state doesn't mutate on value change here locally
    procedure === "reorder"
      ? JSON.parse(JSON.stringify(useSelector((s) => s.reorder.current_plan)))
      : null;
  const first_order =
    procedure === "reorder" && useSelector((s) => s.reorder.first_order);
  const { preselected_plan } = useSelector((s) => s.reorder);

  // stringify and parse current plan, so that redux state doesn't mutate on value change here locally
  let selected_plan = !!plan ? JSON.parse(JSON.stringify(plan)) : "ondemand";

  // Use the non-promotional version of the selected plan in the calculations of the plan price
  // only when the free of version of the current plan is preselected (if there is one) and
  // the preselected plan is different than the currently selected one.
  //
  // Example:
  // "standard_free" is pre-selected; The current plan is "standard"; In this case, use
  // the price of "standard" to calculate the plan price, because in a case where the user upgrades,
  // the price difference should be 149 (premium) - 49(standard) = 100, and not 149 - 0 (standard_free).
  if (
    preselected_plan &&
    `${current_plan?.name}_free` === preselected_plan &&
    preselected_plan === selected_plan
  )
    selected_plan = preselected_plan.replace("_free", "");

  // If there is a preselected plan, if the current plan is promotional and it is different than the
  // currently selected one, use the price of its original variant in the plan price calculations.
  if (
    preselected_plan &&
    plans[current_plan.name]?.promotional &&
    current_plan?.name !== selected_plan
  ) {
    current_plan.name = current_plan.name.replace("_free", "");
  }

  let { charge_bags, charge_cups, custom_large_bags, custom_medium_bags, charge_bags_free } = getBagsQuantities(
    selected_plan,
    quantities,
    designs,
  );
  let currency = currency_rates[country] || currency_rates.default;

  const openShippingCalculator = () =>
    dispatch(layoutActions.togglePopup("shipping_calculator", true));

  const formatPrice = (price, decimals = 2) =>
    intl.formatNumber(price, {
      style: "currency",
      currency: currency.code,
      currencyDisplay: "symbol",
      maximumFractionDigits: decimals,
      minimumFractionDigits: decimals,
    });

  // If the user is reordering the text in brackets under
  // "Subscription fee" is different then when registering
  // const planTranslationParts =
  //   procedure === "reorder" && current_plan.name
  //     ? {
  //         plan: plans[current_plan.name].display_name,
  //         valid_until: current_plan.valid_until,
  //       }
  //     : {};

  // If user is reordering but is currently on standard or premium plan
  // they used their free bags, so we no longer offer free bags to them
  if (
    procedure === "reorder" &&
    current_plan.name &&
    current_plan.name !== "ondemand"
  ) {
    charge_bags += charge_bags_free;
    charge_bags_free = 0;
  }

  const {totalBagsCost, totalCupsCost, productsTotalCost} = getTotalPrice(quantities, plan, currency);
  
  // If the user upgrades plan, subtract the old plan price
  // from the new plan price
  const planMaxedOut =
    current_plan.name === Object.keys(plans)[Object.keys(plans).length - 1];

  let planPrice = Math.trunc(
    // If process is reorder and there is a business loaded and its current plan is NOT maxed out
    (procedure === "reorder" && !!current_plan.name && !planMaxedOut
      ? // check if the current plan and the plan
        current_plan.name !== selected_plan
        ? plans[selected_plan].price - plans[current_plan.name].price
        : 0
      : // display plan price
      planMaxedOut
      ? 0
      : plans[selected_plan].price) * currency.rate
  );

  let shippingPrice = calculateShippingPrice(
    charge_bags + charge_cups + charge_bags_free + custom_large_bags + custom_medium_bags,
    country
  );
  shippingPrice =
    procedure === "reorder"
      ? shippingPrice
        ? shippingPrice.cost * currency.rate
        : null
      : null;

  const customDelivery =
    country !== "" &&
    (isFromInternationalZone(country) || shippingPrice === null);

  let totalCost =
    productsTotalCost +
    planPrice +
    (procedure === "reorder" && !customDelivery && !first_order
      ? shippingPrice
      : 0);

  const renderShippingCost = () => {
    if (procedure !== "reorder") return null;

    return (
      <div
        className={`d-flex justify-content-between ${
          customDelivery && !first_order && step !== "confirmation"
            ? "align-items-start"
            : ""
        } shipping-cost`}
      >
        <span className="label">
          <span
            dangerouslySetInnerHTML={{
              __html:
                t(
                  customDelivery && !first_order
                    ? step === "designs"
                      ? "shipping_tailored"
                      : "shipping_custom"
                    : "shipping"
                ) + " ",
            }}
          />
          {!customDelivery && !first_order && (
            <img
              src="/images/assets/info-orange.svg"
              alt="?"
              onClick={openShippingCalculator}
            />
          )}
        </span>
        {!customDelivery || first_order ? (
          <span
            className="blue-Bold-14px text-right"
            dangerouslySetInnerHTML={{
              __html:
                // Display "Free" if this is a user's first order.
                first_order
                  ? t("free")
                  : shippingPrice === 0
                  ? formatPrice(shippingPrice).replace("00", "-")
                  : formatPrice(shippingPrice),
            }}
          />
        ) : (
          <img
            src="/images/assets/info-orange.svg"
            style={{
              marginTop: step === "designs" ? 15 : 0,
              cursor: "pointer",
            }}
            alt="?"
            onClick={openShippingCalculator}
          />
        )}
      </div>
    );
  };

  return (
    <div className="order-totals">
      <div className="blue-Regular-14px totals-wrapper">
        {/* TODO: if there will be free bags in future, reenable and adapt logic
        {((procedure === "registration" && selected_plan !== "ondemand") ||
          (procedure === "reorder" &&
            current_plan.name === "ondemand" &&
            selected_plan !== "ondemand") ||
          (procedure === "reorder" &&
            !current_plan.name &&
            selected_plan !== "ondemand")) && (
          <div className="d-flex justify-content-between free-bags">
            <span>
              {charge_bags_free} {t("free_bags")}
            </span>
            <span className="blue-Bold-14px">
              {formatPrice(0).replace("00", "-")}
            </span>
          </div>
        )} */}
        <div className={`bags-price`}>
          <div className="d-flex justify-content-between">
            <span>
              {charge_bags + custom_large_bags + custom_medium_bags} {t(`${procedure}_charge_bags`)}
            </span>
            <span className="blue-Bold-14px">
              {totalBagsCost === 0
                ? formatPrice(totalBagsCost).replace("00", "-")
                : formatPrice(totalBagsCost)}
            </span>
          </div>
        </div>
        <div className={`bags-price`}>
          <div className="d-flex justify-content-between">
            <span>
              {charge_cups} {t(`${procedure}_charge_cups`)}
            </span>
            <span className="blue-Bold-14px">
              {totalCupsCost === 0
                ? formatPrice(totalCupsCost).replace("00", "-")
                : formatPrice(totalCupsCost)}
            </span>
          </div>
        </div>
        {/*{procedure === "registration" && selected_plan === "ondemand" ? null : (*/}
        {/*  <div className="d-flex justify-content-between plan-price">*/}
        {/*    <span*/}
        {/*      dangerouslySetInnerHTML={{*/}
        {/*        __html: t(*/}
        {/*          `${procedure}_subscription${*/}
        {/*            procedure === "reorder"*/}
        {/*              ? !current_plan.name*/}
        {/*                ? "_anonymous"*/}
        {/*                : current_plan.name === "ondemand"*/}
        {/*                ? "_ondemand"*/}
        {/*                : ""*/}
        {/*              : ""*/}
        {/*          }`,*/}
        {/*          planTranslationParts*/}
        {/*        ),*/}
        {/*      }}*/}
        {/*    />*/}
        {/*    <span className="blue-Bold-14px">*/}
        {/*      {planPrice === 0*/}
        {/*        ? formatPrice(planPrice).replace("00", "-")*/}
        {/*        : formatPrice(planPrice, 0)}*/}
        {/*    </span>*/}
        {/*  </div>*/}
        {/*)}*/}
        {renderShippingCost()}
        <div className="d-flex justify-content-between total">
          <span>{t("total")}</span>
          <div className="blue-Bold-14px">
            {totalCost === 0
              ? formatPrice(totalCost).replace("00", "-")
              : formatPrice(totalCost)}
          </div>
        </div>
      </div>
    </div>
  );
};
