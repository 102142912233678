import React from "react";
import Head from "react-helmet";
import { useTranslation } from "react-i18next";

/**
 * This component ensures the html tag always has the current
 * language set as a "lang" attribute.
 * Note: The i18n context cannot be accessed in the App component
 * so that's why we need to do the lang param setting in a child
 */
function HtmlLanguage() {
  const { i18n } = useTranslation();
  return (
    <Head title="goodbag & goodcup Wholesale Orders for Partners">
      <html lang={i18n.language} />
    </Head>
  );
}

export default HtmlLanguage;
