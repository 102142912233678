import React, { Component } from "react";
import { onInput, onInputInvalid } from "../../../helpers/FormHelpers";

import "../../../utilities/extensions";

class TextArea extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: "empty",
      value: props.value,
    };

    this.reference = new React.createRef();
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({ value: this.props.value });
    }
  }

  /**
   * Clear input validation error message.
   */
  validateTextarea = () => onInput({ target: this.reference });

  /**
   * Display input field error message.
   */
  invalidateTextarea = () =>
    onInputInvalid({ target: this.reference }, this.props.errorMessage);

  checkValidity = (ev) => {
    let { required, minLength, maxLength, pattern, noValidation } = this.props;
    let value =
      typeof ev.target.value === "string"
        ? ev.target.value.trim()
        : ev.target.value;

    if (noValidation || (!required && !minLength && !maxLength && !pattern))
      return this.setState({ status: "" });

    if (required && !value)
      return this.setState({ status: "invalid" }, this.invalidateTextarea);

    if (minLength && value.length < minLength)
      return this.setState({ status: "invalid" }, this.invalidateTextarea);

    if (maxLength && maxLength < value.length)
      return this.setState({ status: "invalid" }, this.invalidateTextarea);

    if (pattern && !RegExp(pattern).test(value))
      return this.setState({ status: "invalid" }, this.invalidateTextarea);

    this.setState({ status: "valid" }, this.validateTextarea);
  };

  onBlur = (ev) => {
    if (this.props.onBlur) this.props.onBlur(ev);

    this.checkValidity({ target: this.reference });
  };

  handleOnChange = (ev) => {
    this.setState({ value: ev.target.value });
    this.checkValidity(ev);
    this.props.onChange(ev);
  };

  render = () => {
    let {
      required,
      name,
      type,
      value,
      placeholder,
      onChange,
      noValidation,
      hasCounter,
      errorMessage,
      ...props
    } = this.props;

    return (
      <div className={`textarea ${this.state.status}`}>
        <textarea
          name={name}
          required={required}
          value={this.state.value}
          ref={(node) => (this.reference = node)}
          placeholder={placeholder || name.capitalize()}
          onChange={this.handleOnChange}
          onInvalid={this.invalidateTextarea}
          onBlur={this.onBlur}
          {...props}
        />
        {hasCounter && props.maxLength && (
          <div className="chars-counter pt-1 blue-Light-13px-right">
            {value.length}/{props.maxLength}
          </div>
        )}
      </div>
    );
  };
}

export default TextArea;
