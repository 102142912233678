import React from "react";
import { IntlProvider } from "react-intl";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

/**
 * A Intl provider, connected to the redux state so that it can access the current
 * country selected for the location of the shop and create a locale that would
 * best fit the needs of the user, depending on the language he selected and the
 * country that his shop is in.
 * @param country - The country code of the shop's location. DE by default.
 * @param i18n - the i18n object
 * @param children - The content that this component wraps
 * @return {IntlProvider} a provider component with a custom constructed locale
 */
const ConnectedIntlProvider = ({ country, i18n, children }) => {
  const locale = `${i18n.language.split("-")[0]}-${country || "DE"}`;

  return (
    <IntlProvider locale={locale} key={locale}>
      {children}
    </IntlProvider>
  );
};

const mapStateToProps = (state) => ({
  country: state.reorder.shipping_address.country_code,
});

const Reduxified = withTranslation()(ConnectedIntlProvider);
export default connect(mapStateToProps)(Reduxified);
