import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import registerActions from "../../actions/register";
import routes from "../../constants/routes";

import { ScaleIn } from "../hocs/AnimationHOC";

import ShopFinder from "./ShopFinder";
import { navigate } from "../shared/routes/CustomBrowserRouter";

class FindShopModal extends Component {
  selectManualAddition = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate(routes.new_business.designs);
  };

  render() {
    let { t } = this.props;

    return (
      <div className="find-shop-modal">
        <ScaleIn className="animation-wrapper">
          <div className="modal-container">
            <div className="close-modal" onClick={this.props.close}>
              <img src="/images/assets/close.svg" alt="x" />
            </div>
            <div className="blue-Bold-30px-center modal-heading">
              {t("landing:enter_shop_name")}
            </div>
            <ShopFinder />
            <div className="blue-Regular-16px-center cant-find-shop-heading">
              {t("landing:enter_details_label")}
            </div>
            <div
              className="btn btn-outline-primary manual-details-btn mt-2"
              onClick={this.selectManualAddition}
            >
              {t("landing:enter_details_button")}
            </div>
          </div>
        </ScaleIn>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  selectEntryType: (type) => dispatch(registerActions.selectEntryType(type)),
});

let Reduxified = connect(null, mapDispatchToProps)(FindShopModal);

export default withTranslation()(Reduxified);
