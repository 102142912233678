import actionTypes from "../constants/action_types/layout";

export default {
  togglePreviewPanel: (panel) => ({
    type: actionTypes.TOGGLE_PREVIEW_PANEL,
    panel,
  }),
  toggleShopDetailsPanel: (panel) => ({
    type: actionTypes.TOGGLE_SHOP_DETAILS_PANEL,
    panel,
  }),
  toggleConsent: (checkbox) => ({
    type: actionTypes.TOGGLE_CONSENT_CHECKBOX,
    checkbox,
  }),
  toggleErrorPopup: (name, toggled, error = {}) => ({
    type: actionTypes.TOGGLE_ERROR_POPUP,
    name,
    toggled,
    error,
  }),
  toggleLangSwitch: (toggled = null) => ({
    type: actionTypes.TOGGLE_LANG_SWITCH,
    toggled,
  }),
  togglePopup: (name, toggled) => ({
    type: actionTypes.TOGGLE_POPUP,
    name,
    toggled,
  }),
  resetLayout: () => ({
    type: actionTypes.RESET_LAYOUT,
  }),
  hideErrorPopups: () => ({
    type: actionTypes.HIDE_ERROR_POPUPS,
  }),
  updateDimension: (step, element, dimension, value) => ({
    type: actionTypes.UPDATE_DIMENSION,
    step,
    element,
    dimension,
    value,
  }),
};
