import React, { Component } from "react";

import { connect } from "react-redux";
import layoutActions from "../../../actions/layout";
import { withTranslation } from "react-i18next";
import { injectIntl } from "react-intl";

import plans from "../../../constants/plans";
import { currency_rates } from "../../../constants/internationals";

import { navigate } from "../../shared/routes/CustomBrowserRouter";
import routes from "../../../constants/routes";

import NeedHelp from "./NeedHelp";
import InfoBox from "../../shared/InfoBox";

class ChoosePlanSummary extends Component {
  wrapper = new React.createRef();

  // componentDidMount = () => {
  // 	window.addEventListener('scroll', this.handleWindowScroll);
  // 	this.handleWindowScroll();
  // }

  // componentWillUnmount = () => window.removeEventListener('scroll', this.handleWindowScroll);

  // componentDidUpdate = () => this.handleWindowScroll();

  // On window scroll adjust the summary content
  handleWindowScroll = () => {
    let { scrollY } = window;
    let percentage = this.props.plan ? "38%" : "46%";
    let maxScroll = this.props.plan ? 370 : 450;

    if (scrollY <= 50) this.wrapper.style.bottom = percentage;
    else if (scrollY > 50 && scrollY < maxScroll) {
      this.wrapper.style.bottom = `calc(${percentage} - ${scrollY}px)`;
    } else this.wrapper.style.bottom = "0";
  };

  onFormSubmit = () => {
    if (this.props.plan) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      return navigate(
        this.props.editing_plan
          ? routes.register.confirmation
          : routes.register.designs
      );
    }
    this.props.toggleError(true);
  };

  render = () => {
    let { plan, t, intl, editing_plan, country } = this.props;
    let isEmpty = !plan ? "empty" : "";
    let isDisabled = !plan ? "disabled" : "";

    let currency = currency_rates[country] || currency_rates.default;
    let plan_price = !plan
      ? ""
      : intl.formatNumber(plans[plan].price * currency.rate, {
          style: "currency",
          currency: currency.code,
          currencyDisplay: "symbol",
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
        });

    return (
      <div className="summary plan">
        <div className="summary-container">
          <div className="choose-plan-summary summary-content">
            <div
              className={`summary-content-wrapper ${isEmpty}`}
              ref={(node) => (this.wrapper = node)}
            >
              <h4 className="blue-Bold-20px-left">{t("summary:heading")}</h4>
              {plan ? (
                <div className="content">
                  <div className="d-flex justify-content-between plan-fee">
                    <span className="blue-Regular-14px">
                      {t("summary:step_2_subscription_fee")}
                    </span>
                    <span className="blue-Bold-14px">{plan_price}</span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <span className="blue-Regular-14px">
                      {t("summary:step_2_total")}
                    </span>
                    <span className="blue-Bold-14px">{plan_price}</span>
                  </div>
                </div>
              ) : (
                <InfoBox
                  text={t("summary:step_2_select_plan")}
                  paddingTop="pt-1"
                />
              )}
              <div className="proceed">
                <button
                  className={`btn btn-block btn-primary ${isDisabled} submit-plan-step-btn`}
                  type="button"
                  onClick={this.onFormSubmit}
                >
                  {editing_plan
                    ? t("summary:save_changes")
                    : t("summary:next_step")}
                </button>
              </div>
              <NeedHelp />
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  plan: state.register.plan,
  editing_plan: state.register.editing_step === "plan",
  country: state.register.shop_details.country,
});

const mapDispatchToProps = (dispatch) => ({
  toggleError: (toggled) =>
    dispatch(layoutActions.toggleErrorPopup("plan", toggled)),
});

const Reduxified = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChoosePlanSummary);
const WithIntl = injectIntl(Reduxified);

export default withTranslation()(WithIntl);
