import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { adjustProcessNavigationScroll } from "../../../helpers/ProcessHelpers";

import reorderActions from "../../../actions/reorder";
import layoutActions from "../../../actions/layout";

import InfoMessage from "../../shared/InfoMessage";
import PlanSelect from "./PlanSelect";
import DesignOptionsList from "../../register/designs/DesignOptionsList";
import BagDetailsModal from "../../register/designs/BagDetailsModal";

import "../../../stylesheets/reorder/designs.scss";

export default () => {
  const { t } = useTranslation("reorder");
  const dispatch = useDispatch();
  const {
    selected_plan,
    designs,
    bag_logo,
    shipping_address: { country_code },
  } = useSelector((s) => s.reorder);
  const { designs: displayError } = useSelector((s) => s.layout.error_popups);
  const [selectedBag, setSelectedBag] = React.useState(null);

  React.useEffect(() => {
    adjustProcessNavigationScroll("designs");
  }, []);

  const handleQuantityChange = (size, design, quantity) =>
    dispatch(reorderActions.handleQuantityChange(size, design, quantity));

  const saveLogo = (details) => dispatch(reorderActions.saveCustomBag(details));

  return (
    <div className="choose-designs-step">
      <PlanSelect />
      <DesignOptionsList
        step="reorder"
        showBagDetails={(bag) => setSelectedBag(bag)}
        quantities={designs}
        plan={selected_plan}
        custom_logo={bag_logo}
        saveLogo={saveLogo}
        country={country_code}
        setQuantity={handleQuantityChange}
        updateDesignsHeight={(height) =>
          dispatch(
            layoutActions.updateDimension(
              "designs",
              "options_list",
              "height",
              height
            )
          )
        }
      />
      {selectedBag && (
        <BagDetailsModal close={() => setSelectedBag(null)} bag={selectedBag} />
      )}
      {displayError && (
        <InfoMessage title={displayError.title} text={displayError.text}>
          <div
            className="btn btn-primary bulky dismiss-validation-message"
            onClick={() =>
              dispatch(layoutActions.toggleErrorPopup("designs", false))
            }
          >
            {t("dismiss_error")}
          </div>
        </InfoMessage>
      )}
    </div>
  );
};
