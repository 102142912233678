import React from "react";
import { Link, useLocation } from "react-router-dom";
import routes, { processFirstStep } from "../../constants/routes";
import {useTranslation} from "react-i18next";

import LanguageSwitch from "./LanguageSwitch";

import "../../stylesheets/shared/navbar.scss";

const Navbar = () => {
  const { i18n: { language }} = useTranslation();
  const { pathname } = useLocation();

  let process = (pathname.startsWith(routes.reorder.start.replace(':lang', language)))
    ? "reorder"
    : "register";

  return (
    <header>
      <nav className="navbar navbar-expand-sm navbar-toggleable-sm fixed-top box-shadow bg-white mb-3">
        <div className="navbar-container my-container">
          <Link
            to={(processFirstStep[process] || routes.index).replace(':lang', language)}
            className="navbar-brand"
          >
            <img
              src={`/images/goodbag-site-logo-orange.svg`}
              alt="goodbag Partners"
            />
          </Link>
          <LanguageSwitch />
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
