import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import routes from "../../constants/routes";
import { navigate } from "../shared/routes/CustomBrowserRouter";

import ShopFinder from "./ShopFinder";

class FinderContent extends Component {
  redirectToProcess = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate(routes.new_business.designs);
  };

  render = () => {
    const { t } = this.props;

    return (
      <div className="finder-content">
        <h1
          className="page-heading blue-Regular-40px"
          dangerouslySetInnerHTML={{ __html: t("landing:heading") }}
        />
        <div className="shop-finder">
          <p
            className="finder-label blue-SemiBold-24px"
            dangerouslySetInnerHTML={{ __html: t("landing:search_label") }}
          />
          <ShopFinder />
        </div>
        <div className="page-background-mobile">
          <div className="sticker">
            {t("landing:join_badge_label")}
          </div>
          <div className="waves" />
        </div>
        <div className="enter-shop-details">
          <div className="details-label blue-Regular-16px-left">
            {t("landing:enter_details_label")}
          </div>
          <div
            className="btn btn-outline-primary manual-details-btn mt-2"
            onClick={() => this.redirectToProcess()}
          >
            {t("landing:enter_details_button")}
          </div>
        </div>
      </div>
    );
  };
}

export default withTranslation()(FinderContent);
