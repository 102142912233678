import "../../../utilities/extensions";

import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import { navigate } from "../../shared/routes/CustomBrowserRouter";

import designs from "../../../constants/designs";
import plans from "../../../constants/plans";
import { currency_rates } from "../../../constants/internationals";
import {
  getBagPrice,
  getBagsQuantities,
} from "../../../helpers/ProcessHelpers";

import PreviewSection from "./PreviewSection";
import { UseDesignsQuery } from "../../../services/queries/BgoodProductQueries";

class DesignsPreview extends Component {
  state = {
    height: 130,
  };

  logoContainer = new React.createRef();

  componentDidMount = () => {
    this.adjustHeight();
    window.addEventListener("resize", this.adjustHeight);
  };

  componentWillUnmount = () =>
    window.removeEventListener("resize", this.adjustHeight);

  adjustHeight = () => {
    this.props.bag_logo &&
      this.props.bag_logo.logo.url &&
      this.setState({ height: this.logoContainer.offsetWidth });
  };

  redirectToStep = () => {
    this.props.setEditingStep();
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate(this.props.editRedirect);
  };

  render = () => {
    let { quantities, plan, country, bag_logo, t, intl, toggled } = this.props;

    let { custom_large_bags, custom_medium_bags } = getBagsQuantities(plan, quantities, designs);
    let currency = currency_rates[country] || currency_rates.default;

    const round = (number, decimals = 2) =>
      Number(Math.round(parseFloat(number + "e" + decimals)) + "e-" + decimals);
    const formatBagPrice = (bag_price) =>
      intl.formatNumber(bag_price, {
        style: "currency",
        currency: currency.code,
        currencyDisplay: "symbol",
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });

    let charge_bag_price = round(plans[plan].bag_price * currency.rate);
    let charge_cup_price = round(plans[plan].cup_price * currency.rate);
    let custom_large_bag_price = round(
      getBagPrice("custom_large", custom_large_bags) * currency.rate
    );
    let custom_medium_bag_price = round(
      getBagPrice("custom_medium", custom_medium_bags) * currency.rate
    );

    let chosenDesigns = (quantities.large.custom > 0 ? 1 : 0) + (quantities.medium.custom > 0 ? 1 : 0);
    Object.keys(quantities).forEach((size) =>
      Object.keys(quantities[size]).forEach((design) =>
        quantities[size][design] > 0 && design !== "custom"
          ? (chosenDesigns += 1)
          : null
      )
    );

    let maxHeight = toggled ? (chosenDesigns > 0 ? chosenDesigns * 149 : 0) : 0;

    return (
      <div className="designs-column column">
        <UseDesignsQuery>
          {query => {
            if(query.isLoading) {
              return <div>Loading...</div>;
            }
            return <PreviewSection
              heading={t("designs")}
              link={this.redirectToStep}
              className="designs-section-content"
              name="designs"
              style={{ maxHeight: `${maxHeight}px` }}
            >
              <div className="designs-container">
                {Object.keys(quantities).map((size) =>
                  Object.keys(quantities[size])
                    .filter(
                      (design) =>
                        parseInt(quantities[size][design]) > 0 &&
                        design !== "custom"
                    )
                    .map((design) => (
                      <div className="bag-design" key={design}>
                        <div className="image">
                          <img src={designs[size].bags[design].images[0]} alt="" />
                        </div>
                        <div className="info-container">
                          <div className="bag-name blue-Bold-18px text-left">
                            {designs[size].bags[design].product_type === 'goodcup' ? 'goodcup' : 'goodbag'}
                            <br />
                            <span className="name-value">
                              {designs[size].bags[design].name}
                            </span>
                            <div className="bag-variant">
                              {designs[size].bags[design].i18n_variant_color} • {designs[size].bags[design].i18n_variant_size}
                            </div>
                          </div>
                          <div className="quantity">
                            <span className="volume blue-Regular-14px">
                              {`${t("quantity")} ${
                                quantities[size][design]
                              }`}
                            </span>
                            <div className="amount blue-Bold-14px">
                              {designs[size].bags[design].product_type === 'goodcup' ? 
                                formatBagPrice(
                                  quantities[size][design] * charge_cup_price
                                ) : formatBagPrice(
                                  quantities[size][design] * charge_bag_price
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                )}
                {parseInt(quantities.large.custom) > 0 && (
                  <div className="bag-design">
                    <div className="image">
                      <img
                        src={designs.large.bags.custom_large_light.images[0]}
                        alt=""
                      />
                      <div
                        className="bag-logo-wrapper"
                        ref={(node) => (this.logoContainer = node)}
                        style={{
                          width: "65%",
                          height: `${this.state.height}px`,
                        }}
                      >
                        <div
                          className="bag-logo"
                          style={{
                            width: `${
                              (bag_logo.size.width / bag_logo.parent.width) * 100
                            }%`,
                            height: `${
                              (bag_logo.size.height / bag_logo.parent.height) * 100
                            }%`,
                            marginLeft: `${
                              (bag_logo.position.x / bag_logo.parent.width) * 100
                            }%`,
                            marginTop: `${
                              (bag_logo.position.y / bag_logo.parent.height) * 100
                            }%`,
                          }}
                        >
                          {bag_logo.logo.url && (
                            <img src={bag_logo.logo.url} alt="" />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="info-container">
                      <div className="bag-name blue-Bold-18px text-left">
                        goodbag
                        <br />
                        <span className="name-value">
                          {t("custom")} {designs.large.bags.custom_large_light.i18n_variant_size}
                        </span>
                      </div>
                      <div className="quantity">
                        <span className="volume blue-Regular-14px">
                          {t("quantity")} {quantities.large.custom}
                        </span>
                        <div className="amount blue-Bold-14px">
                          {formatBagPrice(
                            quantities.large.custom * custom_large_bag_price
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {parseInt(quantities.medium.custom) > 0 && (
                  <div className="bag-design">
                    <div className="image">
                      <img
                        src={designs.medium.bags.custom_medium_light.images[0]}
                        alt=""
                      />
                      <div
                        className="bag-logo-wrapper"
                        ref={(node) => (this.logoContainer = node)}
                        style={{
                          width: "65%",
                          height: `${this.state.height}px`,
                        }}
                      >
                        <div
                          className="bag-logo"
                          style={{
                            width: `${
                              (bag_logo.size.width / bag_logo.parent.width) * 100
                            }%`,
                            height: `${
                              (bag_logo.size.height / bag_logo.parent.height) * 100
                            }%`,
                            marginLeft: `${
                              (bag_logo.position.x / bag_logo.parent.width) * 100
                            }%`,
                            marginTop: `${
                              (bag_logo.position.y / bag_logo.parent.height) * 100
                            }%`,
                          }}
                        >
                          {bag_logo.logo.url && (
                            <img src={bag_logo.logo.url} alt="" />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="info-container">
                      <div className="bag-name blue-Bold-18px text-left">
                        goodbag
                        <br />
                        <span className="name-value">
                          {t("custom")} {designs.medium.bags.custom_medium_light.i18n_variant_size}
                        </span>
                      </div>
                      <div className="quantity">
                        <span className="volume blue-Regular-14px">
                          {t("quantity")} {quantities.medium.custom}
                        </span>
                        <div className="amount blue-Bold-14px">
                          {formatBagPrice(
                            quantities.medium.custom * custom_medium_bag_price
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </PreviewSection>
          }}
        </UseDesignsQuery>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  toggled: state.layout.toggles.confirmation.designs,
});

const Reduxified = connect(mapStateToProps)(DesignsPreview);
const WithIntl = injectIntl(Reduxified);

export default withTranslation("reorder")(WithIntl);
