import React, { Component } from "react";

class Toggle extends Component {
  state = {
    toggled: !!this.props.toggled,
  };

  toggle = () =>
    this.setState(
      (state) => ({
        toggled: !state.toggled,
      }),
      () => this.props.onClick(this.state.toggled)
    );

  render = () => {
    let isToggled = this.state.toggled ? "toggled" : "";

    return (
      <div className={`toggle ${isToggled}`} onClick={this.toggle}>
        <div className="toggle-switch" />
      </div>
    );
  };
}

export default Toggle;
