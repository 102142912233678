export const config = {
  MIN_QUANTITY_REGULAR: 1,
  MIN_QUANTITY_REGULAR_REORDER: 5,
  MIN_QUANTITY_CUSTOM: 100,
};

export default {
  cups_large: {
    title: {
      en: 'Cups',
      de: 'Cups',
    }
  },
  medium: {
    title: {
      en: "Model Medium",
      de: "Modell Medium",
    },
    details: {
      size: "42x38x10 cm",
      capacity: "16 l",
    },
    // bags: {
    //   custom: {
    //     name: "Custom",
    //     images: [
    //       "/images/register/designs/bag/medium/medium_empty.png",
    //       "/images/register/designs/scene/medium/pure.png",
    //     ],
    //   },
    //   // donald: {
    //   //   name: "Donald",
    //   //   images: [
    //   //     "/images/register/designs/bag/medium/donald.png",
    //   //     "/images/register/designs/scene/medium/donald.jpg",
    //   //   ],
    //   // },
    //   // travel: {
    //   //   name: "Travel",
    //   //   images: [
    //   //     "/images/register/designs/bag/medium/travel.png",
    //   //     "/images/register/designs/scene/medium/travel.jpg",
    //   //   ],
    //   // },
    //   // sunflowergirl: {
    //   //   name: "Sunflowergirl",
    //   //   images: [
    //   //     "/images/register/designs/bag/medium/sunflowergirl.png",
    //   //     "/images/register/designs/scene/medium/sunflowergirl.jpg",
    //   //   ],
    //   // },
    //   // think: {
    //   //   name: "Think",
    //   //   image: [
    //   //     "/images/register/designs/bag/medium/think.png",
    //   //     "/images/register/designs/scene/medium/think.jpg"
    //   //   ],
    //   // },
    //   // triangle: {
    //   //   name: "Triangle",
    //   //   image: [
    //   //     "/images/register/designs/bag/medium/triangle.png",
    //   //     "/images/register/designs/scene/medium/triangle.jpg"
    //   //   ],
    //   // },
    //   // melon: {
    //   //   name: "Melon",
    //   //   images: [
    //   //     "/images/register/designs/bag/medium/melon.png",
    //   //     "/images/register/designs/scene/medium/melon.jpg",
    //   //   ],
    //   // },
    //   // giraffe: {
    //   //   name: "Giraffe",
    //   //   images: [
    //   //     "/images/register/designs/bag/medium/giraffe.png",
    //   //     "/images/register/designs/scene/medium/giraffe.jpg",
    //   //   ],
    //   // },
    //   // fish: {
    //   //   name: "Fish",
    //   //   images: [
    //   //     "/images/register/designs/bag/medium/fish.png",
    //   //     "/images/register/designs/scene/medium/fish.jpg",
    //   //   ],
    //   // },
    //   // rhino: {
    //   //   name: "Rhino",
    //   //   images: [
    //   //     "/images/register/designs/bag/medium/rhino.png",
    //   //     "/images/register/designs/scene/medium/rhino.jpg",
    //   //   ],
    //   // },
    //   // pure_blue: {
    //   //   name: "Pure Blue",
    //   //   images: [
    //   //     "/images/register/designs/bag/medium/pure-blue.png",
    //   //     "/images/register/designs/scene/medium/pure-blue.jpg",
    //   //   ],
    //   // },
    // },
  },
  large: {
    title: {
      en: "Model Large",
      de: "Modell Large",
    },
    details: {
      size: "42x38x20 cm",
      capacity: "32 l",
    },
    // bags: {
    //   custom: {
    //     name: "Custom",
    //     images: [
    //       "/images/register/designs/bag/large/empty.png",
    //       "/images/register/designs/scene/large/pure.jpg",
    //     ],
    //   },
    //   dark_cat: {
    //     name: "Cat Dark Large",
    //     images: [
    //       "/images/register/designs/bag/large/dark_cat.png",
    //       "/images/register/designs/scene/large/pure.jpg",
    //     ],
    //   },
    //   dark_face: {
    //     name: "Face Large",
    //     images: [
    //       "/images/register/designs/bag/large/dark_face.png",
    //       "/images/register/designs/scene/large/pure.jpg",
    //     ],
    //   },
    //   dark_pure: {
    //     name: "Pure Dark Large",
    //     images: [
    //       "/images/register/designs/bag/large/black.png",
    //       "/images/register/designs/scene/large/pure.jpg",
    //     ],
    //   },
    //   pure: {
    //     name: "Pure Large",
    //     images: [
    //       "/images/register/designs/bag/large/empty.png",
    //       "/images/register/designs/scene/large/pure.jpg",
    //     ],
    //   },
    //   think: {
    //     name: "Think Large",
    //     images: [
    //       "/images/register/designs/bag/large/think.png",
    //       "/images/register/designs/scene/large/think.jpg",
    //     ],
    //   },
    //   face: {
    //     name: "Face",
    //     images: [
    //       "/images/register/designs/bag/large/face.png",
    //       "/images/register/designs/scene/large/face.jpg",
    //     ],
    //   },
    //   mermaids: {
    //     name: "Mermaids",
    //     images: [
    //       "/images/register/designs/bag/large/mermaids.png",
    //       "/images/register/designs/scene/large/mermaids.jpg",
    //     ],
    //   },
    //   leaf: {
    //     name: "Leaf",
    //     images: [
    //       "/images/register/designs/bag/large/leaf.png",
    //       "/images/register/designs/scene/large/leaf.jpg",
    //     ],
    //   },
    //   big_things: {
    //     name: "Little Things Large",
    //     images: [
    //       "/images/register/designs/bag/large/big-things.png",
    //       "/images/register/designs/scene/large/big-things.png",
    //     ],
    //   },
    //   choose_well: {
    //     name: "Choose Well Large",
    //     images: [
    //       "/images/register/designs/bag/large/choose-well.png",
    //       "/images/register/designs/scene/large/choose-well.png",
    //     ],
    //   },
      
    //   // awesome: {
    //   //   name: "100% Awesome",
    //   //   images: [
    //   //     "/images/register/designs/bag/large/awesome.png",
    //   //     "/images/register/designs/scene/large/awesome.jpg",
    //   //   ],
    //   // },
    //   // donald: {
    //   //   name: "Donald",
    //   //   images: [
    //   //     "/images/register/designs/bag/large/donald.png",
    //   //     "/images/register/designs/scene/large/donald.png",
    //   //   ],
    //   // },
    //   // less_plastic: {
    //   //   name: "Less Plastic",
    //   //   images: [
    //   //     "/images/register/designs/bag/large/less-plastic.png",
    //   //     "/images/register/designs/scene/large/less-plastic.jpg",
    //   //   ],
    //   // },
    //   // melon: {
    //   //   name: "Melon",
    //   //   images: [
    //   //     "/images/register/designs/bag/large/melon.png",
    //   //     "/images/register/designs/scene/large/melon.jpg",
    //   //   ],
    //   // },
    //   // pineapple: {
    //   //   name: "Pineapple L",
    //   //   images: [
    //   //     "/images/register/designs/bag/large/pineapple.png",
    //   //     "/images/register/designs/scene/large/pineapple.jpg",
    //   //   ],
    //   // },
    //   // goodbag: {
    //   //   name: "goodbag",
    //   //   images: [
    //   //     "/images/register/designs/bag/large/goodbag.png",
    //   //     "/images/register/designs/scene/large/goodbag.jpg",
    //   //   ],
    //   // },
    //   // less_meat: {
    //   //   name: "Less Meat",
    //   //   images: [
    //   //     "/images/register/designs/bag/large/less-meat.png",
    //   //     "/images/register/designs/scene/large/less-meat.jpg",
    //   //   ],
    //   // },
    //   // unicorn: {
    //   //   name: "Unicorn Large",
    //   //   images: [
    //   //     "/images/register/designs/bag/large/unicorn.png",
    //   //     "/images/register/designs/scene/large/unicorn.jpg",
    //   //   ],
    //   // },
    //   // travel: {
    //   //   name: "Travel Large",
    //   //   images: [
    //   //     "/images/register/designs/bag/large/travel.png",
    //   //     "/images/register/designs/scene/large/travel.jpg",
    //   //   ],
    //   // },
    //   // triangle: {
    //   //   name: "Triangle Large",
    //   //   images: [
    //   //     "/images/register/designs/bag/large/triangle.png",
    //   //     "/images/register/designs/scene/large/triangle.jpg",
    //   //   ],
    //   // },
    // },
  },
};
