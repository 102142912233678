export default {
  SAVE_CUSTOM_BAG: "SAVE_CUSTOM_BAG",
  SAVE_SHOP_DETAILS: "SAVE_SHOP_DETAILS",
  SAVE_BUSINESS_GOOGLE_DETAILS: "SAVE_BUSINESS_GOOGLE_DETAILS",
  SET_TOKEN: "SET_REGISTERED",
  SET_FIRST_ORDER: 'SET_FIRST_ORDER',
  SET_PRESELECTED_PLAN: "SET_PRESELECTED_PLAN",
  SET_PLAN: "SET_PLAN",
  SET_EDITING_STEP: "SET_EDITING_STEP",
  HANDLE_INPUT_CHANGE: "HANDLE_INPUT_CHANGE",
  HANDLE_QUANTITY_CHANGE: "HANDLE_QUANTITY_CHANGE",
  HANDLE_COUNTRY_SELECT: "HANDLE_COUNTRY_SELECT",
  TOGGLE_SAME_ADDRESS: "TOGGLE_SAME_ADDRESS",
  RESET_REORDER: "RESET_REORDER",
};
