const routes = {
  index: "/:lang",
  reorder: {
    index: "/:lang/reorder/:action?",
    start: "/:lang/reorder",
    designs: "/:lang/reorder/designs",
    details: "/:lang/reorder/details",
    confirmation: "/:lang/reorder/confirmation",
    completed: "/:lang/reorder/completed",
  },
  register: {
    index: "/:lang/register/:action?",
    start: "/:lang/register",
    details: "/:lang/register/details",
    plan: "/:lang/register/plan",
    designs: "/:lang/register/designs",
    confirmation: "/:lang/register/confirmation",
    completed: "/:lang/register/completed",
  },
  new_business: {
    index: "/:lang/new/:action?/:google_place_id?",
    start: "/:lang/new",
    designs: "/:lang/new/designs",
    details: "/:lang/new/details",
    confirmation: "/:lang/new/confirmation",
    completed: "/:lang/new/completed",
  },
  terms: "/:lang/terms",
  privacy: "/:lang/privacy",
};

export const processFirstStep = {
  register: routes.index,
  reorder: routes.reorder.designs,
  new_business: routes.new_business.designs,
};

export default routes;
