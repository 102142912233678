import { plainRequest } from "../../utilities/request";
import { BGOOD_PRODUCTS_URL } from "../../constants/endpoints";
import i18n from "../../config/i18n";

/**
 * This service is responsible for handling business-related requests to the backend.
 */
class BgoodAPIService {
  /**
   * Fetches information about a registered shop (contact info, current plan, addresses).
   * @param {string} token
   * @return {Promise<{success: boolean, details: object}|{success: boolean}>}
   */
  static async list(token) {
    try {
      return await plainRequest(`${BGOOD_PRODUCTS_URL}?lang=${i18n.language.split("-")[0]}`, "GET", null, {
        Authorization: token,
        "Content-Type": "application/json",
      });
    } catch (ex) {
      return { success: false };
    }
  }
}

export default BgoodAPIService;
