import actionTypes from "../constants/action_types/layout";

const initialState = {
  toggles: {
    details: {
      social: true,
      app_preview: true,
    },
    confirmation: {
      details: true,
      contact: true,
      social: true,
      plan: true,
      designs: true,
      shipping_address: true,
      billing_address: false,
    },
  },
  checkboxes: {
    confirmation: false,
  },
  dimensions: {
    designs: {
      options_list: {
        height: null,
      },
    },
  },
  error_popups: {
    details: false,
    plan: false,
    designs: false,
    confirmation: false,
  },
  popups: {
    privacy: false,
    terms: false,
    shipping_calculator: false,
  },
  languageSwitchToggled: false,
};

export default function layout(state = initialState, action) {
  switch (action.type) {
    case actionTypes.TOGGLE_PREVIEW_PANEL: {
      return {
        ...state,
        toggles: {
          ...state.toggles,
          confirmation: {
            ...state.toggles.confirmation,
            [action.panel]: !state.toggles.confirmation[action.panel],
          },
        },
      };
    }

    case actionTypes.TOGGLE_SHOP_DETAILS_PANEL: {
      return {
        ...state,
        toggles: {
          ...state.toggles,
          details: {
            ...state.toggles.details,
            [action.panel]: !state.toggles.details[action.panel],
          },
        },
      };
    }

    case actionTypes.TOGGLE_CONSENT_CHECKBOX: {
      return {
        ...state,
        checkboxes: {
          ...state.checkboxes,
          [action.checkbox]: !state.checkboxes[action.checkbox],
        },
      };
    }

    case actionTypes.TOGGLE_ERROR_POPUP: {
      return {
        ...state,
        error_popups: {
          ...state.error_popups,
          [action.name]: action.toggled ? action.error : action.toggled,
        },
      };
    }

    case actionTypes.TOGGLE_LANG_SWITCH: {
      return {
        ...state,
        languageSwitchToggled:
          action.toggled !== null ? action.toggled : !state.languageSwitchToggled,
      };
    }

    case actionTypes.TOGGLE_POPUP: {
      return {
        ...state,
        popups: {
          ...state.popups,
          [action.name]: action.toggled,
        },
      };
    }

    case actionTypes.RESET_LAYOUT: {
      return initialState;
    }

    case actionTypes.HIDE_ERROR_POPUPS: {
      return {
        ...state,
        error_popups: initialState.error_popups,
      };
    }

    case actionTypes.UPDATE_DIMENSION: {
      return {
        ...state,
        dimensions: {
          ...state.dimensions,
          [action.step]: {
            ...state.dimensions[action.step],
            [action.element]: {
              ...state.dimensions[action.step][action.element],
              [action.dimension]: action.value,
            },
          },
        },
      };
    }

    default:
      return state;
  }
}
