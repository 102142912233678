import React from "react";

/**
 * Custom hook that will store the component mount state
 * and update it on mount/unmount.
 * Returns the current mount status.
 * @return {function(): boolean}
 */
const useIsMounted = () => {
  const isMounted = React.useRef(false);
  React.useEffect(() => {
    isMounted.current = true;
    return () => (isMounted.current = false);
  }, []);
  return () => isMounted.current;
};

/**
 * * Custom async useState hook.
 * The caveat here is that the state variable will be updated
 * only if the component is mounted.
 * @param defaultValue
 * @return {[unknown, function(*=): void]}
 */
export const useAsyncState = (defaultValue) => {
  const isMounted = useIsMounted();
  const [value, setRawValue] = React.useState(defaultValue);
  const setValue = React.useCallback((newValue) => {
    if (isMounted()) {
      setRawValue(newValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return [value, setValue];
};
