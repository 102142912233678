import React, { Component } from "react";
import { connect } from "react-redux";
import layoutActions from "../../actions/layout";
import { withTranslation } from "react-i18next";

import Toggle from "../shared/Toggle";

import categories from "../../constants/categories";

class AppPreview extends Component {
  state = {
    ohToggled: false,
  };

  toggleOh = () => this.setState((state) => ({ ohToggled: !state.ohToggled }));

  render = () => {
    let { t, details, headless, toggled, toggle } = this.props;
    let isToggled = headless ? "toggled" : toggled ? "toggled" : "";

    let isLogoEmpty = !details.logo.url ? "empty" : "";
    let categoryImage =
      details.shop_details.category &&
      categories.find(
        (c) => c.id === parseInt(details.shop_details.category, 10)
      ).name;

    let isOhToggled = this.state.ohToggled ? "toggled" : "";
    let ohToggleIconDirection = this.state.ohToggled ? "up" : "down";

    return (
      <div className={`app-preview-container ${isToggled}`}>
        {!headless && (
          <div className="app-preview-heading d-flex justify-content-between align-items-center">
            <h4 className="blue-Bold-20px-left">
              {t("general:app_preview_heading")}
            </h4>
            <Toggle onClick={toggle} toggled={toggled} />
          </div>
        )}
        <div className="app-preview-content">
          <div className="shop-info">
            <div className="black-bold-16px-left name">
              {details.shop_details.name || t("general:app_preview_shop_name")}
            </div>
            <div className="logo-address">
              <div className={`logo ${isLogoEmpty}`}>
                <div className="image-wrapper">
                  {details.logo.url ? (
                    <img
                      src={details.logo.url}
                      alt={details.shop_details.name || "Shop"}
                    />
                  ) : (
                    "Logo"
                  )}
                </div>
              </div>
              <div className="address-container blue-Light-12px">
                <div className="blue-Light-12px-left address">
                  <div className="street">
                    {details.shop_details.address ||
                      t("general:app_preview_address")}
                  </div>
                </div>
                <div className="distance">
                  <span className="blue-SemiBold-12px-left">
                    {t("general:app_preview_distance")}:
                  </span>{" "}
                  48 m
                </div>
                <div className={`opening-hours ${isOhToggled}`}>
                  {Object.keys(details.opening_hours).map((key, index) => {
                    let isFirst = index === 0 ? "first" : "";
                    let isClosed =
                      details.opening_hours[key].open &&
                      details.opening_hours[key].from &&
                      details.opening_hours[key].to
                        ? ""
                        : "closed";
                    let intervalString = `${details.opening_hours[key].from} - ${details.opening_hours[key].to}`;
                    let blockDisplay =
                      intervalString.length > 15 ? "block" : "";
                    return (
                      <div
                        className={`day ${isClosed} ${isFirst} ${blockDisplay}`}
                        key={key}
                      >
                        <span className="blue-SemiBold-12px-left day-name">
                          {t(`general:opening_hours_${key.toLowerCase()}`)}:
                        </span>
                        <span className="blue-Light-12px-left interval">
                          {isClosed
                            ? t("general:opening_hours_closed")
                            : intervalString}
                        </span>
                        {isFirst && (
                          <i
                            className={`fas fa-chevron-${ohToggleIconDirection} toggle-opening-hours`}
                            onClick={this.toggleOh}
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          {details.shop_details.category && (
            <div className="category">
              <img
                src={`${process.env.PUBLIC_URL}/images/categories/${categoryImage}@2x.png`}
                alt="Category"
              />
              <span className="blue-Light-12px-left">
                {t(
                  `general:category_${
                    categories.find(
                      (c) => c.id === details.shop_details.category
                    ).name
                  }`
                )}
              </span>
            </div>
          )}
          <div className="blue-Regular-12px-left shop-description">
            {details.shop_details.description ||
              t("general:app_preview_description")}
          </div>
          <div className="shop-coupons">
            <div
              className={`coupon ${
                details.coupon_options.offers_project_tree ? "" : "disabled"
              }`}
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/coupons/plant_a_tree.png`}
                alt="Plant a tree"
              />
            </div>
            <div
              className={`coupon ocean ${
                details.coupon_options.offers_project_ocean ? "" : "disabled"
              }`}
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/coupons/fish_plastic.png`}
                alt="Fish plastic"
              />
            </div>
            <div
              className={`coupon discount ${
                details.coupon_options.offers_discount ? "" : "disabled"
              }`}
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/coupons/discount.png`}
                alt="Discount"
              />
              <span className="discount-text">
                {details.coupon_options.custom_text ||
                  details.coupon_options.discount_text}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  details: state.register,
  toggled: state.layout.toggles.details.app_preview,
});

const mapDispatchToProps = (dispatch) => ({
  toggle: () => dispatch(layoutActions.toggleShopDetailsPanel("app_preview")),
});

let reduxWrapped = connect(mapStateToProps, mapDispatchToProps)(AppPreview);
export default withTranslation()(reduxWrapped);
