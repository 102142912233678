import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useIntl } from "react-intl";

import { currency_rates } from "../../../constants/internationals";
import plans from "../../../constants/plans";
import designsConfig from "../../../constants/designs";
import {
  getBagPrice,
  getBagsQuantities,
} from "../../../helpers/ProcessHelpers";
import { retail_price } from "../../../constants/prices";

export default () => {
  const intl = useIntl();
  const { t } = useTranslation("reorder");
  const {
    shipping_address: { country_code },
    selected_plan,
    // current_plan,
    designs,
  } = useSelector((s) => s.reorder);

  let currency = currency_rates[country_code] || currency_rates.default;
  let { charge_bags, charge_cups, custom_large_bags, custom_medium_bags, charge_bags_free } = getBagsQuantities(
    selected_plan,
    designs,
    designsConfig,
  );
  // If user is currently on standard or premium plan
  // they used their free bags, so we no longer offer free bags to them
//   if (current_plan.name && current_plan.name !== "ondemand") {
//     charge_bags += charge_bags_free;
//     charge_bags_free = 0;
//   }

  const profit_goodbags =
    (charge_bags + custom_large_bags + custom_medium_bags + charge_bags_free) * retail_price.goodbag -
    charge_bags * plans[selected_plan].bag_price -
    getBagPrice("custom_large", designs.large.custom) * custom_large_bags - getBagPrice("custom_medium", designs.medium.custom) * custom_medium_bags;

  const profit_goodcups =
    (charge_cups) * retail_price.goodcup -
    charge_cups * plans[selected_plan].cup_price;


  console.log('profits', charge_bags, profit_goodbags, charge_cups,  profit_goodcups)
  const profit = profit_goodbags + profit_goodcups;

  const formatPrice = (price, decimals = 2) =>
    intl.formatNumber(price * currency.rate, {
      style: "currency",
      currency: currency.code,
      currencyDisplay: "symbol",
      maximumFractionDigits: decimals,
      minimumFractionDigits: decimals,
    });

  return (
    <div className="potential-profit">
      <h6 className="blue-Bold-18px text-left">
        {t("reorder_potential_profit_heading")}
      </h6>
      <div className="d-flex justify-content-between">
        <span className="blue-Regular-14px">
          {t("reorder_potential_profit_your_profit")}
        </span>
        <span className="blue-Bold-14px">{formatPrice(profit)}</span>
      </div>
      <div className="blue-Regular-14px">
        {t("reorder_potential_profit_text", { price: formatPrice(retail_price.goodbag), price_cups: formatPrice(retail_price.goodcup) })}
      </div>
    </div>
  );
};
