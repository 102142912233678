import actionTypes from "../constants/action_types/settings";

const initialState = {
  country_code: "",
};

export default function settings(state = initialState, action) {
  switch (action.type) {
    case actionTypes.SET_COUNTRY_CODE: {
      return {
        ...state,
        country_code: action.country_code,
      };
    }

    default:
      return state;
  }
}
