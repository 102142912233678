import React, { Component } from "react";

import { withTranslation } from "react-i18next";

import { connect } from "react-redux";
import registerActions from "../../../actions/register";
import routes from "../../../constants/routes";

import PreviewSection from "./PreviewSection";
import ContactInfoPreviewSection from "./ContactInfoPreviewSection";
import AppPreview from "../AppPreview";

import { navigate } from "../../shared/routes/CustomBrowserRouter";

class StepsPreview extends Component {
  redirectToStep = () => {
    this.props.setEditingStep("details");
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate(routes.register.details);
  };

  render = () => {
    let { contact_info, social_media, t } = this.props;

    return (
      <div className="shop-details-column column">
        <PreviewSection
          heading={t("confirmation:shop_details")}
          link={this.redirectToStep}
          name="details"
        >
          <AppPreview headless={true} />
        </PreviewSection>
        <ContactInfoPreviewSection
          contact_info={contact_info}
          redirectToStep={this.redirectToStep}
        />
        <PreviewSection
          heading={t("confirmation:social_media")}
          className="social-media-section"
          link={this.redirectToStep}
          name="social"
        >
          <div className="info-row">
            <div className="blue-Bold-14px text-left">
              {t("confirmation:website")}
            </div>
            <div className="blue-Regular-14px website">
              {social_media.website || "-"}
            </div>
          </div>
          <div className="info-row">
            <div className="blue-Bold-14px text-left">
              {t("confirmation:facebook")}
            </div>
            <div className="blue-Regular-14px facebook">
              {social_media.facebook || "-"}
            </div>
          </div>
          <div className="info-row">
            <div className="blue-Bold-14px text-left">
              {t("confirmation:instagram")}
            </div>
            <div className="blue-Regular-14px instagram">
              {social_media.instagram || "-"}
            </div>
          </div>
        </PreviewSection>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  contact_info: state.register.contact_info,
  social_media: state.register.social_media,
});

const mapDispatchToProps = (dispatch) => ({
  setEditingStep: (step) => dispatch(registerActions.setEditingStep(step)),
});

let reduxWrapped = connect(mapStateToProps, mapDispatchToProps)(StepsPreview);

export default withTranslation()(reduxWrapped);
