import React from "react";
import CONFIG from '../../config';
import {useTranslation} from "react-i18next";
import privacy_text from './texts/privacy_texts';

import "../../stylesheets/legal.scss";

export default () => {
  const { i18n: { language } } = useTranslation();

  return (
    <div className="col-12 col-md-6 mx-auto py-5 legal">
      {privacy_text[language] || privacy_text[CONFIG.DEFAULT_LANGUAGE]}
    </div>
  );
};

