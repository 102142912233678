import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";

import { connect } from "react-redux";
import registerActions from "../../../actions/register";

import { urlToFile } from "../../../utilities/files";

import InfoBox from "../../shared/InfoBox";

import { ScaleIn } from "../../hocs/AnimationHOC";

import ReactCrop from "react-image-crop";
import "react-image-crop/lib/ReactCrop.scss";

/**
 * The basic logic behind the whole component can be broken down in a few simple steps:
 * 1. When the "Upload logo/Change logo" button is clicked, a hidden file input is triggered
 * 		and a prompt shows up.
 * 2. When a file is chosen it's being read and loaded into the cropper tool. The tool
 * 		would pop up and the user can crop his picture/logo within the dedicated
 * 		rectangle area and choose which part to be saved.
 * 3. When he clicks on "Save" the selected quadratic area has already been cropped from the uploaded image
 *		and stored in the blob. When "Save" is clicked the image from the blob is just converted(actually downloaded)
 *		to a file and saved in the redux state.
 */
class UploadLogo extends Component {
  state = {
    cropperVisible: false,
    replaceImage: false,
    upload_error: false,
    loading: false,
    preview: this.props.logo.url,
    src: this.props.logo.url,
    crop: {
      unit: "%",
      width: 100,
      x: 0,
      y: 0,
      aspect: 1,
    },
  };

  inputRef = new React.createRef();

  UNSAFE_componentWillReceiveProps = ({ logo }) =>
    this.setState({ preview: logo.url });

  /**
   * Attach event listener to the document body when the file select dialog is opened
   * in order to be able to check if the "Cancel" button was clicked
   * or a file was uploaded
   */
  initializeFileSelect = () => (document.body.onfocus = this.checkFileSelect);

  handleFileUpload = (ev) => this.readImageUpload(ev.target);

  /**
   * This method will get the uploaded image from the file select and
   * will check if its dimensions are less than the required ones
   * (400px x 400px). If positive it will display an error message.
   * If not it will load the image into the cropper tool.
   * @param {HTMLElement} input
   * @returns {void}
   */
  readImageUpload = (input) => {
    let { t } = this.props;

    if (input.files && input.files[0]) {
      this.setState({ loading: true, upload_error: false }, () => {
        let img = new Image();
        img.src = window.URL.createObjectURL(input.files[0]);
        img.onload = () => {
          let width = img.naturalWidth,
            height = img.naturalHeight;

          window.URL.revokeObjectURL(img.src);
          if (width < 100 || height < 100)
            return this.setState({
              upload_error: t("general:uploader_error_size"),
              src: "",
              loading: false,
            });

          if (!input.files[0].name.match(/.(jpg|jpeg|png|gif)$/i))
            return this.setState({
              upload_error: t("general:uploader_error_format"),
              src: "",
              loading: false,
            });

          let reader = new FileReader();
          reader.onload = (e) =>
            this.setState({
              upload_error: false,
              loading: false,
              src: e.target.result,
              cropperVisible: true,
            });
          reader.readAsDataURL(input.files[0]);
        };
      });
    }
  };

  /**
   * Check if a file was selected or the "Cancel" button was clicked
   * in the file select dialog window.
   */
  checkFileSelect = () => {
    let cropperVisible = true;

    if (
      this.inputRef &&
      !this.inputRef.value.length &&
      !this.state.replaceImage
    )
      cropperVisible = false;

    this.setState({ cropperVisible });
    document.body.onfocus = null;
  };

  onCropComplete = (crop) => this.makeClientCrop(crop);

  onCropChange = (crop) => this.setState({ crop });

  onImageLoaded = (image) => (this.imageRef = image);

  makeClientCrop = async (crop) => {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
    }
  };

  /**
   * This method loads the uploaded image and makes the actual crop
   * and stores it in the blob storage
   * @param {File} image
   * @param {object} crop
   * @param {string} fileName
   * @returns {Promise<void>}
   */
  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) return;

        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }

  /**
   * Save the cropped image preview and file
   */
  saveLogo = async () => {
    let { croppedImageUrl } = this.state;

    let data = {
      url: croppedImageUrl,
      file: await urlToFile(croppedImageUrl, "logo.jpg", "image/jpg"),
    };

    this.props.saveLogo(data);
    this.setState({ cropperVisible: false });
  };

  openFilePrompt = (replacing) => {
    if (this.inputRef.current !== undefined) return;
    this.setState({ replaceImage: replacing });
    this.inputRef.click();
  };

  closeCropper = () =>
    this.setState({ cropperVisible: false, replaceImage: false });

  render = () => {
    let { t } = this.props;
    let {
      crop,
      src,
      upload_error,
      preview,
      cropperVisible,
      loading,
    } = this.state;
    let isEmpty = !this.state.preview ? "empty" : "";

    return (
      <div className="upload-logo-container">
        <h4 className="blue-Bold-20px-left">{t("details:logo_heading")}</h4>
        <InfoBox text={t("details:logo_info_box")} />
        <input
          type="file"
          accept=".jpg,.png,.jpeg,.gif"
          className="image-uploader-input"
          ref={(node) => (this.inputRef = node)}
          onChange={this.handleFileUpload}
          onClick={this.initializeFileSelect}
        />
        <div className={`logo-container ${isEmpty}`}>
          <div className="image-wrapper">
            {preview ? <img src={preview} alt="Logo" /> : "Logo"}
          </div>
        </div>
        <div
          className="btn btn-primary upload-logo-btn"
          onClick={() => this.openFilePrompt(false)}
        >
          {preview ? t("details:logo_change") : t("details:logo_upload")}
        </div>
        {cropperVisible && (
          <div className="logo-cropper-container">
            <ScaleIn className="cropper-content">
              <div className="cropper-title d-flex justify-content-between align-items-center">
                <h6 className="blue-Bold-20px-left">
                  {upload_error
                    ? t("details:logo_cropper_error_heading")
                    : loading
                    ? t("details:logo_cropper_loading")
                    : t("details:logo_cropper_heading")}
                </h6>
                <img
                  src={`${process.env.PUBLIC_URL}/images/assets/close.svg`}
                  className="close-logo-crop"
                  onClick={this.closeCropper}
                  alt="x"
                />
              </div>
              <div className="image-container">
                {upload_error ? (
                  <span
                    className="blue-Regular-16px-center logo-upload-error"
                    dangerouslySetInnerHTML={{
                      __html: this.state.upload_error,
                    }}
                  />
                ) : (
                  loading && (
                    <div className="lds-ring">
                      <div />
                      <div />
                      <div />
                      <div />
                    </div>
                  )
                )}
                {!loading && src && (
                  <ReactCrop
                    src={src}
                    crop={crop}
                    onImageLoaded={this.onImageLoaded}
                    onComplete={this.onCropComplete}
                    onChange={this.onCropChange}
                    onDragStart={this.removeTranslate}
                  />
                )}
              </div>
              <div className="cropper-controls d-flex justify-content-between align-items-center">
                {loading ? null : upload_error ? (
                  <Fragment>
                    <span />
                    <div
                      className="btn btn-primary"
                      onClick={() => this.openFilePrompt(true)}
                    >
                      {t("details:logo_cropper_try_again")}
                    </div>
                  </Fragment>
                ) : (
                  <Fragment>
                    <span onClick={() => this.openFilePrompt(true)}>
                      {t("details:logo_cropper_replace")}
                    </span>
                    <div className="btn btn-primary" onClick={this.saveLogo}>
                      {t("details:logo_cropper_save")}
                    </div>
                  </Fragment>
                )}
              </div>
            </ScaleIn>
          </div>
        )}
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  logo: state.register.logo,
});

const mapDispatchToProps = (dispatch) => ({
  saveLogo: (logo) => dispatch(registerActions.saveLogo(logo)),
});

let reduxWrapped = connect(mapStateToProps, mapDispatchToProps)(UploadLogo);
export default withTranslation()(reduxWrapped);
