import React, { Component } from "react";

import { connect } from "react-redux";
import layoutActions from "../../../actions/layout";
import { withTranslation } from "react-i18next";

import {
  adjustProcessNavigationScroll,
  goToFirstIncompleteStep,
} from "../../../helpers/ProcessHelpers";

import ContactUs from "../ContactUs";
import ContactFormDesigns from "./ContactFormDesigns";
import InfoMessage from "../../shared/InfoMessage";
import BagDetailsModal from "./BagDetailsModal";
import PlanOfferings from "./PlanOfferings";
import DesignOptionsList from "./DesignOptionsList";
import ChooseDesignsSummary from "../summary/ChooseDesignsSummary";

import "../../../stylesheets/register/designs.scss";
import registerActions from "../../../actions/register";

class ChooseDesignsStep extends Component {
  state = {
    showContactForm: false,
    showBagDetails: false,
    bagSelected: {
      size: null,
      name: null,
    },
  };

  componentDidMount = () => {
    goToFirstIncompleteStep("register");
    adjustProcessNavigationScroll("designs");
  };

  toggleForm = (toggled) => this.setState({ showContactForm: toggled });

  toggleBagDetails = (bag) =>
    this.setState({ showBagDetails: !!bag, bagSelected: bag });

  render = () => {
    if (!this.props.plan) return null;

    let { t, displayError } = this.props;

    return (
      <div className="choose-designs-step">
        <div className="my-container">
          <PlanOfferings />
          <DesignOptionsList
            step="register"
            showBagDetails={this.toggleBagDetails}
            quantities={this.props.quantities}
            plan={this.props.plan}
            custom_logo={this.props.custom_logo}
            saveLogo={this.props.saveLogo}
            country={this.props.country}
            setQuantity={this.props.setQuantity}
            updateDesignsHeight={this.props.updateDesignsHeight}
          />
        </div>
        <div className="mobile-summary">
          <ChooseDesignsSummary />
        </div>
        <ContactUs
          heading={t("designs:form_heading")}
          text={t("designs:form_text")}
          action={t("designs:form_action")}
          onClick={() => this.toggleForm(true)}
        />
        {this.state.showBagDetails && (
          <BagDetailsModal
            close={() => this.toggleBagDetails(null)}
            bag={this.state.bagSelected}
          />
        )}
        {this.state.showContactForm && (
          <ContactFormDesigns close={() => this.toggleForm(false)} />
        )}
        {this.props.displayError && (
          <InfoMessage title={displayError.title} text={displayError.text}>
            <div
              className="btn btn-primary bulky dismiss-validation-message"
              onClick={() => this.props.toggleError(false)}
            >
              {t("summary:dismiss_error")}
            </div>
          </InfoMessage>
        )}
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  plan: state.register.plan,
  quantities: state.register.designs,
  custom_logo: state.register.bag_logo,
  country: state.register.shop_details.country,
  displayError: state.layout.error_popups.designs,
});

const mapDispatchToProps = (dispatch) => ({
  toggleError: (toggled) =>
    dispatch(layoutActions.toggleErrorPopup("designs", toggled)),
  setQuantity: (size, design, quantity) =>
    dispatch(registerActions.handleQuantityChange(size, design, quantity)),
  updateDesignsHeight: (height) =>
    dispatch(
      layoutActions.updateDimension("designs", "options_list", "height", height)
    ),
  saveLogo: (details) => dispatch(registerActions.saveCustomBag(details)),
});

let reduxWrapped = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChooseDesignsStep);

export default withTranslation()(reduxWrapped);
