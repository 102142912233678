import React, { Fragment } from "react";

export default {
  en: (
    <Fragment>
      <h2>
        Terms of use of bgood GmbH
        <br />
        for the use of the goodbag website and app for business
      </h2>
      <div>As of: May 2020</div>
      <ol className="main-list mt-4">
        <li>
          <span className="li-title">Scope of application</span>
          <ol>
            <li>
              The following terms of use (hereinafter "
              <strong>Terms of Use</strong>") shall apply to all business
              relations between bgood GmbH, Laimgrubengasse 19/7, 1060 Vienna,
              companies’ register no. FN 442550i (hereinafter "
              <strong>goodbag</strong>") as operator of the goodbag smartphone
              app (hereinafter "<strong>App</strong>") as well as of the goodbag
              website{" "}
              <a
                href="https://www.goodbag.io"
                target="_blank"
                rel="noreferrer noopener"
              >
                www.goodbag.io
              </a>{" "}
              (hereinafter "<strong>Website</strong>") and businesses who use
              the App and/or the Website (hereinafter the "
              <strong>Customer</strong>"; the Customer and goodbag together the
              "<strong>Parties</strong>").
            </li>
            <li>
              By accepting these Terms of Use on the Website, the Customer
              agrees to the contents of these Terms of Use..
            </li>
            <li>
              goodbag expressly objects to any diverging terms and conditions of
              the Customer. Diverging or supplementary terms and conditions of
              the Customer shall not become part of the contract even if they
              have not expressly been objected to. Deviations from these Terms
              of Use, supplementary agreements or any opposing terms and
              conditions of the Customer shall only be effective if they are
              confirmed by goodbag in writing.
            </li>
            <li>
              goodbag reserves the right to change, complement to or to remove
              the App’s scope of functions in whole or in part at any time and
              without notice. The Customer has no right to a certain range of
              functions of the App.
            </li>
          </ol>
        </li>

        <li>
          <span className="li-title">Order of goodbag products</span>
          <ol>
            <li>
              By sending the order, the Customer submits an offer – which is
              binding for him/her – to conclude a contract with goodbag on the
              purchase of the contents of the items ordered. This offer is
              accepted and thus, the contract is concluded once the order
              confirmation is sent to the Customer via e-mail to the e-mail
              address provided by the Customer. goodbag reserves the right to
              request proof of identity and creditworthiness of the Customer
              before accepting an order. Any offers made by goodbag to conclude
              a contract are subject to change and non-binding and only
              constitute an invitation to the Customer to place an order.
            </li>
            <li>
              Unless otherwise agreed, the prices, excluding statutory VAT,
              shown during the order process shall be invoiced.
            </li>
            <li>
              Should other export, import or other duties become due in the
              course of the shipment of the product, these shall be borne by the
              Customer.
            </li>
            <li>
              If the Customer is eligible to receive goodbags free of charge
              goodbag will do its best to deliver the selected designs. For the
              goodbags that are delivered free of charge goodbag remains the
              right to change the designs if the situation requires it (e.g.
              certain designs are low on stock).
            </li>
            <li>
              If the Customer orders a custom printing on the bags the Customer
              is responsible for delivering the raw data for printing as a PDF
              document including the pantone color codes for all used colors.
              goodbag will use the colors selected by the Customer but cannot be
              held responsible if the colors look different than expected due to
              the material of the bags.
            </li>
            <li>
              After completing the order process the Customer will receive an
              invoice sent to the Customer via e-mail to the e-mail address
              provided by the Customer. This invoice has to be paid within 14
              days upon it is received by the Customer.
            </li>
            <li>
              If the Customer defaults in payment, goodbag is entitled to
              invoice default interests amounting to 9 % p.a. from the
              respective due date. Furthermore, in the event of default in
              payment, the Customer undertakes to reimburse to goodbag the costs
              incurred (e.g. cancellation fees for payment service) as well as
              reminder and collection fees, insofar as they are necessary for
              the appropriate legal prosecution and are proportionate to the
              claim pursued. The assertion of further rights and claims shall
              remain unaffected thereof.
            </li>
            <li>
              Stated delivery periods shall only be deemed as approximate and
              non-binding, unless otherwise expressly agreed as binding.
            </li>
            <li>
              The ordered goods shall be delivered to the delivery address
              specified by the Customer using an customary shipping method (e.g.
              shipment by postal services) at goodbag’s option.
            </li>
            <li>The goods are shipped at the risk of the Customer.</li>
            <li>
              The goods shall remain the property of goodbag until complete
              payment.
            </li>
          </ol>
        </li>

        <li>
          <span className="li-title">Subscription to the goodbag Service</span>
          <ol>
            <li>
              By completing the online order the Customer subscribes to one of
              three different goodbag subscription plans and is eligible for the
              benefits which can be found online on the goodbag Website{" "}
              <span className="blue-link">
                https://partner.goodbag.io/register/plan.
              </span>
            </li>
            <li>
              By subscribing to the Standard or Premium plan the Customer
              purchases the related services for minimum period of 12 months
              with a yearly subscription (hereinafter "
              <strong>Yearly Subscription</strong>"). The Customer will receive
              an invoice including the 12 month subscription fee and the
              invoiceable amount for the number of ordered bags. The Customer
              agrees to pay for the Yearly Subscription upfront for each 12
              month period.
            </li>
            <li>
              The yearly Subscription starts with the date where the selected
              Plan is ordered by the Customer.
            </li>
            <li>
              The Yearly Subscription is automatically renewed every 12 months
              (hereinafter "<strong>Renewal Date</strong>") if not canceled by
              the Customer within the Cancelation period (hereinafter "
              <strong>Cancelation Date</strong>").
            </li>
            <li>
              The latest possible Cancelation Date is that last day of month in
              before the month of the Renewal Date. (e.g. if the initial
              Subscription was ordered on the 18th of April 2020 the Renewal
              Date is the 18th of April 2021 and the latest Cancelation Date is
              the 31st of March 2021).
            </li>
            <li>
              The Customer can cancel the subscription at any time by sending an
              Email to{" "}
              <a href="mailto:customer@goodbag.io">customer@goodbag.io</a>{" "}
              before the Cancelation Date.
            </li>
          </ol>
        </li>

        <li>
          <span className="li-title">Service of the goodbag App</span>
          <ol>
            <li>
              <strong>goodbag</strong> operates the goodbag App. By completing
              the online order the Customer subscribes to the goodbag service
              and signs up for an online profile within the goodbag App which
              allows her/him to display her/his shops in the goodbag App
              (hereinafter "<strong>Partner Shops</strong>"). By using the
              goodbag bag in Partner Shops, the endusers (hereinafter "
              <strong>Users</strong>") of the goodbag app can either redeem
              various vouchers (hereinafter "<strong>Coupons</strong>") at
              Partner Shops or support various social projects, in particular
              the planting of trees (hereinafter "
              <strong>Social Project</strong>").
            </li>
            <li>
              By signing up to the goodbag service the Customer provides all
              relevant information about the store (the opening hours, short
              description, logo,..). This information is added to the Customers
              profile in the goodbag App and on the goodbag Website. The
              Customer grants goodbag the right to use this information and make
              it publicly available for the sole purpose of displaying the
              Customer shop as a gooodbag Partner shop.
            </li>
            <li>
              By signing up to the goodbag service the Customer provides legal
              information about the business (owner, address, VAT number,..).
              The Customer is responsible to provide this information correctly
              to the best of her or his knowledge.
            </li>
            <li>
              Upon redemption, the Users can choose between a Coupon and the
              support of a Social Project. Only one Coupon can be redeemed or
              only one Social Project can be supported per shop per visit. The
              conditions for redeeming Coupons depend on the respective Coupon
              and/or Partner Shop. The respectively valid conditions for
              redemption can be found in the App.
            </li>
            <li>
              If offered to the User the Customer is responsible for granting
              the User the selected discount. goodbag cannot be held responsible
              for any discounts that are offered by the Customer but not granted
              to the User due to any technical or other issues.
            </li>
            <li>
              For redeeming a Coupon or supporting a Social Project it is
              necessary that the User is personally present in the shop of the
              Partner Shop and shows both the goodbag bag and the respective
              barcode of the App. If the User is asked within the App to scan
              the goodbag bag, it is furthermore necessary that the User scans
              the goodbag bag.
            </li>
            <li>
              For the support of Social Projects, the User receives a voucher
              code at Partner Shops for the use, which can subsequently be
              redeemed for a Social Project by using the App. The restrictions
              on and the conditions for redeeming the voucher are displayed
              within the App.
            </li>
            <li>
              The information on the Partner Shops (e.g. address, opening hours,
              category) in the App and/or on the Website is for information
              purposes only. goodbag does not assume any guarantee and/or
              liability for the correctness of this information. In particular,
              the User shall not be entitled to any claims vis-à-vis goodbag due
              to incorrect information on the possibility of redeeming Coupons
              and their conditions. Should the Customer or the User consider
              information on the Partner Shops to be incorrect, the Customer or
              User can report this via e-mail to{" "}
              <a href="mailto:office@goodbag.io or">office@goodbag.io or</a> via
              the feedback function within the App.
            </li>
            <li>
              As regards the support of Social Projects, the presentation of the
              objectives achieved (e.g. number of trees planted) only serves as
              a visual representation of a success to be expected on the basis
              of calculations. For achieving the objectives of Social Projects,
              goodbag uses independent third-parties (hereinafter “Social
              Partners”). The objectives of Social Projects are supported by
              means of financial support of Social Partners who are
              contractually obliged to achieve certain objectives (e.g. planting
              a certain number of trees). You can find a transparent
              presentation of the funding of Social Projects at{" "}
              <a
                href="https://goodbag.io/trees"
                rel="noreferrer noopener"
                target="_blank"
              >
                https://goodbag.io/trees
              </a>
              . goodbag reserves the right to change the Social Partners and/or
              the funded projects at any time and without prior notice.
            </li>
            <li>
              Social Partners are supported to the extent communicated by
              goodbag at{" "}
              <a
                href="https://goodbag.io/trees"
                rel="noreferrer noopener"
                target="_blank"
              >
                https://goodbag.io/trees
              </a>
              . goodbag is entitled to change the calculation of the extent of
              support at any time and to impose restrictions thereon (e.g.
              maximum number of funded trees per User).
            </li>
            <li>
              The User is not entitled to use Coupons in any other way than by
              displaying them in the App, to make Coupons available to third
              parties for their use (e.g. by providing a screenshot). The User
              is not entitled to use the chip of the bag for purposes other than
              for redeeming Coupons, for supporting Social Projects or for other
              uses in connection with goodbag. The User shall hold goodbag
              harmless and indemnify it for any violation of this point of the
              Terms of Use.
            </li>
            <li>
              goodbag shall be free to offer Coupons and the support of Social
              Projects to the User also against other services (e.g. advertising
              to friends). In this regard, the conditions and restrictions for
              the provision of Coupons and the possibility to support Social
              Projects are displayed by goodbag within the App and may be
              changed unilaterally by goodbag at any time.
            </li>
          </ol>
        </li>

        <li>
          <span className="li-title">Warranty and liability</span>
          <ol>
            <li>The statutory liability provisions apply.</li>
            <li>
              Liability is excluded for defects caused by the User. This is in
              particular the case with improper handling as well as with the
              usual wear and tear due to use (e.g. if the goodbag bags are used
              for a longer period of time).
            </li>
            <li>
              goodbag shall exclusively be liable for material damage – for any
              legal reason whatsoever – if a damage was caused by goodbag with
              gross negligence or with intent. The liability of goodbag for
              slight negligence shall – except for personal damages – be
              excluded.
            </li>
            <li>
              In general, goodbag provides the functions of the App 24 hours a
              day and 7 days a week for use. goodbag shall make an effort to
              offer the provided App for access and use with a minimum of
              disruption. However, even if all due diligence is applied,
              downtimes and data losses cannot be excluded. goodbag expressly
              does not guarantee any minimum availability of the App and/or
              permanent storage of the data of the User.
            </li>
            <li>
              The systems of goodbag are protected against viruses, hacker
              attacks and other security-related manipulations of the App as
              well as of the underlying (server-side) software by measures
              corresponding with the state of the art. Nevertheless, the risk of
              such attacks, however, cannot be excluded. Unless a virus
              infection, hacker attacks and other security-related manipulations
              are caused due to a grossly negligent or intentional neglect of
              the security measures taken by goodbag, goodbag does not assume
              any liability for the damage resulting therefrom. When using the
              navigation function of goodbag, the User himself/herself is
              responsible for compliance with the provisions under road traffic
              law (e.g. driving bans), due diligence in traffic (e.g. pavement
              damages, potholes) and the actual choice of the route.
            </li>
          </ol>
        </li>

        <li>
          <span className="li-title">Copyright and links</span>
          <ol>
            <li>
              The App, its installation file, contents/data published within the
              App, the underlying (server-side) software as well as the Website
              are protected by copyright. Any kind of reproduction,
              distribution, change, public provision and other exploitation
              shall only be admissible with the written approval by goodbag.
            </li>
            <li>
              The direct or indirect links to third-party websites, apps and
              other content offered within the App and/or the Website are made
              available in the interest of the Customer and the User. The
              contents offered in the linked websites, apps and other content
              (hereinafter "<strong>Third-Party Contents</strong>") are not
              created by goodbag. goodbag has no influence on these Third-Party
              Contents. Any access to these Third-Party Contents shall be the
              sole responsibility of the User. It is not possible to constantly
              check the Third-Party Contents. Should the User deem Third-Party
              Contents as illegal, criminally relevant, unethical or in any
              other way alarming, the User may report such by sending an e-mail
              to <a href="mailto:office@goodbag.io">office@goodbag.io</a>.
            </li>
          </ol>
        </li>

        <li>
          <span className="li-title">Final provisions</span>
          <ol>
            <li>
              These Terms of Use as well as any and all agreements entered into
              between the Parties are governed by Austrian law under exclusion
              of the United Nations Convention on Contracts for the
              International Sale of Goods (UN Sales Law) and the
              conflict-of-laws rules of private international law. In terms of
              consumers, this choice of law shall only apply insofar as the
              consumer is thereby not deprived of the protection granted by the
              mandatory provisions of the law of the country of habitual
              residence of the consumer.
            </li>
            <li>
              The court having substantive jurisdiction in Vienna, Inner City
              (Wien, Innere Stadt) shall be competent for any and all actions
              brought against goodbag by the User for disputes arising out of or
              in connection with these Terms of Use. One of the courts in the
              district where his/her domicile, habitual residence or place of
              employment of the User is situated shall be competent for all
              actions brought by goodbag against a User having his/her domicile,
              habitual residence or place of employment in Austria for disputes
              arising out of or in connection with these Terms of Use. The
              statutory places of jurisdiction shall apply to Users not having a
              domicile in Austria when the contract is concluded..
            </li>
            <li>
              Any modifications of, amendments and ancillary agreements to these
              Terms of Use shall require the written form to be valid. The same
              shall apply to the agreement to derogate from this form
              requirement. In case of contradictions between these Terms of Use
              and deviating written agreements between the Parties, the
              provisions of the deviating agreements shall take precedence.
            </li>
          </ol>
        </li>
      </ol>
    </Fragment>
  ),
};
