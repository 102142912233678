import React, { Component } from "react";
import ReactMarkdown from 'react-markdown'
import { withTranslation } from "react-i18next";

import designs from "../../../constants/designs";

import { ScaleIn } from "../../hocs/AnimationHOC";

class BagDetailsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      onFocus: "model",
    };

    this.loadPrimaryImage();
  }

  // Pre-load the primary image so that the modal shows up smoothly
  loadPrimaryImage = () => {
    let { bag } = this.props;
    window
      .fetch(`/images/register/designs/scene/${bag.size}/${bag.name}.jpg`)
      .then(() => this.setState({ visible: true }));
  };

  changeImage = (image) => this.setState({ onFocus: image });

  render = () => {
    let { onFocus, visible } = this.state;

    if (!visible) return null;

    let { bag, close } = this.props;

    let productItem = designs[bag.size].bags[bag.key];

    let primaryImage =
      productItem.images[onFocus === "bag" ? 0 : 1];
    let secondaryImage =
      productItem.images[onFocus === "bag" ? 1 : 0];

    return (
      <div className="bag-details-modal" onClick={close}>
        <ScaleIn className={`modal-content-wrapper ${onFocus}-focused`} onClick={e => e.stopPropagation()}>
          <div className="close-modal-btn" onClick={close}>
            <img src="/images/assets/close.svg" alt="x" />
          </div>
          <div className="content-half details">
            <div className="details-wrapper">
              <div className="blue-SemiBold-18px-left name">
                goodbag {productItem.name}
              </div>
              <div className="details-list">
                <ReactMarkdown>
                  {productItem.shop_description}
                </ReactMarkdown>
              </div>
            </div>
          </div>
          <div
            className="content-half images"
            ref={(node) => (this.imageRef = node)}
          >
            <div className={`primary ${onFocus}-focused`}>
              <img src={primaryImage} alt={bag.name} />
            </div>
            <div
              className={`secondary ${onFocus}-focused`}
              onClick={() =>
                this.changeImage(onFocus === "bag" ? "model" : "bag")
              }
            >
              <img src={secondaryImage} alt={bag.name} />
            </div>
          </div>
        </ScaleIn>
      </div>
    );
  };
}

export default withTranslation("reorder")(BagDetailsModal);
