import {useQuery} from "@tanstack/react-query";
import BgoodProductService from "../business/BgoodProductService";
import { goodbagApiQuery } from "./Query";

export const useDesignsQuery = () => {
  return useQuery(
    ['designs'],
    () => goodbagApiQuery(() => BgoodProductService.getDesigns()),
    {
      keepPreviousData: true,
    },
  );
};

export function UseDesignsQuery (props) {
  return props.children(useDesignsQuery())
}