import { GET_COUNTRY_URL } from "../../constants/endpoints";
import { get } from "../../utilities/request";

class LocationAPIService {
  /**
   * Get the country code of the client.
   * @returns {Promise<{status_code: number, success: boolean, error: string, message: string}>}
   */
  static async getUserCountry() {
    return await get(GET_COUNTRY_URL, false);
  }
}

export default LocationAPIService;
