import BusinessAPIService from "../network/BusinessAPIService";

/**
 * This service handles all business-related requests' business logic.
 */
class BusinessService {
  /**
   * Get contact, shipping and plan info about an existing shop.
   * @param token
   * @return {Promise<{success: boolean, data: Object}|{success: boolean}>}
   */
  static async getBusinessDetails(token) {
    let { success, data } = await BusinessAPIService.fetchBusinessDetails(
      token
    );

    let result = { success };

    if (success) {
      result.data = {
        token,
        contact_info: {
          company: data.name || "",
          contact: data.contact_name || "",
          email: data.email || "",
          phone: data.phone || "",
          vat_number: data.vat_number || "",
        },
        plan: {
          name: data.plan,
          valid_until: data.t_plan_expires,
        },
        shipping_address: {},
        billing_address: {},
      };

      if (data.addresses.length > 0) {
        data.addresses = data.addresses.sort((a, b) => a.id > b.id);
        const shipping_address = data.addresses.find(c => c.type === 'shipping');
        const billing_address = data.addresses.find(c => c.type === 'billing');

        if (shipping_address) {
          const { id, type, ...details } = shipping_address;
          result.data.shipping_address = details;
          result.data.db_shipping_address = shipping_address;
        }

        if (billing_address) {
          const {id, type, ...details} = billing_address;
          result.data.billing_address = details;
          result.data.db_billing_address = billing_address;
        }
      }
    }

    return result;
  }
}

export default BusinessService;
