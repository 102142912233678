import "whatwg-fetch";

/**
 * Handle the Promise returned by the fetch API.
 * @param {Response} response
 * @returns {Promise<{status_code: (number|Response.status), success: (Response.ok|boolean), error: (string|Response.statusText), message: (string|null)}>}
 */
const handleResponse = async (response) => {
  switch (response.status) {
    case 401:
      return {
        success: false,
        error: "AuthorizationError",
        status_code: 401,
        message: "You are not authorized to perform this action.",
      };
    default:
      break;
  }

  let responseJSON = await response.json();
  let result = {
    success: response.ok,
    status_code: responseJSON.status_code || response.status,
    error: responseJSON.error || response.statusText,
    message: responseJSON.message || response.message || null,
  };

  if (response.ok) result.data = responseJSON;

  return result;
};

/**
 * Create a new request.
 * @param {String} url
 * @param {String} method
 * @param {boolean} authorized
 * @param {Object} body
 * @returns {Promise<{status_code: number, success: boolean, error: string, message: string}>}
 */
export const request = async (
  url,
  method = "GET",
  authorized = false,
  body
) => {
  let options = {
    method,
    headers: {
      "Content-Type": "application/json",
    },
  };

  if (process.env.NODE_ENV !== "development") {
    options = {
      ...options,
      withCredentials: true,
      credentials: "include",
    };
  }

  options.body = JSON.stringify(body);

  try {
    let req = await window.fetch(url, options);

    return await handleResponse(req);
  } catch (err) {
    return {
      success: false,
      error: "UnknownError",
      message:
        "Couldn't perform the request. Check your internet connection or maybe the server is down or is talking non-sense.",
      status_code: 503,
    };
  }
};

/**
 * Create a new request with custom headers and body.
 * @param {String} url
 * @param {String} method
 * @param {Object} body
 * @param {Object} headers
 * @returns {Promise<{status_code: number, success: boolean, error: string, message: string}>}
 */
export const plainRequest = async (url, method = "GET", body, headers) => {
  let options = {
    method,
    headers,
    body,
  };

  if (process.env.NODE_ENV !== "development") {
    options = {
      ...options,
      withCredentials: true,
      credentials: "include",
    };
  }

  try {
    let request = await window.fetch(url, options);

    return await handleResponse(request);
  } catch (err) {
    return {
      success: false,
      error: "UnknownError",
      message:
        "Couldn't perform the request. Check your internet connection or maybe the server is down or is talking non-sense.",
      status_code: 503,
    };
  }
};

/**
 * Makes a POST request.
 * @param url
 * @param body
 * @param authorized
 * @return {Promise<{status_code: number, success: boolean, error: string, message: string}>}
 */
export const post = (url, body, authorized) =>
  request(url, "POST", authorized, body);

/**
 * Makes a PUT request.
 * @param url
 * @param body
 * @param authorized
 * @return {Promise<{status_code: number, success: boolean, error: string, message: string}>}
 */
export const put = (url, body, authorized) =>
  request(url, "PUT", authorized, body);

/**
 * Makes a GET request.
 * @param url
 * @param authorized
 * @return {Promise<{status_code: number, success: boolean, error: string, message: string}>}
 */
export const get = (url, authorized) => request(url, "GET", authorized);

export default {
  plainRequest,
  request,
  post,
  get,
  put,
};
