import React, { Component } from "react";

class Dropdown extends Component {
  dropdown = new React.createRef();

  state = {
    toggled: false,
  };

  toggle = (ev, isToggled) => {
    if (ev) ev.stopPropagation();
    this.setState((prevState) => ({
      toggled: typeof isToggled === "boolean" ? isToggled : !prevState.toggled,
    }));
  };

  componentDidMount = () =>
    document.addEventListener("mousedown", this.handleClickOutside);

  componentWillUnmount = () =>
    document.removeEventListener("mousedown", this.handleClickOutside);

  handleClickOutside = (event) =>
    this.dropdown && !this.dropdown.contains(event.target)
      ? this.toggle(null, false)
      : null;

  handleOptionSelect = (ev) => {
    ev.stopPropagation();
    this.toggle(null, false);
  };

  render = () => {
    let isToggled = this.state.toggled ? "opened" : "";
    let { style, className, placeholder } = this.props;

    return (
      <div className={`dropdown-container ${className || ""}`}>
        <div
          className={`custom-dropdown ${isToggled}`}
          ref={(node) => (this.dropdown = node)}
          style={style}
        >
          <div
            className="placeholder d-flex justify-content-between align-items-center"
            onClick={() => this.toggle(null)}
          >
            {placeholder}
            <img
              src={`${process.env.PUBLIC_URL}/images/assets/open.svg`}
              alt="Open"
              onClick={(ev) => this.toggle(ev)}
            />
          </div>
          <div className="dropdown-list" onClick={this.handleOptionSelect}>
            {this.props.children}
          </div>
        </div>
      </div>
    );
  };
}

export default Dropdown;
