import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { navigate } from "../../shared/routes/CustomBrowserRouter";
import routes from "../../../constants/routes";

import layoutActions from "../../../actions/layout";
import OrderService from "../../../services/business/OrderService";

import InfoBox from "../../shared/InfoBox";
import Checkbox from "../../shared/forms/Checkbox";
import OrderTotals from "../../register/summary/OrderTotals";
import PotentialProfit from "./PotentialProfit";
import NeedHelp from "../../register/summary/NeedHelp";

export default () => {
  const { t } = useTranslation("reorder");
  const dispatch = useDispatch();
  const {
    selected_plan,
    designs,
    shipping_address: { country_code },
  } = useSelector((s) => s.reorder);

  const {
    checkboxes: { confirmation: consentToggled },
  } = useSelector((s) => s.layout);

  const [submitted, setSubmitted] = React.useState(false);

  const urlArea = window.location.pathname.match(/\/new.*/)
    ? "new_business"
    : "reorder";

  const toggleConsent = () =>
    dispatch(layoutActions.toggleConsent("confirmation"));

  const handleLegalLinkClick = (ev) => {
    ev.stopPropagation();
    let name = "";
    if (ev.target.classList.contains("privacy-link")) name = "privacy";
    if (ev.target.classList.contains("terms-link")) name = "terms";

    dispatch(layoutActions.togglePopup(name, true));
  };

  const onSubmit = async () => {
    if (!submitted) {
      if (!consentToggled)
        return dispatch(
          layoutActions.toggleErrorPopup("confirmation", true, {
            title: t("step_4_error_title"),
            text: t("comply_error"),
          })
        );

      setSubmitted(true);

      const { success } = await OrderService.submitReorder();
      navigate(routes[urlArea].completed, { success, process: "reorder" });
    }
  };

  return (
    <div className="confirmation-summary summary-content">
      <div className="summary-content-wrapper">
        <h4 className="blue-Bold-20px-left">{t("step_4_heading_mobile")}</h4>
        <InfoBox
          title={t("reorder_payment_info_box_title")}
          text={t("reorder_payment_info_box_text")}
        />
        <OrderTotals
          plan={selected_plan}
          quantities={designs}
          country={country_code}
          procedure="reorder"
          step="confirmation"
        />
        <Checkbox
          onClick={toggleConsent}
          initialValue={consentToggled}
          className={submitted ? "submitted" : ""}
        >
          <div
            className="blue-Regular-14px"
            onClick={handleLegalLinkClick}
            dangerouslySetInnerHTML={{
              __html: t("comply_text", {
                interpolation: { escapeValue: false },
                terms: `<span class="orange-Regular-14px terms-link">${t(
                  "terms_and_conditions"
                )}</span>`,
                privacy: `<span class="orange-Regular-14px privacy-link">${t(
                  "privacy_policy"
                )}</span>`,
              }),
            }}
          />
        </Checkbox>
        <div className="proceed">
          <button
            className={`btn btn-block btn-primary 
              ${!consentToggled ? " disabled " : ""} 
              ${submitted ? " submitted " : ""}`}
            type="submit"
            form="shop-details-form"
            onClick={onSubmit}
          >
            {t("step_4_checkout")}
          </button>
        </div>
        <PotentialProfit />
        <NeedHelp />
      </div>
    </div>
  );
};
