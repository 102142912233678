import React, { Component } from "react";

import { connect } from "react-redux";
import layoutActions from "../../../actions/layout";
import { withTranslation } from "react-i18next";

import InfoBox from "../../shared/InfoBox";
import NeedHelp from "./NeedHelp";

import { validateShopDetails } from "../../../helpers/ProcessHelpers";
import ProgressService from "../../../services/business/ProgessService";

class ShopDetailsSummary extends Component {
  // componentDidMount = () => {
  // 	this.handleWindowScroll();
  // 	window.addEventListener('scroll', this.handleWindowScroll);
  // }

  // componentWillUnmount = () => window.removeEventListener('scroll', this.handleWindowScroll);
  //
  // componentDidUpdate = () => this.handleWindowScroll();

  // On window scroll adjust the summary content
  handleWindowScroll = () => {
    let { scrollY } = window;
    let percentage =
      window.innerWidth <= 1178
        ? window.innerWidth <= 1000
          ? this.props.editing_details
            ? "83%"
            : "(79.5% + 10px)"
          : this.props.editing_details
          ? "64.5%"
          : "(57.5% + 10px)"
        : this.props.editing_details
        ? "59.5%"
        : "52.5%";
    let maxScroll = window.innerWidth <= 1000 ? 1920 : 370;

    if (scrollY <= 50) {
      return (this.wrapper.style.bottom = `calc(${percentage})`);
    } else if (scrollY > 50 && scrollY < maxScroll) {
      return (this.wrapper.style.bottom = `calc(${percentage} - ${
        scrollY + 20
      }px)`);
    } else return (this.wrapper.style.bottom = "0");
  };

  onFormSubmit = () => {
    let { t } = this.props;

    if (
      !Object.values(this.props.details.coupon_options).find((v) => v === true)
    )
      return this.setError(
        t("summary:step_1_error_coupons_title"),
        t("summary:step_1_error_coupons_text")
      );

    window.scrollTo({ top: 0, behavior: "smooth" });
    // Lead a shop into our db after the step has been completed.
    if (!this.props.shopLeaded) ProgressService.leadShop();
  };

  setError = (title, text) =>
    this.props.toggleError(title && text, { title, text });

  render = () => {
    let { t, details, editing_details } = this.props;
    let isNextValid = !validateShopDetails(details) ? "disabled" : "";
    let isEditing = editing_details ? "editing" : "";

    return (
      <div className="summary details">
        <div className="summary-container">
          <div className="shop-details-summary summary-content">
            <div
              className={`summary-content-wrapper ${isEditing}`}
              ref={(node) => (this.wrapper = node)}
            >
              <h4 className="blue-Bold-20px-left">
                {editing_details
                  ? t("summary:step_1_editing")
                  : t("summary:heading")}
              </h4>
              {!editing_details && (
                <InfoBox text={t("summary:step_1_info_box")} />
              )}
              <div className="proceed">
                <button
                  className={`btn btn-block btn-primary ${isNextValid} submit-details-step-btn`}
                  type="submit"
                  form="shop-details-form"
                  onClick={this.onFormSubmit}
                >
                  {editing_details
                    ? t("summary:save_changes")
                    : t("summary:next_step")}
                </button>
              </div>
              <NeedHelp />
            </div>
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  details: state.register,
  editing_details: state.register.editing_step === "details",
  display_error: state.layout.error_popups.details,
  shopLeaded: state.register.shop_leaded,
});

const mapDispatchToProps = (dispatch) => ({
  toggleError: (toggled, error) =>
    dispatch(layoutActions.toggleErrorPopup("details", toggled, error)),
});

let reduxWrapped = connect(
  mapStateToProps,
  mapDispatchToProps
)(ShopDetailsSummary);
export default withTranslation()(reduxWrapped);
