import React, { Component, Fragment } from "react";

import { withTranslation } from "react-i18next";

import { connect } from "react-redux";
import reorderActions from "../../actions/reorder";

import { navigate } from "../shared/routes/CustomBrowserRouter";
import routes from "../../constants/routes";

import PlacesAutocomplete from "react-places-autocomplete";
import LocationService from "../../services/business/LocationService";
import ProgressService from "../../services/business/ProgessService";

import ShopsHelpers from "../../helpers/ShopsHelpers";

class ShopFinder extends Component {
  state = {
    search: "",
    focusedSuggestion: 0,
    show_results: false,
  };

  suggestionsContainer = new React.createRef();
  searchInput = new React.createRef();

  componentDidMount = () => {
    if (this.searchInput.current !== null) this.searchInput.focus();
    document.addEventListener("mousedown", this.handleClickOutside);
  };

  componentWillUnmount = () =>
    document.removeEventListener("mousedown", this.handleClickOutside);

  loadSelectedShop = (shop) => {
    // If the shop is undefined or null for some reason just keep the
    // suggestions list open.
    if (typeof shop === "undefined" || shop === null)
      return this.toggleResults(true);

    const parsedDetails = ShopsHelpers.parseGooglePlaceDetails(shop);

    this.props.saveBusinessGoogleDetails(parsedDetails);

    // Send an asynchronous request that would lead the place Id returned by Google's API into our db.
    ProgressService.leadPlace(parsedDetails.place_id);

    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate(routes.new_business.designs);
  };

  toggleResults = (show_results) => {
    let old_show_results = this.state.show_results;
    // If hiding the suggestions container, empty the locally stored collection.
    if (!show_results) this.suggestions = [];

    this.setState(
      (prevState) => ({
        show_results,
        // If the suggestions container is being hidden, reset the focused selection index so that
        // on the next autocomplete suggestions collection, the process of "navigating" starts all over.
        focusedSuggestion: !show_results ? 0 : prevState.focusedSuggestion,
      }),
      () => {
        if (!show_results) this.suggestionsContainer.scrollTop = 0;
        // If the autocomplete suggestions were hidden, but are currently getting enabled, trigger the autocomplete.
        if (!old_show_results && show_results) this.triggerAutocomplete();
      }
    );
  };

  /**
   * Triggers the React Autocomplete Search by adding " " to the end of the input and then removing it immediately.
   */
  triggerAutocomplete = () => {
    let { search } = this.state;
    const setValue = Object.getOwnPropertyDescriptor(
      this.searchInput.__proto__,
      "value"
    ).set;
    // Trigger auto complete input change
    setValue.call(this.searchInput, search + " ");
    this.searchInput.dispatchEvent(new Event("input", { bubbles: true }));
    // Revert back to initial search value
    setTimeout(() => {
      setValue.call(this.searchInput, search);
      this.searchInput.dispatchEvent(new Event("input", { bubbles: true }));
    }, 0);
  };

  handleSearchClick = () => {
    this.toggleResults(true);
    this.searchInput.focus();
  };

  handleClickOutside = (event) =>
    this.wrapper && !this.wrapper.contains(event.target)
      ? this.toggleResults(false)
      : null;

  handleSelect = (address, placeId) => {
    LocationService.getPlaceDetails(placeId, (shop) =>
      this.loadSelectedShop(shop)
    );

    // TODO: perform a check in our database if the shop already exists
    //       if so - display info message.
  };

  handleSearchChange = (search) =>
    this.setState({ search, focusedSuggestion: 0 });

  scrollSuggestionIntoView(suggestion) {
    let parentRect = this.suggestionsContainer.getBoundingClientRect();
    let childRect = suggestion.getBoundingClientRect();
    // Measures whether the child's top and bottom borders are within the top and bottom borders of the suggestions container element.
    let suggestionViewable =
      childRect.top >= parentRect.top &&
      childRect.top + childRect.height <= parentRect.top + parentRect.height;

    // If the suggestion is not fully viewable, scroll the suggestions container.
    if (!suggestionViewable)
      this.suggestionsContainer.scrollTop =
        childRect.top + this.suggestionsContainer.scrollTop - parentRect.top;
  }

  setFocusedSuggestion = (index) => this.setState({ focusedSuggestion: index });

  handleKeyDown = (ev) => {
    if (
      (!this.suggestions || this.suggestions.length === 0) &&
      ev.keyCode !== 13
    )
      return;

    const onFocus =
      this.suggestions.length === 1 ? 0 : this.state.focusedSuggestion;
    let nextFocused;
    let focusedSuggestionElement;

    switch (ev.keyCode) {
      // Enter key
      case 13:
        return this.toggleResults(true);
      // Escape key
      case 27:
        return this.toggleResults(false);
      // Arrow down key
      case 40:
        if (!this.state.show_results) return this.toggleResults(true);

        if (this.suggestions.length < 2) return;

        nextFocused = onFocus === this.suggestions.length - 1 ? 0 : onFocus + 1;
        this.setFocusedSuggestion(nextFocused);

        focusedSuggestionElement = document.getElementById(
          `autocomplete-suggestion-${nextFocused}`
        );
        return this.scrollSuggestionIntoView(focusedSuggestionElement);
      // Arrow up key
      case 38:
        ev.preventDefault();
        if (!this.state.show_results || this.suggestions.length < 2) return;

        nextFocused = onFocus === 0 ? this.suggestions.length - 1 : onFocus - 1;
        this.setFocusedSuggestion(nextFocused);

        focusedSuggestionElement = document.getElementById(
          `autocomplete-suggestion-${nextFocused}`
        );
        return this.scrollSuggestionIntoView(focusedSuggestionElement);

      default:
        break;
    }
  };

  storeSuggestions = (suggestions) => {
    this.suggestions = suggestions;
  };

  render = () => {
    const { t } = this.props;
    let { search, focusedSuggestion, show_results } = this.state;
    let searchOptions = { types: ["establishment"] };

    return (
      <PlacesAutocomplete
        value={this.state.search}
        onChange={this.handleSearchChange}
        onSelect={this.handleSelect}
        searchOptions={searchOptions}
        debounce={400}
        shouldFetchSuggestions={show_results && search.length > 3}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
          <Fragment>
            {/* Store suggestions locally. */}
            {this.storeSuggestions(suggestions)}
            <div
              className="finder-input-container"
              ref={(node) => (this.wrapper = node)}
            >
              <span className="search-icon">
                <img
                  src={`${process.env.PUBLIC_URL}/images/assets/search.svg`}
                  alt="Search"
                />
              </span>
              <input
                {...getInputProps({
                  placeholder: t("landing:search_placeholder"),
                  className: "shop-finder-input",
                })}
                ref={(node) => (this.searchInput = node)}
                onKeyDown={this.handleKeyDown}
                onBlur={() => this.toggleResults(false)}
              />
              <div
                className="btn btn-primary submit-search"
                onClick={this.handleSearchClick}
              >
                {t("landing:search_button")}
              </div>
              <div id="map" />
              <div
                className={`suggestions-container ${
                  suggestions.length > 0 && show_results ? "" : "empty"
                }`}
                ref={(node) => (this.suggestionsContainer = node)}
              >
                {suggestions.length > 0 && (
                  <div className="autocomplete-dropdown-container">
                    {suggestions.map((suggestion, key) => {
                      let className = suggestion.active
                        ? "suggestion-item active"
                        : "suggestion-item";
                      if (focusedSuggestion === key) className += " focused";

                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, { className })}
                          id={`autocomplete-suggestion-${key}`}
                          onMouseOver={() => this.setFocusedSuggestion(key)}
                          key={key}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                    <div className="google-logo">
                      <img
                        src={`${process.env.PUBLIC_URL}/images/landing/powered-by-google.png`}
                        alt=""
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Fragment>
        )}
      </PlacesAutocomplete>
    );
  };
}

const mapDispatchToProps = (dispatch) => ({
  saveBusinessGoogleDetails: (details) =>
    dispatch(reorderActions.saveBusinessGoogleDetails(details)),
});

let reduxWrapped = connect(null, mapDispatchToProps)(ShopFinder);

export default withTranslation()(reduxWrapped);
