import React, { Component } from "react";

import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import registerActions from "../../../actions/register";
import layoutActions from "../../../actions/layout";
import routes from "../../../constants/routes";

import {
  adjustProcessNavigationScroll,
  goToFirstIncompleteStep,
} from "../../../helpers/ProcessHelpers";

import ShopDetailsPreview from "./ShopDetailsPreview";
import DesignsPreview from "./DesignsPreview";
import InfoMessage from "../../shared/InfoMessage";
import PrivacyPolicyPopup from "../../legal/popup/PrivacyPolicyPopup";
import TermsAndConditionsPopup from "../../legal/popup/TermsAndConditionsPopup";

import "../../../stylesheets/register/confirmation.scss";

class ConfirmationStep extends Component {
  componentDidMount = () => {
    if (!goToFirstIncompleteStep("register")) {
      // Proceed only if the user is ready for the current step (he has completed the prior ones).
      this.props.setEditingStep("");
      adjustProcessNavigationScroll("confirmation");
    }
  };

  render = () => {
    let { t, plan, displayError } = this.props;

    if (!plan) return null;

    return (
      <div className="confirmation-step">
        <div className="my-container">
          <div className="steps-preview">
            <div className="heading blue-Bold-20px-left">
              {t("confirmation:heading")}
            </div>
            <div
              className="row preview-columns"
              style={{ width: "calc(100% - (376px - ((100vw - 1291px) / 2)))" }}
            >
              <ShopDetailsPreview />
              {/*<PlanPreview*/}
              {/*  editRedirect={routes.register.plan}*/}
              {/*  plan={this.props.plan}*/}
              {/*  country={this.props.country}*/}
              {/*  setEditingStep={() => this.props.setEditingStep("plan")}*/}
              {/*/>*/}
              <DesignsPreview
                editRedirect={routes.register.designs}
                quantities={this.props.quantities}
                bag_logo={this.props.bag_logo}
                country={this.props.country}
                plan={this.props.plan}
                setEditingStep={() => this.props.setEditingStep("designs")}
              />
            </div>
          </div>
        </div>
        {this.props.displayError && (
          <InfoMessage title={displayError.title} text={displayError.text}>
            <div
              className="btn btn-primary bulky dismiss-validation-message"
              onClick={() => this.props.toggleError(false)}
            >
              {t("summary:dismiss_error")}
            </div>
          </InfoMessage>
        )}
        {this.props.privacyOpened && <PrivacyPolicyPopup />}
        {this.props.termsOpened && <TermsAndConditionsPopup />}
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  plan: state.register.plan,
  displayError: state.layout.error_popups.confirmation,
  privacyOpened: state.layout.popups.privacy,
  termsOpened: state.layout.popups.terms,
  quantities: state.register.designs,
  bag_logo: state.register.bag_logo,
  country: state.register.shop_details.country,
});

const mapDispatchToProps = (dispatch) => ({
  setEditingStep: (step) => dispatch(registerActions.setEditingStep(step)),
  toggleError: (toggled) =>
    dispatch(layoutActions.toggleErrorPopup("confirmation", toggled)),
});

let reduxWrapped = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmationStep);

export default withTranslation()(reduxWrapped);
