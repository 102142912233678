const onInputInvalid = (ev, message) => ev.target.setCustomValidity(message);
const onInput = (ev) => ev.target.setCustomValidity("");

function onInputChange(ev, callback = () => {}) {
  this.setState({ [ev.target.name]: ev.target.value }, callback);
}

function onCheckboxChange(ev) {
  this.setState({ [ev.target.name]: ev.target.checked });
}

const validateEmail = (input) =>
  /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
    input
  );

export {
  onInputChange,
  onInputInvalid,
  onInput,
  onCheckboxChange,
  validateEmail,
};
