import React, { Component } from "react";

class Checkbox extends Component {
  checkbox = React.createRef();

  state = {
    isToggled: this.props.initialValue || false,
    isDisabled: this.props.disabled,
  };

  toggleCheckbox = () => {
    if (this.state.isDisabled) return;

    this.props.onClick(this.props.name, !this.state.isToggled);
    this.checkbox.current.click();
    this.setState((prevState) => ({ isToggled: !prevState.isToggled }));
  };

  render = () => {
    let isToggled = this.state.isToggled ? "active" : "";
    let isDisabled = this.state.isDisabled ? "disabled" : "";

    return (
      <div
        className={`checkbox-wrapper ${isToggled} ${isDisabled} ${this.props.className}`}
      >
        <div className="d-flex">
          <div className="checkbox-element" onClick={this.toggleCheckbox}>
            <img
              src={`${process.env.PUBLIC_URL}/images/assets/checkbox-active.svg`}
              alt="Checked"
            />
          </div>
          <div className="checkbox-label black-Regular">
            {this.props.children}
          </div>
        </div>
        <input
          type="checkbox"
          ref={this.checkbox}
          onChange={this.props.onChange}
          onInvalid={this.props.onInvalid}
          className="checkbox"
          name={this.props.name}
          required={this.props.required}
        />
      </div>
    );
  };
}

export default Checkbox;
