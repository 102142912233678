import React, { Component } from "react";

import { withTranslation } from "react-i18next";

export const renderPlanOffering = (text) => (
  <div className="include-option">
    <div className="d-flex align-items-start">
      <img
        src={`${process.env.PUBLIC_URL}/images/assets/check-green.svg`}
        alt=">"
      />
      <span className="blue-Regular-16px-left">{text}</span>
    </div>
  </div>
);

class PlanOption extends Component {
  render = () => {
    let {
      t,
      color,
      super_text,
      name,
      price,
      bag_price,
      cup_price,
      free_bags,
      children,
    } = this.props;

    return (
      <div
        className={`plan-option-container ${color || ""} ${name.toLowerCase()}`}
      >
        <div
          className={`white-ExtraBold-18px text-left super-text ${
            super_text ? "" : "empty"
          }`}
        >
          {super_text}
        </div>
        <div className="plan-content">
          <div className="blue-SemiBold-20px text-left name">
            {t(`plan:${name}_name`)}
          </div>
          <div className="price">
            <span className="blue-ExtraBold-30px-center text-left">
              {price}
            </span>
            <p
              className="blue-SemiBold-20px text-left bag-price"
              dangerouslySetInnerHTML={{
                __html: "+" + t("plan:price_per_bag", { bag_price }),
              }}
            />
            <p
              className="blue-SemiBold-20px text-left bag-price"
              dangerouslySetInnerHTML={{
                __html: "+" + t("plan:price_per_cup", { cup_price }),
              }}
            />
          </div>
          <div className="text-center content-container">{children}</div>
          <div className="includes">
            <div className="blue-Bold-16px title">
              {t("plan:includes_heading")}
            </div>
            <div className="includes-list">
              {name !== "ondemand" &&
                renderPlanOffering(
                  t("plan:includes_free_bags", { amount: free_bags })
                )}
              {renderPlanOffering(
                t(
                  `plan:${
                    name === "ondemand"
                      ? "includes_listing_platforms_ondemand"
                      : "includes_listing_platforms"
                  }`
                )
              )}
              {name !== "ondemand" &&
                renderPlanOffering(t("plan:includes_tree_coupons"))}
              {renderPlanOffering(t("plan:includes_bag_coupons"))}
              {renderPlanOffering(t("plan:includes_optional_discount"))}
              {name === "standard" &&
                renderPlanOffering(
                  t("plan:includes_optional_cardboard", {
                    price: this.props.stand_price,
                  })
                )}
              {name === "premium" &&
                renderPlanOffering(t("plan:premium_includes_newsletter"))}
              {name === "premium" &&
                renderPlanOffering(t("plan:premium_includes_cardboard"))}
            </div>
          </div>
        </div>
      </div>
    );
  };
}

export default withTranslation()(PlanOption);
