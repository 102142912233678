import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { injectIntl } from "react-intl";
import { navigate } from "../shared/routes/CustomBrowserRouter";
import "../../utilities/extensions";

import routes from "../../constants/routes";
import { currency_rates } from "../../constants/internationals";
import { config as bags_config } from "../../constants/designs";

import registerActions from "../../actions/register";
import { connect } from "react-redux";

import steps from "../../constants/steps";
import { validateShopDetails } from "../../helpers/ProcessHelpers";

class RegisterNavigation extends Component {
  /**
   * Return the "€0,-" string from the navigation tab for the plan step, formatted
   * accordingly to the country of the client location.
   */
  renderPlanPrice = () => {
    let currency = currency_rates[this.props.country] || currency_rates.default;
    let planPrice = this.props.intl.formatNumber(0, {
      style: "currency",
      currency: currency.code,
      currencyDisplay: "symbol",
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    });

    return planPrice.insert(planPrice.indexOf("0") + 1, ",-");
  };

  renderLink = (step, completedCondition) => {
    const { t, current, editingStep } = this.props;
    const status =
      editingStep === step && current === step && completedCondition
        ? "editing"
        : current === step
        ? "current"
        : completedCondition
        ? "completed"
        : "";
    // If the link to be rendered is for the plan step, return the required translation
    // params needed for the string interpolation. Otherwise, return empty object.
    let translationParts =
      step === "plan" ? { price: this.renderPlanPrice() } : {};

    return (
      <span
        className={`navigation-step orange-Bold-16px-left ${step} ${status}`}
        onClick={() => this.redirectToStep(step)}
      >
        {t(
          `general:navigation_${steps.indexOf(step) + 1}_step`,
          translationParts
        )}
      </span>
    );
  };

  redirectToStep = (step) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate(`${routes.register.start}/${step}`);
  };

  render = () => {
    let { details } = this.props;

    return (
      <div className="steps-navigation">
        <div className="my-container">
          <div className="steps-navigation-container">
            {this.renderLink("details", validateShopDetails(details))}
            {this.renderLink("plan", details.plan)}
            {this.renderLink(
              "designs",
              Object.keys(details.designs).find((size) =>
                Object.values(details.designs[size]).find(
                  (design) =>
                    parseInt(design) >= bags_config.MIN_QUANTITY_REGULAR
                )
              )
            )}
            {this.renderLink("confirmation")}
          </div>
        </div>
      </div>
    );
  };
}

const mapStateToProps = (state) => ({
  details: state.register,
  editingStep: state.register.editing_step,
  country: state.register.shop_details.country,
});

const mapDispatchToProps = (dispatch) => ({
  setEditingStep: (step) => dispatch(registerActions.setEditingStep(step)),
});

const Reduxified = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterNavigation);
const WithIntl = injectIntl(Reduxified);

export default withTranslation()(WithIntl);
