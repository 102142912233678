import { combineReducers } from "redux";

import register from "./register";
import reorder from "./reorder";
import settings from "./settings";
import layout from "./layout";

export default combineReducers({
  register,
  reorder,
  settings,
  layout,
});
