import React from "react";

import { withTranslation } from "react-i18next";

const CompanyStores = ({ t, headingStyle, children }) => {
  const default_stores = [
    `/images/landing/default_stores/maria_granel.png`,
    `/images/landing/default_stores/denner.png`,
    `/images/landing/default_stores/natur_and_reform.png`,
    `/images/landing/default_stores/continente.png`,
    `/images/landing/default_stores/audi.png`,
    `/images/landing/default_stores/saint_charles_apotheke.png`,
    `/images/landing/default_stores/greentech_festival.png`,
    `/images/landing/default_stores/avery_dennison.png`,
  ];

  return (
    <div className="company-stores">
      <div
        className={`${headingStyle} heading`}
        dangerouslySetInnerHTML={{
          __html: t("general:company_stores_heading"),
        }}
      />
      <div className="stores">
        <div className="stores-list">
          {default_stores.map((value, key) => (
            <div className="store" key={key}>
              <div className="store-container">
                <img src={value} alt={key} />
              </div>
            </div>
          ))}
        </div>
      </div>
      {children}
    </div>
  );
};

export default withTranslation()(CompanyStores);
